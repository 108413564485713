import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constType from './constant'
import axios from '../../../../utils/axios';

 
function* createPod(actionData){
    try{
    const pod = yield axios({
            url: `tagPodImageV2?fileName=${actionData.data.fileName}&unloadingSeqNum=${actionData.data.unloadingSeqNum}&vehicleId=${actionData.data.vehicleId}&timestamp=${actionData.data.timestamp}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.createPodSuccess(pod));
    } catch(error){
        console.log('create Pod',error)
    }
}

export default function* podSaga(){
    yield takeLatest(constType.CREATE_POD, createPod);
}