
import * as actionTypes from "./constant";

export function getExpenseFleetReportsService(data) {
    return {
        type: actionTypes.GET_EXPENSE_FLEET_REPORTS,
        data
    }
}
export function getExpenseFleetReportsSuccess(expenseReports) {
    return {
        type: actionTypes.GET_EXPENSE_FLEET_REPORTS_SUCCESS,
        expenseReports
    }
}

export function getExpenseFleetReportsError(error) {
    return {
        type: actionTypes.GET_EXPENSE_FLEET_REPORTS_FAILURE,
        error
    }
}
