export const GET_SIM_USAGE = 'GET_SIM_USAGE'
export const SET_SIM_USAGE = 'SET_SIM_USAGE'
export const RESET_SERVICE = 'RESET_SERVICE'
export const GET_ALL_DATA_SIM_USAGE = 'GET_ALL_DATA_SIM_USAGE'
export const GET_ALL_DATA_SIM_USAGE_SUCCESS = 'GET_ALL_DATA_SIM_USAGE_SUCCESS'
export const GET_ALL_DATA_SIM_USAGE_ERROR = 'GET_ALL_DATA_SIM_USAGE_ERROR'
export const SIMCONSENT_GET_AVAILABLE_PING = 'SIMCONSENT_GET_AVAILABLE_PING'
export const SIMCONSENT_SET_AVAILABLE_PING = 'SIMCONSENT_SET_AVAILABLE_PING'
export const SIMCONSENT_GET_SPECIFIC_ORG_INFO = 'SIMCONSENT_GET_SPECIFIC_ORG_INFO'
export const GET_CHART_SERVICE = 'GET_CHART_SERVICE'
export const GET_CHART_SUCCESS = 'GET_CHART_SUCCESS'
export const GET_CHART_FAILURE = 'GET_CHART_FAILURE'
export const GET_VEHICLELIST_CHART = "GET_VEHICLELIST_CHART";
export const GET_VEHICLELIST_CHART_SUCCESS = "GET_VEHICLELIST_CHART_SUCCESS";
export const GET_VEHICLELIST_CHART_FAILURE = "GET_VEHICLELIST_CHART_FAILURE";






