import {
    GET_SIM_USAGE,
    SET_SIM_USAGE,
    RESET_SERVICE,
    GET_ALL_DATA_SIM_USAGE,
    GET_ALL_DATA_SIM_USAGE_SUCCESS,
    GET_ALL_DATA_SIM_USAGE_ERROR,
    SIMCONSENT_SET_AVAILABLE_PING,
    SIMCONSENT_GET_SPECIFIC_ORG_INFO,
    GET_CHART_SERVICE,
    GET_CHART_FAILURE,
    GET_CHART_SUCCESS,
    GET_VEHICLELIST_CHART,
    GET_VEHICLELIST_CHART_SUCCESS,
    GET_VEHICLELIST_CHART_FAILURE
} from './constant'
// get vehiclelist
export function getVehiclesService(organizationId, uid) {
    return {
        type: GET_VEHICLELIST_CHART,
        organizationId,
        uid
    }
}
export function getVehiclesSuccess(vehicleList) {
    return {
        type: GET_VEHICLELIST_CHART_SUCCESS,
        vehicleList        
    }
}

export function getVehiclesError(error) {
    return {
        type: GET_VEHICLELIST_CHART_FAILURE,
        error
    }
}
export function getSimUsageData(uid, fromdate, todate, vehicleId) {
    return {
        type: GET_SIM_USAGE,
        uid,
        fromdate,
        todate,
        vehicleId
    }
}
export function chartService(payLoad) {
    return {
        type: GET_CHART_SERVICE,
        payLoad
    }
}
export function chartSuccess(res) {
    return {
        type: GET_CHART_SUCCESS,
        res
    }
}
export function chartError(error) {
    return {
        type: GET_CHART_FAILURE,
        error
    }
}
export function getAllSimData(uid, fromdate, todate) {
    return {
        type: GET_ALL_DATA_SIM_USAGE,
        uid,
        fromdate,
        todate
    }
}
export function getAllSimDataSuccess(allSimDataList) {
    return {
        type: GET_ALL_DATA_SIM_USAGE_SUCCESS,
        allSimDataList
    }
}
export function getAllSimDataError(error) {
    return {
        type: GET_ALL_DATA_SIM_USAGE_ERROR,
        error
    }
}
export function setSimUsageData(data) {
    return {
        type: SET_SIM_USAGE,
        data
    }
}
export function resetServiceType() {
    return {
        type: RESET_SERVICE
    }
}
export function setAvailablePing(availablePings) {
    return {
        type: SIMCONSENT_SET_AVAILABLE_PING,
        availablePings
    }
}
export function getSpecificOrgInfoForAvailablePings(organizationId, uid) {
    return {
        type: SIMCONSENT_GET_SPECIFIC_ORG_INFO,
        organizationId,
        uid
    }
}



