import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constType from './constant'
import axios from '../../../../utils/axios';

function* truckHistoryService(actionData){

    try{
        let data ={
            orgId: actionData.payload.orgId,
            fromTimeUtc:actionData.payload.fromTimeUtc,
            toTimeUtc: actionData.payload.toTimeUtc,
            driverList: actionData.payload.driverId? [actionData.payload.driverId] : [],
            vehicleList: actionData.payload.vehicleId? [actionData.payload.vehicleId] : [],
        }
        const truckDetail = yield axios({
            url: `getTotalCards`,
            method: 'POST',
            data: data
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.truckHistorySuccess(truckDetail));
    } catch(error){
        yield put(actions.truckHistoryError(error));
    }
}

function* getVehicle(vehicle){
    try{
        const data = yield axios({
            url: `getVehicles?orgId=${vehicle.uid}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.vehicleSuccess(data));
    } catch(error){
        yield put(actions.vehicleError(error));
    }
}

function* getDriver(id){
    try{
        const data = yield axios({
            url: `getDriverList?fleetOwner=${id.orgOwnerId}`,
            method: 'GET',
        })
        .then( response=>{ 
            return response.data; 
        });
        yield put(actions.getDriverSuccess(data));
    } catch(error){
        yield put(actions.driverError(error));
    }
}

function* getPlanHistory(record){
    try{
        const report = yield axios({
            url: `getSpecificPlanDetails?planNo=${record.data.planNo}&vehicleId=${record.data.vehicleId}`,
            method: 'GET',
        }).then( response=>{ 
            return response.data.data
        });      
        yield put(actions.historyDetailSuccess(report.planList[0]));
    } catch(error){
        console.log("configCreated",error)
    }
}

function* getTripReportService(record){
    try{
        const report = yield axios({
            url: `getTripReports?vehicleId=${record.data.vehicleId}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'GET',
        }).then( response=>{ 
            return response.data.dataList
        });
        yield put(actions.getTripReportSuccess(report));
        /*   
        yield put(actions.getTripReportSuccess([
            {
              "lat": "12.84694",
              "lng": "79.7377",
              "imei": null,
              "speed": null,
              "date": "1647369000000",
              "status": null,
              "ignitionStatus": "OFF",
              "error": null,
              "address": "Enathur - Vaiyavoor Rd-Enathur-Kanchipuram-Tamil Nadu-India-631501",
              "insideGeofence": "NO",
              "fuelLevel": "0",
              "odo": 0.0,
              "duration": 8975000,
              "exitTime": 1647377975000,
              "dist": 0.0
            },
            {
              "lat": "12.84694",
              "lng": "79.7377",
              "imei": null,
              "speed": null,
              "date": "1647381633000",
              "status": null,
              "ignitionStatus": "OFF",
              "error": null,
              "address": "Enathur - Vaiyavoor Rd-Enathur-Kanchipuram-Tamil Nadu-India-631501",
              "insideGeofence": "NO",
              "fuelLevel": "0",
              "odo": 0.0,
              "duration": 1798000,
              "exitTime": 1647383431000,
              "dist": 0.0
            },
            {
              "lat": "12.84694",
              "lng": "79.7377",
              "imei": null,
              "speed": null,
              "date": "1647392464000",
              "status": null,
              "ignitionStatus": "OFF",
              "error": null,
              "address": "Enathur - Vaiyavoor Rd-Enathur-Kanchipuram-Tamil Nadu-India-631501",
              "insideGeofence": "NO",
              "fuelLevel": "0",
              "odo": 0.0,
              "duration": 4582000,
              "exitTime": 1647397046000,
              "dist": 0.0
            },
            {
              "lat": "13.02647",
              "lng": "80.02268",
              "imei": null,
              "speed": null,
              "date": "1647403381000",
              "status": null,
              "ignitionStatus": "OFF",
              "error": null,
              "address": "1.9km away from Thandalam",
              "insideGeofence": "NO",
              "fuelLevel": "0",
              "odo": 0.0,
              "duration": 30000,
              "exitTime": 1647403411000,
              "dist": 46.01
            },
            {
              "lat": "13.02546",
              "lng": "80.02463",
              "imei": null,
              "speed": null,
              "date": "1647403969000",
              "status": null,
              "ignitionStatus": "OFF",
              "error": null,
              "address": "2.03km away from Thandalam-AH45-Kuthambakkam-Tiruvallur-Tamil Nadu-India-600124",
              "insideGeofence": "NO",
              "fuelLevel": "0",
              "odo": 0.0,
              "duration": 1195000,
              "exitTime": 1647405164000,
              "dist": 46.61
            },
            {
              "lat": "13.02546",
              "lng": "80.02463",
              "imei": null,
              "speed": null,
              "date": "1647414230000",
              "status": null,
              "ignitionStatus": "OFF",
              "error": null,
              "address": "2.03km away from Thandalam-AH45-Kuthambakkam-Tiruvallur-Tamil Nadu-India-600124",
              "insideGeofence": "NO",
              "fuelLevel": "0",
              "odo": 0.0,
              "duration": 20254000,
              "exitTime": 1647434484000,
              "dist": 46.61
            },
            {
              "lat": "12.84705",
              "lng": "79.73722",
              "imei": null,
              "speed": null,
              "date": "1647446721000",
              "status": null,
              "ignitionStatus": "OFF",
              "error": null,
              "address": "Enathur - Vaiyavoor Rd-Enathur-Kanchipuram-Tamil Nadu-India-631501",
              "insideGeofence": "NO",
              "fuelLevel": "0",
              "odo": 0.0,
              "duration": 130000,
              "exitTime": 1647446851000,
              "dist": 88.44
            }
          ]));
          */
    } catch(error){
        yield put(actions.getTripReportError(error));
    }
}

function* getFuelData(record){
    try{
        const report = yield axios({
            url: `getFuelData?vehicleId=${record.data.vehicleId}&fromDateUtc=${record.data.fromTimeUtc}&toDateUtc=${record.data.toTimeUtc}`,
            method: 'POST',
        }).then( response=>{
            return response.data
        });
        yield put(actions.getFuelDataSuccess(report));
    } catch(error){
        console.log("configCreated",error)
        yield put(actions.getFuelDataError(error));

    }
}

export default function* truckHistorySaga(){
    yield takeLatest(constType.LOAD_TRUCKHISTORY_LODING, truckHistoryService)
    yield takeLatest(constType.LOAD_VEHICLE, getVehicle)
    yield takeLatest(constType.GET_LOAD_DRIVER, getDriver)
    yield takeLatest(constType.HISTORY_DETAIL, getPlanHistory)
    yield takeLatest(constType.GET_TRIP_REPORTS, getTripReportService)
    yield takeLatest(constType.GET_FUEL_DATA, getFuelData)
}