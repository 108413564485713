import * as constType from './constant'

export function createPod(data){
    return {
        type : constType.CREATE_POD,
        data
    }
}

export function createPodSuccess(pod){
    return {
        type : constType.CREATE_POD_SUCCESS,
        pod
    }
}

export function podModal(modal){
    return {
        type : constType.POD_MODAL,
        modal
    }

}

export function podMsgClear(){
    return {
        type : constType.POD_MSG_CLEAR,
    }
}

export function podError(error){
    return {
        type : constType.CREATE_POD_FAILURE,
        error
    }
}

