import * as actionTypes from "./constant";
export function updateProfileImage(url){
    //alert(url)
    return {
        type : actionTypes.UPDATE_PROFILE_IMAGE,
        photoURL : url
    }
}
export function getPaymentHistory(uid, fromTimeUtc, toTimeUtc, val){
    return {
        type : actionTypes.GET_PAYMENT_HISTORY,
        uid,
        fromTimeUtc,
        toTimeUtc,
        val
    }
}
export function setPaymentHistory(paymentHistory){
    return {
        type : actionTypes.SET_PAYMENT_HISTORY,
        paymentHistory
    }
}
export function resetServiceType(){
    return {
        type : actionTypes.RESET_SERVICE
    }
}

export function getChart(chartDetail){
    return {
        type : actionTypes.GET_CHART,
        chartDetail
    }
}
export function setChart(chartDetail){
    return {
        type : actionTypes.SET_CHART,
        chartDetail
    }
}

export function setChartError(){
    return {
        type : actionTypes.SET_CHART_ERROR
    }
}
export function showEditProfileModal(){
    return {
        type : actionTypes.SHOW_EDIT_PROFILE_MODAL
    }
}
export function closeEditProfileModal(){
    return {
        type : actionTypes.CLOSE_EDIT_PROFILE_MODAL
    }
}

/*Get Sim Price List*/
export function getSimPrice() {
    return {
        type: actionTypes.GET_SIM_PRICE,
    }
}

export function getSimPriceSuccess(list) {
    return {
        type: actionTypes.GET_SIM_PRICE_SUCCESS,
        list
    }
}

export function getSimPriceError(error) {
    return {
        type: actionTypes.GET_SIM_PRICE_FAILURE,
        error
    }
}

