import * as actionTypes from "./constant";

export default function claimApproval(prevState = {
    loading: false,
    migratedVehRes: [],
    setmigratedVeh: '',
    response: ''
}, action) {
    switch (action.type) {
        case actionTypes.MIGRATED_VEH_HISTORY_SERVICE:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.MIGRATED_VEH_HISTORY_SUCCESS:
            return {
                ...prevState,
                migratedVehRes: action.res,
                loading: false,
                setmigratedVeh: 'SET_MIGRATED_VEH_DATA'
            }
        case actionTypes.MIGRATED_VEH_APPROVE_SERVICE:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.MIGRATED_VEH_APPROVE_SUCCESS:
            return {
                ...prevState,
                response: action.res,
                loading: false
            }
        case actionTypes.MIGRATED_VEH_APPROVE_FAILURE:
            return {
                ...prevState,
                response: action.error,
                loading: false
            }
        case actionTypes.MIGRATED_VEH_APPROVE_EMPTY:
            return {
                ...prevState,
                response: ''
            }
        case actionTypes.RESET_MIGRATED_VEH:
            return {
                ...prevState,
                loading: false,
                setmigratedVeh: ''
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
