export const CREATE_POD         = 'CREATE_POD'
export const CREATE_POD_SUCCESS = 'CREATE_POD_SUCCESS'
export const CREATE_POD_FAILURE = 'CREATE_POD_FAILURE'
export const POD_MSG_CLEAR = 'POD_MSG_CLEAR'
export const POD_MODAL = 'POD_MODAL'






