export const GET_PLAN_REPORTS           = 'GET_PLAN_REPORTS'
export const GET_PLAN_REPORTS_SUCCESS   = 'GET_PLAN_REPORTS_SUCCESS'
export const GET_PLAN_REPORTS_FAILURE   = 'GET_PLAN_REPORTS_FAILURE'

export const REPORT_PAGE                = 'REPORT_PAGE'
export const REPORT_PAGE_SUCCESS        = 'REPORT_PAGE_SUCCESS'
export const REPORT_PAGE_FAILURE        = 'REPORT_PAGE_FAILURE'

export const GET_ONGOING_EXCEL          = 'GET_ONGOING_EXCEL'
export const GET_ONGOING_EXCEL_SUCCESS  = 'GET_ONGOING_EXCEL_SUCCESS'
export const GET_ONGOING_EXCEL_FAILURE  = 'GET_ONGOING_EXCEL_FAILURE'

export const GET_COMPLETED_EXCEL         = 'GET_COMPLETED_EXCEL'
export const GET_COMPLETED_EXCEL_SUCCESS = 'GET_COMPLETED_EXCEL_SUCCESS'
export const GET_COMPLETED_EXCEL_FAILURE = 'GET_COMPLETED_EXCEL_FAILURE'