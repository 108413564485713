import * as actionTypes from './constant';
import { updateObject } from '../../../utils/object';

// Vehicle Reducer
const initialState = {
    error: null,
    loading: false,
    vehicles: [],
    liveStatusDataLoaded: false,
    liveStatusData: [],
    liveStatusSubscribers: [],
    latestSubscriptionIndex: null,
    liveStatusDataError: null,
    driverList : [],
    selectedDri : [],
    currentVehicleInfo : {},
    simTracking : "",
    currentVehicleData:{},
    availablePings:null
};

const loadVehiclesStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};

const loadVehicleSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        vehicles: action.vehicles
    });
};

const loadVehicleFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};
const loadCurrentVehicleSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        currentVehicleData: action.currentVehicleData
    });
};

const loadCurrentVehicleFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

//Drivers
const loadDriversStart = (state) => {
    return updateObject(state, { error: null, loading: true });
};
const storeDriverInitiate = (state,action) => {
    return updateObject(state, {
        driverId1: action.driverId1,
        driverId2 : action.driverId2
    });
}
const storeDriverStart = (state,action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        selectedDri: action.selectedDri
    });
}
const loadDriversSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        driverList: action.drivers
    });
};

const loadDriversFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

//Load view plan
// const viewPlanStart = (state, action) => {
//     return updateObject(state, { error: null, loading: true });
// };
const loadViewPlan = (state, action) => {
    
    return updateObject(state, {
        error: null,
        loading: false,
        viewPlanData: action.data
    });
};
const viewPlanSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        vehicles: action.vehicles
    });
};

const viewPlanFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    });
};

const simTracking = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        simTracking: action.statusData
    });
};

const subscribeLiveVehicleshStatus = (state, action) => {
    const updatedLiveStatusSubscribers = [...state.liveStatusSubscribers];
    updatedLiveStatusSubscribers.push(action.callback);

    return updateObject(state, {
        liveStatusSubscribers: updatedLiveStatusSubscribers,
        latestSubscriptionIndex: updatedLiveStatusSubscribers.length
    });
};

const unsubscribeLiveVehicleshStatus = (state, action) => {
    const updatedLiveStatusSubscribers = [...state.liveStatusSubscribers];
    updatedLiveStatusSubscribers.splice((action.index - 1), 1);
    return updateObject(state, {
        liveStatusSubscribers: updatedLiveStatusSubscribers,
        latestSubscriptionIndex: updatedLiveStatusSubscribers.length
    });
};

export const notifyLiveVehicleshStatusSuccess = (state, action) => {
    return updateObject(state, {
        liveStatusDataLoaded: true,
        liveStatusData: action.dataStatus,
        vehCountDetails : action.vehCountDetails,
        availablePings: action.availablePings
    });
}

export const notifyLiveVehicleshStatusFail = (state, action) => {
    return updateObject(state, {
        liveStatusDataError: action.error,
    });
}
export const viewPlan = (state,action) => {
    return updateObject(state, {
        viewPlan : true, createPlan : false })
}
export const createPlan = (state,action) => {
    return updateObject(state, {
        createPlan : true,
        viewPlan :false
    })
}
export const loadDrivers = (state,action) => {
    const driverList = [...action.driverList];
    driverList.push(action.driverList);

    return updateObject(state, {
        driverList: driverList,
    });
}
export const setVehicleInfo = (state,action) => {
    return updateObject(state, {
        currentVehicleInfo : action.vehicleInfo
    })
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_VEHICLES_START: return loadVehiclesStart(state);
        case actionTypes.LOAD_VEHICLES_SUCCESS: return loadVehicleSuccess(state, action);
        case actionTypes.LOAD_VEHICLES_FAIL: return loadVehicleFail(state, action);
        case actionTypes.LOAD_CURRENT_VEHICLE_SUCCESS: return loadCurrentVehicleSuccess(state, action);
        case actionTypes.LOAD_CURRENT_VEHICLE_FAIL: return loadCurrentVehicleFail(state, action);
        case actionTypes.SUBSCRIBE_LIVE_VEHICLES_STATUS: return subscribeLiveVehicleshStatus(state, action);
        case actionTypes.UNSUBSCRIBE_LIVE_VEHICLES_STATUS: return unsubscribeLiveVehicleshStatus(state, action);
        case actionTypes.NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_SUCCESS: return notifyLiveVehicleshStatusSuccess(state, action);
        case actionTypes.VIEW_PLAN : return viewPlan(state,action);
        case actionTypes.CREATE_PLAN : return createPlan(state,action);
        case actionTypes.LOAD_DRIVERS : return loadDrivers(state,action);

        case actionTypes.LOAD_DRIVERS_SUCCESS : return loadDriversSuccess(state,action);
        case actionTypes.LOAD_DRIVERS_START : return loadDriversStart(state);
        case actionTypes.LOAD_DRIVERS_FAIL : return loadDriversFail(state,action);
        case actionTypes.STORE_DRIVER_INITATE : return storeDriverInitiate(state,action);
        case actionTypes.STORE_DRIVER_START : return storeDriverStart(state,action);
        
        case actionTypes.VIEWPLAN_SUCCESS : return viewPlanSuccess(state,action);
        case actionTypes.VIEWPLAN_FAIL : return viewPlanFail(state,action);
        case actionTypes.VIEWPLAN_START : return loadViewPlan(state,action); 
        case actionTypes.SET_CURRENT_VEHICLE_INFO : return setVehicleInfo(state,action); 
        case actionTypes.SIM_TRACKING_STATUS_SUCCESS : return simTracking(state,action);
        default: return state;
    }
}

