import * as actionTypes from './constant'

/* Create Location */
export function configCreate(data) {
    return {
        type: actionTypes.CONFIG_CREATE,
        data
    }
}

export function configCreateSuccess(createData) {
    return {
        type: actionTypes.CONFIG_CREATE_SUCCESS,
        createData
    }
}

export function configError(error) {
    return {
        type: actionTypes.CONFIG_CREATE_FAILURE,
        error
    }
}

/* Create Location */
export function viewConfig(orgId) {
    return {
        type: actionTypes.CONFIG_VIEW,
        orgId
    }
}

export function configViewSuccess(list) {
    return {
        type: actionTypes.CONFIG_VIEW_SUCCESS,
        list
    }
}

export function viewError(error) {
    return {
        type: actionTypes.CONFIG_VIEW_FAILURE,
        error
    }
}
