import * as actionTypes from './constant'

export function getTripDetail(data) {
    return {
        type: actionTypes.GET_TRIP_DETAIL,
        data
    }
}

export function getTripDetailSuccess(data) {
    return {
        type: actionTypes.TRIP_DETAIL_SUCCESS,
        data
    }
}

export function getTripDetailError(error) {
    return {
        type: actionTypes.TRIP_DETAIL_FAILURE,
        error
    }
}


export function getTripDetailUsingLink(vehicleId, tripNum) {
    return {
        type: actionTypes.GET_TRIP_DETAIL_USING_LINK,
        vehicleId,
        tripNum
    }
}

export function getLiveData(driverId) {
    return {
        type: actionTypes.GET_LIVE_DATA,
        driverId
    }
}

export function getLiveDataSuccess(data) {
    return {
        type: actionTypes.GET_LIVE_DATA_SUCCESS,
        data
    }
}

export function getLiveDataError(error) {
    return {
        type: actionTypes.GET_LIVE_DATA_FAILURE,
        error
    }
}

export function resetLiveData() {
    return {
        type: actionTypes.RESET_LIVE_DATA,
    }
}


