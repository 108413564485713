import * as actionTypes from './constant'

export default function chartService(prevState = {
    data: '',
    driverTripCompleted: '',
    dashboardChart : '',
    tripsCompletedloading: true,
    driverLoading: true,
    driverCompletedLoading: false,
    dashboardChartLoading : false,
    driverList: [],
    error: '',
}, action) {
    switch (action.type) {
        case actionTypes.TRIPS_COMPLETED_CHART:
            return {
                ...prevState,
                error: action.error,
                data: '',
                tripsCompletedloading: true
            }
        case actionTypes.TRIPS_COMPLETED_CHART_SUCCESS:
            return {
                ...prevState,
                data: action.data.phm.data?action.data.phm.data : '' ,
                tripsCompletedloading: false
            }

        case actionTypes.TRIPS_COMPLETED_CHART_FAILURE:
            return {
                ...prevState,
                error: action.error,
                tripsCompletedloading: true
            }

        /* Driver Action */
        case actionTypes.GET_CHART_DRIVER_LIST:
            return {
                ...prevState,
                error: action.error,
                driverLoading: true
            }
        case actionTypes.GET_CHART_DRIVER_LIST_SUCCESS:
            return {
                ...prevState,
                driverList: action.driverData,
                driverLoading: false
            }
        case actionTypes.GET_CHART_DRIVER_LIST_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                driverLoading: false
            }
        /* Driver based trip count */
        case actionTypes.DRIVER_COMPLETED_CHART:
            return {
                ...prevState,
                error: action.error,
                driverCompletedLoading: true
            }
        case actionTypes.DRIVER_COMPLETED_CHART_SUCCESS:
            return {
                ...prevState,
                driverTripCompleted: action.data.phm.data?action.data.phm.data : '' ,
                driverCompletedLoading: false
            }
        case actionTypes.DRIVER_COMPLETED_CHART_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                driverCompletedLoading: false
            }

        /* Dashboard Chart */
        case actionTypes.DASHBOARD_CHART:
            return {
                ...prevState,
                error: action.error,
                dashboardChartLoading: true
            }
        case actionTypes.DASHBOARD_CHART_SUCCESS:
            return {
                ...prevState,
                dashboardChart: action.data.phm.data?action.data.phm.data : '' ,
                dashboardChartLoading: false
            }
        case actionTypes.DASHBOARD_CHART_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                dashboardChartLoading: false
            }

        default: {
            return {
                ...prevState
            }
        }
    }
}
