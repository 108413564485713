import * as actionTypes from "./constant";

export function getDriverVerifyService(fleetOwnerId,  fromTimeUtc, toTimeUtc) {
    return {
        type: actionTypes.GET_DRIVER_VERIFY,
        fleetOwnerId,
        fromTimeUtc,
        toTimeUtc
    }
}
export function setDriverVerifyList(driverList) {
    return {
        type: actionTypes.SET_DRIVER_VERIFY,
        driverList
    }
}
export function resetDriver(){
    return{
        type: actionTypes.RESET_DRIVER
    }
}
export function driverVerifyService(payLoad) {
    return {
        type: actionTypes.DRIVER_VERIFY_SERVICE,
        payLoad
    }
} export function driverVerifySuccess(res) {
    return {
        type: actionTypes.DRIVER_VERIFY_SUCCESS,
        res
    }
} export function driverVerifyError(error) {
    return {
        type: actionTypes.DRIVER_VERIFY_FAILURE,
        error
    }
}
export function driverVerifyEmptyRes(error) {
    return {
        type: actionTypes.DRIVER_VERIFY_EMPTY_RES
    }
}