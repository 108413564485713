import { 
LOAD_ALLTRUCK_SUCCESS, 
LOAD_ALLTRUCK_FAILURE,
LOAD_ALLTRUCK_LODING,
HAND_OVER,
HAND_OVER_SUCCESS,
HAND_OVER_FAILURE,
SHOW_ADD_TRUCK,
HIDE_ADD_TRUCK,
PLAN_SHOW_ADD_TRUCK
} from './constant'

export function allTruckServices( uid, history){
    return {
        type : LOAD_ALLTRUCK_LODING,
        uid : uid,
        history
    }
}

export function allTruckSuccess(truckData){
    return {
        type : LOAD_ALLTRUCK_SUCCESS,
        truckData
    }
}

export function allTruckError(error){
    return {
        type : LOAD_ALLTRUCK_FAILURE,
        error
    }
}

export function handOverServices( payload, history){
    return {
        type : HAND_OVER,
        payload,
        history
    }
}

export function handOverSuccess(handOver){
    return {
        type : HAND_OVER_SUCCESS,
        handOver
    }
}

export function handOverError(error){
    return {
        type : HAND_OVER_FAILURE,
        error
    }
}
export function showAddTruck(){
    return {
        type : SHOW_ADD_TRUCK,
    }
}
export function hideAddTruck(){
    return {
        type : HIDE_ADD_TRUCK,
    }
}
export function showAddTruckFromPlan(){
    return {
        type : PLAN_SHOW_ADD_TRUCK,
    }
}



