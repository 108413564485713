import { takeLatest, all, put } from "redux-saga/effects";
import * as actions from './action'
import { GET_SIM_USAGE, GET_ALL_DATA_SIM_USAGE, SIMCONSENT_GET_SPECIFIC_ORG_INFO, GET_CHART_SERVICE, GET_VEHICLELIST_CHART } from './constant'
import axios from '../../../utils/axios';

function* getSimUsageData(action) {
    var vehicleId;
    if(action.vehicleId === 'All Vehicles'){
        vehicleId = '';
    }
    else{
        vehicleId = action.vehicleId;
    }
    try {
        //var url ="getDriverLastLocationBasedOnFleetownerHistory?fleetOwner=wEKzym4ApSclS1cCWPflj9gKKkx1&fromDate=2020-09-16&toDate=2020-09-16";
        var url = `getDriverLastLocationBasedOnFleetownerHistory?fleetOwner=${action.uid}&fromDate=${action.fromdate}&toDate=${action.todate}&vehicleId=${vehicleId}`;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            var data = response.data;
            yield put(actions.setSimUsageData(data))
        }
    } catch (error) {
        console.log(error);
    }
}
function* chartService(action) {
    var payLoad = action.payLoad;
    try {
        var url = `getSimConsumedStatus`;
        var response = yield axios.post(url, payLoad).then(res => res);
        if (response) {
            var data = response.data.phm.data;
        }
        yield put(actions.chartSuccess(data))
    } catch (error) {
        yield put(actions.chartError(error))
    }

}
function* getAllSimData(action) {
    try {
        var url = `getPingHistory?fromDate=${action.fromdate}&fleetownerId=${action.uid}&toDate=${action.todate}`;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            var data = response.data;
        }
        yield put(actions.getAllSimDataSuccess(data))
    } catch (error) {
        yield put(actions.getAllSimDataError(error))
    }
}
function* getSpecificOrgInfoService(actionData) {
    try {
        let result = yield axios.post(`getOrgInfo?orgId=${actionData.organizationId}&userId=${actionData.uid}`).then(res => res.data);
        if (result.message === "SUCCESS") {
            let orgInfo = result.data;
            if (orgInfo) {
                yield put(actions.setAvailablePing(orgInfo.availablePings));
            } else {
                yield put(actionData.history.push('/login'))
            }
        }
    } catch (error) {
        console.log(error);
    }
}
// get vehicles
function* getVehiclesService(action) {
    try {
        const data = yield axios({
            url: `getVehicles?orgId=${action.organizationId}&fleetownerId=${action.uid}`,
            method: 'POST',
        })
            .then(response => {
                return response.data.dataList
            });
        yield put(actions.getVehiclesSuccess(data));
    } catch (error) {
        yield put(actions.getVehiclesError(error));
    }
}
export default function* simUsageSaga() {
    yield all([
        takeLatest(GET_SIM_USAGE, getSimUsageData),
        takeLatest(GET_ALL_DATA_SIM_USAGE, getAllSimData),
        takeLatest(GET_VEHICLELIST_CHART, getVehiclesService),
        takeLatest(GET_CHART_SERVICE, chartService),
        takeLatest(SIMCONSENT_GET_SPECIFIC_ORG_INFO, getSpecificOrgInfoService),
    ])

}