import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as constType from './constant'
import axios from '../../../../utils/axios';

 
function* createHandOver(actionData){
    try{
    const handover = yield axios({
            url: `addHandoverPlan`,
            method: 'POST',
            data: actionData.data
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.createHandOverSuccess(handover));
    } catch(error){
        console.log('create handover',error)
    }
}

function* updateHandOver(actionData){
    try{
    const handover = yield axios({
            url: `updateHandoverPlan`,
            method: 'POST',
            data: actionData.data
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.updateHandOverSuccess(handover));
    } catch(error){
        console.log('create handover',error)
    }
}

function* deleteHandOver(actionData){
    try{
    const handover = yield axios({
            url: `removeHandOverPlan?seqNum=${actionData.seqNum}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.deleteHandOverSuccess(handover));
    } catch(error){
        console.log('delete handover',error)
    }
}

function* forceHandOver(actionData){
    try{
    const handover = yield axios({
            url: `forceHandover?vehicleId=${actionData.vehicleId}`,
            method: 'POST',
            data: actionData.data
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.forceHandOverSuccess(handover));
    } catch(error){
        console.log('force handover',error)
    }
}



export default function* handOverSaga(){
    yield takeLatest(constType.CREATE_HANDOVER, createHandOver);
    yield takeLatest(constType.UPDATE_HANDOVER, updateHandOver);
    yield takeLatest(constType.DELETE_HANDOVER, deleteHandOver);
    yield takeLatest(constType.FORCE_HANDOVER, forceHandOver);
}