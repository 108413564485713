import * as actionTypes from "./constant";

export function emptyResponse(){
    return {
        type : actionTypes.EMPTY_RESPONSE_ORG_A,
    }
}
export function leaveOrgService(id){
    return {
        type : actionTypes.LEAVE_ORG,
        id,
    }
}
export function leaveOrgSuccess(deletedMsg){
    return {
        type : actionTypes.LEAVE_ORG_SUCCESS,
        deletedMsg
    }
}

export function leaveOrgError(error){
    return {
        type : actionTypes.LEAVE_ORG_FAILURE,
        error
    }
}
export function showHideModal(value){
    return {
        type : actionTypes.MODAL,
        value
    }
}
export function hideAssignedOrg(showAssignedOrg, orgObj){
    return {
        type : actionTypes.MY_ORG,
        orgObj,
        showAssignedOrg,
        organizationId:orgObj.organisationId,
        organizationName:orgObj.organizationName
    }
}

