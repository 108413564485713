import * as actionTypes from "./constant";

export default function assignOrg(prevState = {
    loading: true,
    error: '',
    modal: false,
    leaveOrgResponse: '',
    orgList: [],
    invitedOrgList: [],
    createdOrgRes: '',
    showAssignedOrg: true,
    organizationId: '',
    organizationName: '',
    editedOrgRes: ''
}, action) {
    switch (action.type) {
        case actionTypes.MY_ORGANIZATION_DROPDOWN_SUCCESS:
            return {
                ...prevState,
                orgList: action.orgData.myOrg,
                createdOrgRes: '',
                leaveOrgResponse: '',
                invitedOrgList: action.orgData.invitedOrg,
                loading: false,
                editedOrgRes: ''
            }
        case actionTypes.MY_ORGANIZATION_DROPDOWN_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true,
                createdOrgRes: '',
                editedOrgRes: '',
                leaveOrgResponse: ''
            }
        case actionTypes.LEAVE_ORG:
            return {
                ...prevState,
                error: action.error,
                loading: true,
                leaveOrgResponse: '',
                createdOrgRes: '',
                editedOrgRes: ''
            }
        case actionTypes.LEAVE_ORG_SUCCESS:
            return {
                ...prevState,
                leaveOrgResponse: action.deletedMsg,
                loading: false,
                createdOrgRes: '',
                editedOrgRes: ''
            }
        case actionTypes.LEAVE_ORG_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true,
                leaveOrgResponse: ''

            }
        case actionTypes.EMPTY_RESPONSE_ORG_A:
            return {
                ...prevState,
                response: "",
                createdOrgRes: '',
                delOrgRes: '',
                leaveOrgResponse: '',
            }
        case actionTypes.MODAL:
            return {
                ...prevState,
                modal: action.value,
                createdOrgRes: '',
                editedOrgRes: ''
            }
        case actionTypes.MY_ORG:
            return {
                ...prevState,
                showAssignedOrg: action.showAssignedOrg,
                organizationId: action.organizationId,
                organizationName: action.organizationName,
                createdOrgRes: '',
                editedOrgRes: '',
                leaveOrgResponse: ''
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
