import * as actionTypes from "./constant";

export default function expenseReports(prevState = {
    expenseReportsData: [],
    loading: false,
    vehicleList: []
}, action) {
    switch (action.type) {
        case actionTypes.GET_EXPENSE_FLEET_REPORTS:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.GET_EXPENSE_FLEET_REPORTS_SUCCESS:
            return {
                ...prevState,
                expenseReportsData: action.expenseReports.data,
                loading: false
            }
        case actionTypes.GET_EXPENSE_FLEET_REPORTS_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
          
        default: {
            return {
                ...prevState
            }
        }
    }
}
