import * as actionTypes from "./constant";

export function getExpenseTypeService(){
    return {
        type : actionTypes.GET_EXPENSE_TYPE       
    }
}
export function getExpenseTypeSuccess(expenseTypes){
    return {
        type : actionTypes.GET_EXPENSE_TYPE_SUCCESS,
        expenseTypes
    }
}

export function getExpenseTypeError(error){
    return {
        type : actionTypes.GET_EXPENSE_TYPE_FAILURE,
        error
    }
}
export function getDriversExpenseService(orgOwnerId, vehicleId, planNo){
    return {
        type : actionTypes.GET_EXPENSE_DRIVER,
        orgOwnerId, 
        vehicleId, 
        planNo       
    }
}
export function getDriversExpenseSuccess(driverList){
    return {
        type : actionTypes.GET_EXPENSE_DRIVER_SUCCESS,
        driverList
    }
}

export function getDriversExpenseError(error){
    return {
        type : actionTypes.GET_EXPENSE_DRIVER_FAILURE,
        error
    }
}
export function getExpenseHistoryService(id, planNo){
    return {
        type : actionTypes.GET_EXPENSE_HISTORY,
        id,
        planNo      
    }
}
export function getExpenseHistorySuccess(expenseHistroy){
    return {
        type : actionTypes.GET_EXPENSE_HISTORY_SUCCESS,
        expenseHistroy
    }
}
export function getExpenseHistoryError(error){
    return {
        type : actionTypes.GET_EXPENSE_HISTORY_FAILURE,
        error
    }
}

