import * as actionTypes from './constant'

export default function locationService(prevState = {
    list : [],
    createLocation: '',
    getLocation: '',
    updateLocation : '',
    deleteLocation: '',
    statusLocation : '',
    loading: true,
    error:'',
}, action){
    switch(action.type) {

        /* Location List */
        case actionTypes.LOCATION_LIST:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.LOCATION_LIST_SUCCESS:
            return {
                ...prevState,
                list : action.locationData,
                loading: false
            }

        /* Create  Location */
        case actionTypes.LOCATION_CREATE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.LOCATION_CREATE_SUCCESS:
            return {
                ...prevState,
                createLocation : action.createData,
                loading: false
        }

        /* Edit && view  Location */
        case actionTypes.LOCATION_GET:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.LOCATION_GET_SUCCESS:
            return {
                ...prevState,
                getLocation : action.getData,
                loading: false
        }

        /* Create  Location */
        case actionTypes.LOCATION_UPDATE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.LOCATION_UPDATE_SUCCESS:
            return {
                ...prevState,
                updateLocation : action.updateData,
                loading: false
        }


        /* Delete Location */
        case actionTypes.LOCATION_DELETE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.LOCATION_DELETE_SUCCESS:
            return {
                ...prevState,
                deleteLocation : action.deleteData,
                loading: false
        }

        /* Status Change Location */
        case actionTypes.LOCATION_STATUS:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.LOCATION_STATUS_SUCCESS:
            return {
                ...prevState,
                statusLocation : action.statusData,
                loading: false
        }


        case actionTypes.LOCATION_FAILURE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        default : {
            return {
                ...prevState
            }
        }
    }
}
