export const GET_DOC_TYPE = "GET_DOC_TYPE";
export const GET_DOC_TYPE_SUCCESS = "GET_DOC_TYPE_SUCCESS";
export const GET_DOC_TYPE_FAILURE = "GET_DOC_TYPE_FAILURE";
export const GET_VEHICLELIST_DOC = "GET_VEHICLELIST_DOC";
export const GET_VEHICLELIST_DOC_SUCCESS = "GET_VEHICLELIST_DOC_SUCCESS";
export const GET_VEHICLELIST_DOC_FAILURE = "GET_VEHICLELIST_DOC_FAILURE";
export const CREATE_DOC = "CREATE_DOC";
export const CREATE_DOC_SUCCESS = "CREATE_DOC_SUCCESS";
export const CREATE_DOC_FAILURE = "CREATE_DOC_FAILURE";
export const UPDATE_DOC = "UPDATE_DOC";
export const UPDATE_DOC_SUCCESS = "UPDATE_DOC_SUCCESS";
export const UPDATE_DOC_FAILURE = "UPDATE_DOC_FAILURE";
export const VIEW_DOC = "VIEW_DOC";
export const VIEW_DOC_SUCCESS = "VIEW_DOC_SUCCESS";
export const VIEW_DOC_FAILURE = "VIEW_DOC_FAILURE";
export const DELETE_DOC = "DELETE_DOC";
export const DELETE_DOC_SUCCESS = "DELETE_DOC_SUCCESS";
export const DELETE_DOC_FAILURE = "DELETE_DOC_FAILURE";
export const EMPTY_RESPONSE_REMINDER = "EMPTY_RESPONSE_REMINDER";
export const GET_DRIVERLIST_DOC = "GET_DRIVERLIST_DOC";
export const GET_DRIVERLIST_DOC_SUCCESS = "GET_DRIVERLIST_DOC_SUCCESS";
export const GET_DRIVERLIST_DOC_FAILURE = "GET_DRIVERLIST_DOC_FAILURE";

