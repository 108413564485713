import * as actionTypes from "./constant";

export function addDriverDetails(driverDetails) {
    return {
        type: actionTypes.ADD_DRIVER,
        driverDetails,
    }
}
export function editDriverDetails(driverDetails) {
    return {
        type: actionTypes.EDIT_DRIVER,
        driverDetails,
    }
}
export function setResponseData(response) {
    return {
        type: actionTypes.SET_RESPONSE_DATA,
        response
    }
}
export function getDrivers(orgOwnerId) {
    return {
        type: actionTypes.GET_DRIVERS,
        orgOwnerId
    }
}
export function refreshDriverData(orgOwnerId) {
    return {
        type: actionTypes.REFRESH_DRIVER,
        orgOwnerId
    }
}
export function setDriverDetails(driverList) {
    return {
        type: actionTypes.SET_DRIVER_DETAILS,
        driverList
    }
}
export function showHideModal(value) {
    return {
        type: actionTypes.MODAL,
        value
    }
}
export function removeDriver(id, orgId) {
    return {
        type: actionTypes.REMOVE_DRIVER,
        id,
        orgId
    }
}
export function inviteDriver(id, uid) {
    return {
        type: actionTypes.INVITE_DRIVER,
        id,
        uid
    }
}
export function updateFavouriteStatus(data, uid) {
    return {
        type: actionTypes.UPDATE_FAVOURITE_STATUS,
        data,
        uid
    }
}
export function sendConsentMsg(data) {
    return {
        type: actionTypes.SEND_CONSENT_MSG,
        data
    }
}
export function emptyResponse() {
    return {
        type: actionTypes.EMPTY_RESPONSE,
    }
}
export function changeDriverlist(boo) {
    return {
        type: actionTypes.CHANGE_DRIVER_LIST_T,
        boo
    }
}

//EXCEL DOWNLOAD
export function getExcelDownload(accountUserId) {
    return {
        type: actionTypes.EXCEL_DOWNLOAD,
        accountUserId
    }
}

export function getExcelDownloadSuccess(data) {
    return {
        type: actionTypes.EXCEL_DOWNLOAD_SUCCESS,
        data
    }
}

export function getExcelDownloadError(error) {
    return {
        type: actionTypes.EXCEL_DOWNLOAD_FAILURE,
        error
    }
}

export function getDriversWithSearchText(orgOwnerId, searchText) {
    return {
        type: actionTypes.GET_DRIVERS_WITH_SEARCH_TEXT,
        orgOwnerId, searchText
    }
}

export function setDriverList(driverData) {
    return {
        type: actionTypes.DRIVER_LIST,
        driverData
    }
}

