import { 
    LOGIN_SUCCESS, 
    LOGIN_FAILURE, 
    DO_LOGIN,SET_UID, 
    SET_REG_TYPE, 
    SET_ORG_INFO,
    GET_DEFAULT_ORG_INFO,
    GET_SPECIFIC_ORG_INFO ,
    REFRESH_TOKEN ,
    EMPTY_NEW_REG_INFO ,
    SWITCH_LOADER, 
    REGISTER_PROCESS } from './constant'

export function loginService( data, history, vehicle){
    return {
        type : DO_LOGIN,
        data,
        history,
        vehicle
    }
}

export function loginSuccess(userData){
    return {
        type : LOGIN_SUCCESS,
        userData
    }
}

export function loginError(error){
    return {
        type : LOGIN_FAILURE,
        error
    }
}

export function registerService( data, history, vehicle){
    return {
        type : REGISTER_PROCESS,
        data,
        history,
        vehicle
    }
}

export function setUid(uid){
    return {
        type : SET_UID,
        uid
    }
}
export function setRegType(newRegInfo){
    return {
        type : SET_REG_TYPE,
        newRegInfo
    }
}
export function setOrgInfo(orgInfo){
    return {
        type : SET_ORG_INFO,
        orgInfo
    }
}
export function getDefaultOrgInfo(uid,history){
    return {
        type : GET_DEFAULT_ORG_INFO,
        uid,
        history
    }
}
export function getSpecificOrgInfo(organizationId,uid){
    return {
        type : GET_SPECIFIC_ORG_INFO,
        organizationId,
        uid
    }
}
export function refreshToken(data,history){
    return {
        type : REFRESH_TOKEN,
        data,
        history
    }
}
export function emptyNewRegistrationInfo(organizationId,uid){
    return {
        type : EMPTY_NEW_REG_INFO,
    }
}
export function setSwitchLoader(value){
    return {
        type : SWITCH_LOADER,
        value
    }
}
