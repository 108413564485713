export const FUEL_LIST = 'FUEL_LIST'
export const FUEL_LIST_SUCCESS = 'FUEL_LIST_SUCCESS'
export const FUEL_LIST_FAILURE = 'FUEL_LIST_FAILURE'

export const FUEL_HISTORY = 'FUEL_HISTORY'
export const FUEL_HISTORY_SUCCESS = 'FUEL_HISTORY_SUCCESS'
export const FUEL_HISTORY_FAILURE = 'FUEL_HISTORY_FAILURE'

export const FAVORITE_HISTORY = 'FAVORITE_HISTORY'
export const FAVORITE_HISTORY_SUCCESS = 'FAVORITE_HISTORY_SUCCESS'
export const FAVORITE_HISTORY_FAILURE = 'FAVORITE_HISTORY_FAILURE'


export const FAVORITE = 'FAVORITE'
export const FAVORITE_SUCCESS = 'FAVORITE_SUCCESS'
export const FAVORITE_FAILURE = 'FAVORITE_FAILURE'  

export const COLLAPSE_STATUS = 'COLLAPSE_STATUS'


