import * as actionTypes from './constant'

export function getTripCompletedChart(data) {
    return {
        type: actionTypes.TRIPS_COMPLETED_CHART,
        data
    }
}

export function tripCompletedChartSuccess(data) {
    return {
        type: actionTypes.TRIPS_COMPLETED_CHART_SUCCESS,
        data
    }
}

export function tripCompletedChartError(error) {
    return {
        type: actionTypes.TRIPS_COMPLETED_CHART_FAILURE,
        error
    }
}

/* GET DRIVER LIST */
export function getDriver(orgOwnerId){
    return {
        type : actionTypes.GET_CHART_DRIVER_LIST,
        orgOwnerId
    }
}

export function getDriverSuccess(driverData){
    return {
        type : actionTypes.GET_CHART_DRIVER_LIST_SUCCESS,
        driverData : driverData.dataList
    }
}

export function driverError(error){
    return {
        type : actionTypes.GET_CHART_DRIVER_LIST_FAILURE,
        error
    }
}

/* Driver and trip completed */
export function getDriverBasedCompletedChart(data) {
    return {
        type: actionTypes.DRIVER_COMPLETED_CHART,
        data
    }
}

export function driverBasedCompletedChartSuccess(data) {
    return {
        type: actionTypes.DRIVER_COMPLETED_CHART_SUCCESS,
        data
    }
}

export function driverBasedCompletedChartError(error) {
    return {
        type: actionTypes.DRIVER_COMPLETED_CHART_FAILURE,
        error
    }
}

/* Dashboard Chart */
export function getDashboardChart(data) {
    return {
        type: actionTypes.DASHBOARD_CHART,
        data
    }
}

export function dashboardChartSuccess(data) {
    return {
        type: actionTypes.DASHBOARD_CHART_SUCCESS,
        data
    }
}

export function dashboardChartError(error) {
    return {
        type: actionTypes.DASHBOARD_CHART_FAILURE,
        error
    }
}
