import * as constType from './constant'

export default function podService(prevState = {
    podMsg: '',
    loading: true,
    error: '',
    podModal : false
}, action) {
    switch (action.type) {
        case constType.CREATE_POD:
            return {
                ...prevState,
                loading: true
            }

        case constType.CREATE_POD_SUCCESS:
            return {
                ...prevState,
                podMsg: action.pod.message,
                pod: action.pod.dataList,
                loading: false
            }
        case constType.POD_MSG_CLEAR:
            return {
                ...prevState,
                podMsg: '',
                loading: false
            }
        case constType.POD_MODAL:
            return {
                ...prevState,
                podModal: action.modal,
                loading: false
            }

        case constType.CREATE_POD_FAILURE:
            return {
                ...prevState,
                loading: true
            }

        default: {
            return {
                ...prevState
            }
        }
    }
}
