import {
    GET_SIM_USAGE,
    SET_SIM_USAGE,
    RESET_SERVICE,
    GET_ALL_DATA_SIM_USAGE,
    GET_ALL_DATA_SIM_USAGE_SUCCESS,
    GET_ALL_DATA_SIM_USAGE_ERROR,
    SIMCONSENT_SET_AVAILABLE_PING,
    GET_CHART_SUCCESS,
    GET_CHART_FAILURE,
    GET_CHART_SERVICE,
    GET_VEHICLELIST_CHART,
    GET_VEHICLELIST_CHART_SUCCESS,
    GET_VEHICLELIST_CHART_FAILURE
} from './constant'

export default function simUsage(prevState = {
    data: [],
    loading: true,
    error: '',
    serviceType: '',
    allSimDataList: [],
    availablePings: '',
    chartResponse: [],
    vehicleListChart: [],
    consumedPings: 0
}, action) {
    switch (action.type) {
        case GET_SIM_USAGE:
            return {
                ...prevState,
                loading: true
            }
        case SET_SIM_USAGE:
            return {
                ...prevState,
                data: action.data,
                serviceType: 'SET_SIM_USAGE',
                loading: false
            }
        case GET_ALL_DATA_SIM_USAGE:
            return {
                ...prevState,
                data: action,
                loading: true
            }
        case GET_ALL_DATA_SIM_USAGE_SUCCESS:
            return {
                ...prevState,
                allSimDataList: action.allSimDataList.phm.dataList,
                consumedPings: action.allSimDataList.phm.consumedPings,
                loading: false
            }
        case GET_ALL_DATA_SIM_USAGE_ERROR:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case RESET_SERVICE:
            return {
                ...prevState,
                serviceType: ''
            }
        case SIMCONSENT_SET_AVAILABLE_PING:
            return {
                ...prevState,
                availablePings: action.availablePings
            }
        case GET_CHART_SERVICE:
            return {
                ...prevState,
                loading: true
            }
        case GET_CHART_SUCCESS:
            return {
                ...prevState,
                chartResponse: action.res,
                loading: false
            }
        case GET_CHART_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case GET_VEHICLELIST_CHART:
            return {
                ...prevState
            }
        case GET_VEHICLELIST_CHART_SUCCESS:
            return {
                ...prevState,
                vehicleListChart: action.vehicleList
            }
        case GET_VEHICLELIST_CHART_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}

