import * as actionTypes from "./constant";

export default function expenseHistroy(prevState = {
    delExpenseRes: null,
    error: null,
    logExpenseList: []
}, action) {
    switch (action.type) {
        case actionTypes.DELETE_EXPENSE:
            return {
                ...prevState,
                data: action.seqNum
            }
        case actionTypes.DELETE_EXPENSE_SUCCESS:
            return {
                ...prevState,
                delExpenseRes: action.delExpenseRes
            }
        case actionTypes.DELETE_EXPENSE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.LOG_EXPENSE:
            return {
                ...prevState,
                data: action.seqNum
            }
        case actionTypes.LOG_EXPENSE_SUCCESS:
            return {
                ...prevState,
                logExpenseList: action.logExpenseRes.dataList
            }
        case actionTypes.LOG_EXPENSE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.EMPTY_DEL_EXPENSE_RESPONSE:
            return {
                ...prevState,
                delExpenseRes: ""
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
