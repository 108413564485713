import * as actionTypes from './constant'

/* Create Location */
export function getSharedTrips(planNumber){
    return {
        type : actionTypes.GET_SHARED_TRIPS,
        planNumber
    }
}

export function sharedTripSuccess(trips){
    return {
        type : actionTypes.SHARED_TRIPS_SUCCESS,
        trips
    }
}

export function sendInvite(data){
    return {
        type : actionTypes.SHARE_TRIPS,
        data
    }
}

export function sendInviteSuccess(data){
    return {
        type : actionTypes.TRIP_SHARED_SUCCESS,
        data
    }
}

export function loadVehicles(data){
    return {
        type : actionTypes.LOAD_VEHICLES,
        data
    }
}

export function additionalLoadVehicles(data){
    return {
        type : actionTypes.ADDITIONAL_LOAD_VEHICLES,
        data
    }
}

export function loadVehicleSuccess(vehicles,responseType){
    return {
        type : actionTypes.LOAD_VEHICLES_SUCCESS,
        vehicles,
        responseType
    }
}

export function loadVehiclesFail(error){
    return {
        type : actionTypes.LOAD_VEHICLES_FAIL,
        error
    }
}
export function resetAlert(){
    return {
        type: actionTypes.RESET_ALERT
    }
}

/* remove email from shareTrip */
export const removeEmailfromShareTrip = id => ({
    type: actionTypes.REMOVE_EMAIL_FROM_SHARE_TRIP,
    id
});

export const removeEmailfromShareTripSuccess = data =>({
    type: actionTypes.REMOVE_EMAIL_FROM_SHARE_TRIP_SUCCESS,
    data
});

export const removeEmailfromShareTripError = data =>({
    type: actionTypes.REMOVE_EMAIL_FROM_SHARE_TRIP_ERROR,
    data
});
export const resetRemoveEmail = () => ({
    type: actionTypes.RESET_REMOVE_EMAIL
});