import * as actionTypes from './constant'

export default function locationService(prevState = {
    list : [],
    create: '',
    loading: true,
    error:'',
}, action){
    switch(action.type) {

        //View Config
        case actionTypes.CONFIG_VIEW:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.CONFIG_VIEW_SUCCESS:
            return {
                ...prevState,
                list : action.list.data,
                loading: false
        }

        case actionTypes.CONFIG_VIEW_FAILURE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }

        //Create  Config
        case actionTypes.CONFIG_CREATE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.CONFIG_CREATE_SUCCESS:
            return {
                ...prevState,
                create : action.createData,
                loading: false
        }

        case actionTypes.CONFIG_CREATE_FAILURE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        default : {
            return {
                ...prevState
            }
        }
    }
}
