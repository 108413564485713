import * as constType from './constant'

export function truckHistoryService( payload){
    return {
        type : constType.LOAD_TRUCKHISTORY_LODING,
        payload        
    }
}

export function truckHistorySuccess(truckData){
    return {
        type : constType.LOAD_TRUCKHISTORY_SUCCESS,
        truckData
    }
}

export function truckHistoryError(error){
    return {
        type : constType.LOAD_TRUCKHISTORY_FAILURE,
        error
    }
}
export function savePodDataList(payload){
    return {
        type : constType.SAVE_POD_LIST,
        payload
    }
}

/* GET VEHICLE LIST */
export function getVehicle(uid){
    return {
        type : constType.LOAD_VEHICLE,
        uid: uid
    }
}

export function vehicleSuccess(vehicleData){
    return {
        type : constType.LOAD_VEHICLE_SUCCESS,
        vehicleData
    }
}

export function vehicleError(error){
    return {
        type : constType.LOAD_VEHICLE_FAILURE,
        error
    }
}

/* GET DRIVER LIST */
export function getDriver(orgOwnerId){
    return {
        type : constType.GET_LOAD_DRIVER,
        orgOwnerId
    }
}

export function getDriverSuccess(driverData){
    return {
        type : constType.GET_LOAD_DRIVER_SUCCESS,
        driverData : driverData.dataList
    }
}

export function driverError(error){
    return {
        type : constType.GET_LOAD_DRIVER_FAILURE,
        error
    }
}

export function displayModeHandler(mode){
    return {
        type : constType.DISPLAY_MODE,
        mode
    }
}

/* history detail page */
export function historyDetail(data) {
    return {
        type: constType.HISTORY_DETAIL,
        data,
    }
}

export function historyDetailSuccess(getDetail) {
    return {
        type: constType.HISTORY_DETAIL_SUCCESS,
        getDetail
    }
}

export function historyDetailError(error) {
    return {
        type: constType.HISTORY_DETAIL_FAILURE,
        error
    }
}
/* get trip reports */
export function getTripReportService(data) {
    return {
        type: constType.GET_TRIP_REPORTS,
        data,
    }
}

export function getTripReportSuccess(getReports) {
    return {
        type: constType.GET_TRIP_REPORTS_SUCCESS,
        getReports
    }
}

export function getTripReportError(error) {
    return {
        type: constType.GET_TRIP_REPORTS_FAILURE,
        error
    }
}

export function getFuelData(data) {
    return {
        type: constType.GET_FUEL_DATA,
        data,
    }
}

export function getFuelDataSuccess(fuelData) {
    return {
        type: constType.GET_FUEL_DATA_SUCCESS,
        fuelData
    }
}

export function getFuelDataError(error) {
    return {
        type: constType.GET_FUEL_DATA_FAILURE,
        error
    }
}