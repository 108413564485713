export const LOAD_VEHICLES_START = "LOAD_VEHICLES_START";
export const LOAD_VEHICLES_INITIATE = "LOAD_VEHICLES_INITIATE";
export const LOAD_VEHICLES_SUCCESS = "LOAD_VEHICLES_SUCCESS";
export const LOAD_VEHICLES_FAIL = "LOAD_VEHICLES_FAIL";
export const LOAD_CURRENT_VEHICLE_INITIATE = "LOAD_CURRENT_VEHICLE_INITIATE";
export const LOAD_CURRENT_VEHICLE_SUCCESS = "LOAD_CURRENT_VEHICLE_SUCCESS";
export const LOAD_CURRENT_VEHICLE_FAIL = "LOAD_CURRENT_VEHICLE_FAIL";
export const SUBSCRIBE_LIVE_VEHICLES_STATUS = "SUBSCRIBE_LIVE_VEHICLES_STATUS";
export const SUBSCRIBE_LIVE_VEHICLES_STATUS_INITIATE = "SUBSCRIBE_LIVE_VEHICLES_STATUS_INITIATE";
export const UNSUBSCRIBE_LIVE_VEHICLES_STATUS = "UNSUBSCRIBE_LIVE_VEHICLES_STATUS";
export const NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_SUCCESS = "NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_SUCCESS";
export const NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_FAIL = "NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_FAIL"
export const VIEW_PLAN = "VIEW_PLAN"
export const CREATE_PLAN = "CREATE_PLAN"
export const LOAD_DRIVERS = "LOAD_DRIVERS"
export const LOAD_DRIVERS_START = "LOAD_DRIVERS_START";
export const LOAD_DRIVERS_INITIATE = "LOAD_DRIVERS_INITIATE";
export const LOAD_DRIVERS_FAIL = "LOAD_DRIVERS_FAIL";
export const LOAD_DRIVERS_SUCCESS = "LOAD_DRIVERS_SUCCESS";
export const STORE_DRIVER_INITATE = "STORE_DRIVER_INITATE";
export const STORE_DRIVER_START= "STORE_DRIVER_START";
export const LOAD_VIEW_PLANS = "LOAD_VIEW_PLANS";
export const VIEWPLAN_START = "VIEWPLAN_START";
export const VIEWPLAN_FAIL = "VIEWPLAN_FAIL";
export const VIEWPLAN_SUCCESS = "VIEWPLAN_SUCCESS";
export const LOAD_VIEWPLAN_SUCCESS = "LOAD_VIEWPLAN_SUCCESS";
export const SET_CURRENT_VEHICLE_INFO = "SET_CURRENT_VEHICLE_INFO";
export const SIM_TRACKING_STATUS = "SIM_TRACKING_STATUS";
export const SIM_TRACKING_STATUS_SUCCESS = "SIM_TRACKING_STATUS_SUCCESS";


export const docLocation = "http://vamotest.sgp1.cdn.digitaloceanspaces.com/TESTDRS/";