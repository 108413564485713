

import * as actionTypes from "./constant";

// get vehiclelist
export function getVehiclesService(organizationId) {
    return {
        type: actionTypes.GET_VEHICLELIST_TYRE,
        organizationId
    }
}
export function getVehiclesSuccess(vehicleList) {
    return {
        type: actionTypes.GET_VEHICLELIST_TYRE_SUCCESS,
        vehicleList
    }
}

export function getVehiclesError(error) {
    return {
        type: actionTypes.GET_VEHICLELIST_TYRE_FAILURE,
        error
    }
}

// get tyre brand
export function getTyreBrandService() {
    return {
        type: actionTypes.GET_TYRE_BRAND
        
    }
}
export function getTyreBrandSuccess(tyreBrandList) {
    return {
        type: actionTypes.GET_TYRE_BRAND_SUCCESS,
        tyreBrandList
    }
}

export function getTyreBrandError(error) {
    return {
        type: actionTypes.GET_TYRE_BRAND_FAILURE,
        error
    }
}

// add tyre
export function addTyreService(payLoad) {
    return {
        type: actionTypes.ADD_TYRE,
        payLoad
    }
}
export function addTyreSuccess(response) {
    return {
        type: actionTypes.ADD_TYRE_SUCCESS,
        response
    }
}

export function addTyreError(error) {
    return {
        type: actionTypes.ADD_TYRE_FAILURE,
        error
    }
}
// UPDATE tyre
export function updateTyreService(payLoad) {
    return {
        type: actionTypes.UPDATE_TYRE,
        payLoad
    }
}
export function updateTyreSuccess(response) {
    return {
        type: actionTypes.UPDATE_TYRE_SUCCESS,
        response
    }
}

export function updateTyreError(error) {
    return {
        type: actionTypes.UPDATE_TYRE_FAILURE,
        error
    }
}
// move
export function migrateTyreService(data) {
    return {
        type: actionTypes.MIGRATE_TYRE,
        data
    }
}
export function migrateTyreSuccess(response) {
    return {
        type: actionTypes.MIGRATE_TYRE_SUCCESS,
        response
    }
}
export function migrateTyreError(error) {
    return {
        type: actionTypes.MIGRATE_TYRE_FAILURE,
        error
    }
}

 // audit
export function auditTyreService(id, uid) {
    return {
        type: actionTypes.GET_TYRE_AUDITS,
        id,
        uid
    }
}
export function auditTyreSuccess(auditList) {
    return {
        type: actionTypes.GET_TYRE_AUDITS_SUCCESS,
        auditList
    }
}

export function auditTyreError(error) {
    return {
        type: actionTypes.GET_TYRE_AUDITS_FAILURE,
        error
    }
}


// empty response
export function emptyResponse() {
    return {
        type: actionTypes.EMPTY_RESPONSE_TYRE   
    }
}






// get list
export function getTyreInventoryService(organizationId, uid) {
    return {
        type: actionTypes.GET_TYRE_INVENTORY,
        organizationId
    }
}
export function getTyreInventorySuccess(tyreInventoy) {
    return {
        type: actionTypes.GET_TYRE_INVENTORY_SUCCESS,
        tyreInventoy       
    }
}

export function getTyreInventoryError(error) {
    return {
        type: actionTypes.GET_TYRE_INVENTORY_FAILURE,
        error
    }
}


// get audit list
export function getHistoryTyreService(serialNum) {
    return {
        type: actionTypes.TYRE_HISTORY_SERVICE,
        serialNum
    }
}
export function getHistoryTyreSuccess(tyreHistory) {
    return {
        type: actionTypes.TYRE_HISTORY_SUCCESS,
        tyreHistory       
    }
}

export function getHistoryTyreError(error) {
    return {
        type: actionTypes.TYRE_HISTORY_FAILURE,
        error
    }
}

export function getTyrePositions() {
    return {
        type: actionTypes.GET_TYRE_POSITIONS
    }
}

export function getTyrePositionsSuccess(data) {
    return {
        type: actionTypes.GET_TYRE_POSITIONS_SUCCESS,
        data
    }
}