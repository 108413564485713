export const VIEW_DRIVER_CONFIG = 'VIEW_DRIVER_CONFIG_CREATE'
export const VIEW_DRIVER_CONFIG_SUCCESS = 'VIEW_DRIVER_CONFIG_SUCCESS'
export const VIEW_DRIVER_CONFIG_FAILURE = 'VIEW_DRIVER_CONFIG_FAILURE'

export const DRIVER_CONFIG = 'DRIVER_CONFIG_CREATE'
export const DRIVER_CONFIG_SUCCESS = 'DRIVER_CONFIG_SUCCESS'
export const DRIVER_CONFIG_FAILURE = 'DRIVER_CONFIG_FAILURE'

export const UPDATE_DRIVER_CONFIG = 'UPDATE_DRIVER_CONFIG'
export const UPDATE_DRIVER_CONFIG_SUCCESS = 'UPDATE_DRIVER_CONFIG_SUCCESS'
export const UPDATE_DRIVER_CONFIG_FAILURE = 'UPDATE_DRIVER_CONFIG_FAILURE'

export const DRIVER_REDEEM = 'DRIVER_REDEEM'
export const DRIVER_REDEEM_SUCCESS = 'DRIVER_REDEEM_SUCCESS'
export const DRIVER_REDEEM_FAILURE = 'DRIVER_REDEEM_FAILURE'

export const DRIVER_PLAN = 'DRIVER_PLAN'
export const DRIVER_PLAN_SUCCESS = 'DRIVER_PLAN_SUCCESS'
export const DRIVER_PLAN_FAILURE = 'DRIVER_PLAN_FAILURE'

export const REDEEM_POINT = 'REDEEM_POINT'
export const REDEEM_POINT_SUCCESS = 'REDEEM_POINT_SUCCESS'
export const REDEEM_POINT_FAILURE = 'REDEEM_POINT_FAILURE'

export const LOAD_DRIVER = 'LOAD_DRIVER'
export const LOAD_DRIVER_SUCCESS = 'LOAD_DRIVER_SUCCESS'
export const LOAD_DRIVER_FAILURE = 'LOAD_DRIVER_FAILURE'