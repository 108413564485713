import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';
// get type
function* getDocTypeService() {
    try {
        let res = yield axios.get(`getVehicleDocTypes`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getDocTypeSuccess(res));
    } catch (error) {
        yield put(actions.getDocTypeError(error));
    }
}
// get vehicles
function* getVehiclesService(action) {
    try {
        const data = yield axios({
            url: `getVehicles?orgId=${action.organizationId}`,
            method: 'POST',
        })
            .then(response => {
                return response.data.dataList
            });
        yield put(actions.getVehiclesSuccess(data));
    } catch (error) {
        yield put(actions.getVehiclesError(error));
    }
}

function* getDriversService(id) {
    try {
        const data = yield axios({
            url: `getDriverList?fleetOwner=${id.organizationId}`,
            method: 'GET',
        })
            .then(response => {
                return response.data.dataList;
            });
        yield put(actions.getDriversSuccess(data));
    } catch (error) {
        yield put(actions.getDriversError(error));
    }
}

// create
function* createDocService(action) {
    try {
        let res = yield axios.post(`createVehicleDocRemainder`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.createDocSuccess(res));
    } catch (error) {
        yield put(actions.createDocError(error));
    }
}
// update
function* updateDocService(action) {
    try {
        let res = yield axios.post(`updateVehicleDocsRemainder`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.updateDocSuccess(res));
    } catch (error) {
        yield put(actions.updateDocError(error));
    }
}
// view
function* getViewDocService(action) {
    try {
        let res = yield axios.get(`getAllVehicleDocsRemainder?orgId=${action.organizationId}`)
            .then(response => {
                return (response.data)
            });
        yield put(actions.getViewDocSuccess(res));
    } catch (error) {
        yield put(actions.getViewDocError(error));
    }
}
// delete
function* deleteDocService(action) {
    try {
        let res = yield axios.post(`deleteVehicleDocsRemainder?id=${action.delDocs.id}&driverId=${action.delDocs.driverId}`)
            .then(response => {
                return (response.data)
            });
        yield put(actions.deleteDocSuccess(res));
    } catch (error) {
        yield put(actions.deleteDocError(error));
    }
}

export default function* docSaga() {
    yield all([
        takeLatest(actionTypes.GET_DOC_TYPE, getDocTypeService),
        takeLatest(actionTypes.GET_VEHICLELIST_DOC, getVehiclesService),
        takeLatest(actionTypes.GET_DRIVERLIST_DOC, getDriversService),
        takeLatest(actionTypes.CREATE_DOC, createDocService),
        takeLatest(actionTypes.UPDATE_DOC, updateDocService),
        takeLatest(actionTypes.VIEW_DOC, getViewDocService),
        takeLatest(actionTypes.DELETE_DOC, deleteDocService)
    ]);

}


