import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../../utils/axios';

/* Get Shared Trips api call */
function* getVehicleStatus(data){
    try{
    const vehicle = yield axios({
            url: `getVehicleLiveStatusBasedOnVehicle?vehicleId=${data.vehicleId}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });
         yield put(actions.getVehicleSuccess(vehicle));

    } catch(error){
        console.log("location created failed")
    }
}

export default function* sharedTripsDetailsSaga(){
    yield takeLatest(constant.GET_VEHICLE_STATUS, getVehicleStatus)

}