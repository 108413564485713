import { put, takeLatest, call, select } from 'redux-saga/effects'
import * as actions from './action'
import {
    GET_PLAN_INFO,
    DELETE_LOCATION,
    EDIT_LOCATION,
    getPlanType,
    ADD_PLAN,
    ASSIGN_DRIVER,
    UPDATE_PLAN,
    SEND_SIM_CONSENT_MSG,
    GET_DRIVER_LIST,
    PLAN_ABORT,
    STORE_GEO_LOCATION,
    LOCATION_DATA
} from './constant'
import axios from '../../../../utils/axios';
import { StorageClass } from '../../../../utils/StorageClass'


function* planService(payload) {
    //let findDriver
    //let driverStatus = null;
    let vehicleDriver = null;
    let planData = [];
    try {
        let orgData = '';
        let orgOwnerId = yield select(state => state.userState.orgInfo.orgOwnerId);
        let uid = yield select(state => state.userState.uid);
        if (orgOwnerId === '' || orgOwnerId === undefined) {
            const storage = new StorageClass();
            const organizationId = storage.getItem('organizationId');
            orgData = yield axios.post(`getOrgInfo?orgId=${organizationId}&userId=${uid}`).then(res => res.data);
            orgOwnerId = (orgData.message === "SUCCESS") ? orgData.data.orgOwnerId : '';
        }
        // const location = yield axios.post(`viewLocations?userId=${uid}`).then(res => res.data);
        if (payload.seqNo) {
            planData = yield call(getPlanService, payload.seqNo)
            planData.selectedPlanKey = getPlanType(planData.planType)

        }
        if(payload.vehicleId) {
        vehicleDriver = yield call(getDriverStatus, payload.vehicleId)
        }
        planData.selectedDrivers = [];
        if (vehicleDriver !== null) {
            let driver = {
                ...vehicleDriver,
                name: vehicleDriver.type == 'DRIVER' ? vehicleDriver.driverName1 : vehicleDriver.managerName,
                mobileNum: vehicleDriver.mobileNumber1,
                licenceNum: "",
                driverId: vehicleDriver.driverId1,
                driverStatus: vehicleDriver.driverStatus1,
            }
            planData.selectedDrivers.push(driver)
            let handoverType = vehicleDriver.type.toLowerCase()
            handoverType = handoverType.charAt(0).toUpperCase() + handoverType.slice(1)
            planData.handOverType = handoverType
        }
        yield put(actions.planSuccessData(planData));
    } catch (error) {
        console.log(error)
        yield put(actions.planDataError(error));
    }
}
function* deleteLocationService(data) {
    try {
        yield axios({
            url: `deleteLocationData?seqNum=` + data.seqNo,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        yield put(actions.deleteLocationSuccess());
    } catch (error) {
        // yield put(actions.loadDriverError(error));
    }
}
function* getDriverListService(actionData) {
    try {
        let response = yield axios.get(`getFleetDriversV2?fleetOwner=${actionData.orgOwnerId}`).then(res => res);
        let result = response.data;
        if (result.message === "SUCCESS") {
            yield put(actions.setDriverList(result.data))
        }
    } catch (error) {
        console.log(error);
    }
}
function* getPlanService(seqNo) {
    try {
        const planData = yield axios({
            url: `getTripPlannerDataBasedOnSeqNum?seqNum=` + seqNo,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        return planData
    } catch (error) {
        //yield put(actions.planDataError(error));
    }
}

function* getDriverStatus(vehicleId) {
    try {
        const driverStatus = yield axios({
            url: `ViewVehicleDrivers?vehicleId=` + vehicleId,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        return driverStatus.data
    } catch (error) {
        //yield put(actions.planDataError(error));
    }
}

function* editLocationService(data) {

    try {
        const location = yield axios({
            url: `editLocationHistory`,
            method: 'POST',
            data: data.locationInfo
        })
            .then(response => {
                return response.data
            });
        yield put(actions.locationSuccess(location));
    } catch (error) {
        //yield put(actions.planDataError(error));
    }
}

function* addPlanService(data) {

    try {
         yield axios({
            url: `addTripPlanner`,
            method: 'POST',
            data: data.plan
        })
            .then(response => {
                return response.data
            });
        yield call(assignDriverService, data, 'add')

    } catch (error) {
        yield put(actions.addPlanError(error));
    }
}

function* updatePlanService(data) {

    try {
        yield axios({
            url: `editTripPlanner`,
            method: 'POST',
            data: data.plan
        })
            .then(response => {
                return response.data
            });
        if (data.isDriverChanged == 'yes') {
            yield call(assignDriverService, data, 'update')
        } else {
            yield put(actions.updatePlanSuccess());
        }
    } catch (error) {
        yield put(actions.addPlanError(error));
    }
}

function* assignDriverService(data, scope) {
    try {
        yield axios({
            url: `assignDriver?` + data.assignDriverData,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        if (scope === 'add') {
            yield put(actions.addPlanSuccess());
        } else if (scope === 'update') {
            yield put(actions.updatePlanSuccess());
        }
    } catch (error) {
    }
}
function* sendConsentMsgService(actionData) {
    const { driverId, userId } = actionData.data;
    try {
         yield axios.post(`sendConsentMsg?driverId=${driverId}&userId=${userId}`).then(res => res);
        // let result = response.data;
        // if (result === "SUCCESS") {
        //     yield put(actions.setAlertMsg(successMsg)) 
        // } else {
        //     yield put(actions.setAlertMsg(errorData)) 
        // }
    } catch (error) {
        console.log(error);
    }
}

function* abortPlanService(actionData) {
    try {
        let response = yield axios.post(`closePlan?vehicleId=${actionData.data.vehicleId}&plannedTime=${actionData.data.utcDate}&planNo=${actionData.data.planNo}`).then(res => res);
        let responseData = response.data;
        yield put(actions.abortPlanServiceSuccess(responseData))
    } catch (error) {
        console.log(error);
    }
}

function* geoLocationService(data) {
    try {
        yield axios({
            url: `addLocationPoints`,
            data: data.data,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        // yield put(actions.locationCreateSuccess(create));

    } catch (error) {
        console.log("location created failed")
    }
}

function* locationData(data) {
    try {
        //let uid = yield select(state => state.userState.uid);
        const location = yield axios.post(`viewLocations?userId=${data.uid}`).then(res => res.data);
        yield put(actions.locationDataSuccess(location.data.map));
    } catch (error) {
        // console.log(error)
        yield put(actions.locationDataError(error));
    }
}


export default function* planSaga() {
    yield takeLatest(GET_PLAN_INFO, planService)
    yield takeLatest(DELETE_LOCATION, deleteLocationService)
    yield takeLatest(EDIT_LOCATION, editLocationService)
    yield takeLatest(ADD_PLAN, addPlanService)
    yield takeLatest(UPDATE_PLAN, updatePlanService)
    yield takeLatest(ASSIGN_DRIVER, assignDriverService)
    yield takeLatest(SEND_SIM_CONSENT_MSG, sendConsentMsgService)
    yield takeLatest(GET_DRIVER_LIST, getDriverListService)
    yield takeLatest(PLAN_ABORT, abortPlanService)
    yield takeLatest(STORE_GEO_LOCATION, geoLocationService)
    yield takeLatest (LOCATION_DATA, locationData)

}
