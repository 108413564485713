import * as actionTypes from './constant';
import { updateObject } from '../../../../utils/object';

/* Vehicle Reducer */
const initialState = {
    error: null,
    loading: false,
    additionalLoading : false,
    liveStatusDataLoaded: false,
    liveStatusData: [],
    liveStatusSubscribers: [],
    latestSubscriptionIndex: null,
    liveStatusDataError: null,
    currentFilter: { vehicleName: null, 'liveStatus.driverName': null },
    vehicleTripCount: [],
    availablePings: null,
    simDurationList:[],
    vehicleStatusTripCount: []
};



const subscribeLiveVehicleshStatus = (state, action) => {
    const updatedLiveStatusSubscribers = [...state.liveStatusSubscribers];
    updatedLiveStatusSubscribers.push(action.callback);

    return updateObject(state, {
        liveStatusSubscribers: updatedLiveStatusSubscribers,
        latestSubscriptionIndex: updatedLiveStatusSubscribers.length,
        loading: true
    });
};

const additionalVehiclesStatusInitiate = (state, action) => {
    return updateObject(state, {
        loading: false,
        additionalLoading: true
    });
};

const unsubscribeLiveVehicleshStatus = (state, action) => {
    const updatedLiveStatusSubscribers = [...state.liveStatusSubscribers];
    updatedLiveStatusSubscribers.splice((action.index - 1), 1);
    return updateObject(state, {
        liveStatusSubscribers: updatedLiveStatusSubscribers,
        latestSubscriptionIndex: updatedLiveStatusSubscribers.length
    });
};

const notifyLiveVehicleshStatusSuccess = (state, action) => {
    let updatedLiveStatusData;
    if(action.responseType ===1){
        updatedLiveStatusData = action.dataStatus;
    }else{
        updatedLiveStatusData = state.liveStatusData.concat(action.dataStatus);
    }
    
    return updateObject(state, {
        liveStatusDataLoaded: true,
        liveStatusData: updatedLiveStatusData,
        vehicleTripCount: action.vehCountDetails,
        availablePings : action.availablePings,
        simDurationList:action.simDurationList,
        loading: false,
        additionalLoading: false
    });
}

const notifyLiveVehicleshStatusFail = (state, action) => {
    return updateObject(state, {
        liveStatusDataError: action.error,
    });
}

const vehicleStatusCount = (state, action) => {
    return updateObject(state, {
        vehicleStatusTripCount : []
    });
}

const vehicleStatusCountSuccess = (state, action) => {
    return updateObject(state, {
        vehicleStatusTripCount : action.data.dataList 
    });
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBSCRIBE_LIVE_VEHICLES_STATUS: return subscribeLiveVehicleshStatus(state, action);
        case actionTypes.ADDITIONAL_LIVE_VEHICLES_STATUS: return additionalVehiclesStatusInitiate(state, action);
        case actionTypes.UNSUBSCRIBE_LIVE_VEHICLES_STATUS: return unsubscribeLiveVehicleshStatus(state, action);
        case actionTypes.NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_SUCCESS: return notifyLiveVehicleshStatusSuccess(state, action);
        case actionTypes.NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_FAIL: return notifyLiveVehicleshStatusFail(state, action);
        case actionTypes.VEHICLE_STATUS_COUNT: return vehicleStatusCount(state, action);
        case actionTypes.VEHICLE_STATUS_COUNT_SUCCESS: return vehicleStatusCountSuccess(state, action);
        default: return state;
    }
}

