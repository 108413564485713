import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../../utils/axios';

function* getDriver(id) {
    try {
        const data = yield axios({
            url: `getFleetDrivers?fleetOwner=${id.orgOwnerId}`,
            method: 'GET',
        }).then(response => {
                return response.data;

            });
        yield put(actions.driverSuccess(data));
    } catch (error) {
        yield put(actions.driverError(error));
    }
}

function* viewDriverConfig(record) {
    try {
        const create = yield axios({
            url: `getDriverConfigList?orgId=${record.orgId}&userId=${record.orgOwnerId}`,
            data: record.data,
            method: 'POST',
        }).then(response => {
                return response.data
            });
        yield put(actions.viewDriverConfigSuccess(create));
    } catch (error) {
        yield put(actions.viewDriverConfigError(error));
    }
}

function* driverConfig(record) {
    try {
        const create = yield axios({
            url: `createDriverIncentiveConfig`,
            data: record.data,
            method: 'POST',
        }).then(response => {
                return response.data
            });
        yield put(actions.driverConfigSuccess(create));
    } catch (error) {
        yield put(actions.driverConfigError(error));
    }
}

function* updateDriverConfig(record) {
    try {
        const update = yield axios({
            url: `updateDriverIncentiveConfig`,
            data: record.data,
            method: 'POST',
        }).then(response => {
                return response.data
            });
        yield put(actions.updateDriverSuccess(update));
    } catch (error) {
        yield put(actions.updateDriverError(error));
    }
}

function* driverRedeem(record) {
    try {
        const redeem = yield axios({
            url: `getRedeemsList?orgId=${record.data.orgId}&driverId=${record.data.driverId}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'GET',
        }).then(response => {
                return response.data;
            });
        yield put(actions.driverRedeemSuccess(redeem));
    } catch (error) {
        yield put(actions.driverRedeemError(error));
    }
}

function* driverPlan(record) {
    try {
        const redeem = yield axios({
            url: `getPlanwiseRedeemDetails?orgId=${record.data.orgId}&driverId=${record.data.driverId}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'GET',
        })
            .then(response => {
                return response.data;
            });
        yield put(actions.driverPlanSuccess(redeem));
    } catch (error) {
        yield put(actions.driverPlanError(error));
    }
}

function* redeemPoint(record) {
    try {
        const redeem = yield axios({
            url: `recordAsPaid?orgId=${record.data.orgId}&driverId=${record.data.driverId}&outstandingPoints=${record.data.verifiedPoints}`,
            method: 'POST',
        })
            .then(response => {
                return response.data;
            });
        yield put(actions.redeemPointSuccess(redeem));
    } catch (error) {
        yield put(actions.redeemPointError(error));
    }
}


export default function* driverConfigSaga() {
    yield takeLatest(constant.VIEW_DRIVER_CONFIG, viewDriverConfig);
    yield takeLatest(constant.DRIVER_CONFIG, driverConfig);
    yield takeLatest(constant.LOAD_DRIVER, getDriver);
    yield takeLatest(constant.UPDATE_DRIVER_CONFIG, updateDriverConfig);
    yield takeLatest(constant.DRIVER_REDEEM, driverRedeem);
    yield takeLatest(constant.DRIVER_PLAN, driverPlan);
    yield takeLatest(constant.REDEEM_POINT, redeemPoint);
}