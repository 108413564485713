import * as actionTypes from './constant'

const initialState = {
    loading: true,
    vehicle: {},
    error:'', 
}
export default function sharedTripsDetail(prevState = initialState, action){
    switch(action.type) {

        case actionTypes.GET_VEHICLE_STATUS:
            return {
                ...prevState,
            }
        case actionTypes.STATUS_SUCCESS:
            return {
                ...prevState,
                vehicle : action.vehicle,
                loading: false
            }
        case actionTypes.STATUS_FAIL:
            return {
                ...prevState,
                loading: true
            }           
        default : {
            return {
                ...prevState
            }
        }
    }
}
