export const CREATE_SCR = "CREATE_SCR";
export const CREATE_SCR_SUCCESS = "CREATE_SCR_SUCCESS";
export const CREATE_SCR_FAILURE = "CREATE_SCR_FAILURE";
export const UPDATE_SCR = "UPDATE_SCR";
export const UPDATE_SCR_SUCCESS = "UPDATE_SCR_SUCCESS";
export const UPDATE_SCR_FAILURE = "UPDATE_SCR_FAILURE";
export const VIEW_SCR = "VIEW_SCR";
export const VIEW_SCR_SUCCESS = "VIEW_SCR_SUCCESS";
export const VIEW_SCR_FAILURE = "VIEW_SCR_FAILURE";
export const DELETE_SCR = "DELETE_SCR";
export const DELETE_SCR_SUCCESS = "DELETE_SCR_SUCCESS";
export const DELETE_SCR_FAILURE = "DELETE_SCR_FAILURE";
export const EMPTY_RESPONSE_REMINDER = "EMPTY_RESPONSE_REMINDER";

