export const GET_TYRE_DETAILS = "GET_TYRE_DETAILS";
export const GET_TYRE_DETAILS_SUCCESS = "GET_TYRE_DETAILS_SUCCESS";
export const GET_TYRE_DETAILS_FAILURE = "GET_TYRE_DETAILS_FAILURE";

export const GET_TYPE_SERVICE = "GET_TYPE_SERVICE";
export const GET_SERVICE_TYPE_SUCCESS = "GET_SERVICE_TYPE_SUCCESS";
export const GET_SERVICE_TYPE_FAILURE = "GET_SERVICE_TYPE_FAILURE";

export const GET_VEHICLELIST_TYRE_LOG = "GET_VEHICLELIST_TYRE_LOG";
export const GET_VEHICLELIST_TYRE_LOG_SUCCESS = "GET_VEHICLELIST_TYRE_LOG_SUCCESS";
export const GET_VEHICLELIST_TYRE_LOG_FAILURE = "GET_VEHICLELIST_TYRE_LOG_FAILURE";

export const CREATE_TYRE_SERVICE = "CREATE_TYRE_SERVICE";
export const CREATE_TYRE_SERVICE_SUCCESS = "CREATE_TYRE_SERVICE_SUCCESS";
export const CREATE_TYRE_SERVICE_FAILURE = "CREATE_TYRE_SERVICE_FAILURE";

export const UPDATE_TYRE_SERVICE = "UPDATE_TYRE_SERVICE";
export const UPDATE_TYRE_SERVICE_SUCCESS = "UPDATE_TYRE_SERVICE_SUCCESS";
export const UPDATE_TYRE_SERVICE_FAILURE = "UPDATE_TYRE_SERVICE_FAILURE";

export const VIEW_TYRE_SERVICE = "VIEW_TYRE_SERVICE";
export const VIEW_TYRE_SERVICE_SUCCESS = "VIEW_TYRE_SERVICE_SUCCESS";
export const VIEW_TYRE_SERVICE_FAILURE = "VIEW_TYRE_SERVICE_FAILURE";

export const DELETE_TYRE_SERVICE = "DELETE_TYRE_SERVICE";
export const DELETE_TYRE_SERVICE_SUCCESS = "DELETE_TYRE_SERVICE_SUCCESS";
export const DELETE_TYRE_SERVICE_FAILURE = "DELETE_TYRE_SERVICE_FAILURE";

export const EMPTY_RESPONSE_TYRE_LOG = "EMPTY_RESPONSE_TYRE_LOG";

