import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import { LOAD_MANAGEVEHICLE_LODING, MOVE_VEHICLE } from './constant';
import axios from '../../../utils/axios';

function* manageVehicleService(actionData) {
    try {
        const allTruckData = yield axios({
            url: `getVehicleListBasedOnOrgnaization?orgId=${actionData.organizationId}`,
            method: 'POST',
        }).then(response => {
            return response.data
        });
        yield put(actions.manageVehicleSuccess(allTruckData));
    } catch (error) {
        yield put(actions.manageVehicleError(error));
    }
}
function* moveVehicleService(actionData) {
    try {
        let response = yield axios.post(`vehicleMoveProcess`, actionData.data)
            .then(res => {
                return res.data
            });
        yield put(actions.moveVehicleSuccess(response));
    } catch (error) {
        yield put(actions.moveVehicleError(error));
    }
}
export default function* manageVehicleSaga() {
    yield all([
        takeLatest(LOAD_MANAGEVEHICLE_LODING, manageVehicleService),
        takeLatest(MOVE_VEHICLE, moveVehicleService)
    ]);
}



