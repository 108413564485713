import * as actionTypes from "./constant";

export function createOrganizationService(orgDetails) {
  return {
    type: actionTypes.CREATE_ORGANIZATION,
    orgDetails,
  };
}
export function createOrganizationSuccess(orgData) {
  return {
    type: actionTypes.CREATE_ORGANIZATION_SUCCESS,
    orgData,
  };
}

export function createOrganizationError(error) {
  return {
    type: actionTypes.CREATE_ORGANIZATION_FAILURE,
    error,
  };
}

export function editOrganizationService(editOrgDetails) {
  return {
    type: actionTypes.EDIT_ORGANIZATION,
    editOrgDetails,
  };
}
export function editOrganizationSuccess(editOrgData) {
  return {
    type: actionTypes.EDIT_ORGANIZATION_SUCCESS,
    editOrgData,
  };
}

export function editOrganizationError(error) {
  return {
    type: actionTypes.EDIT_ORGANIZATION_FAILURE,
    error,
  };
}

export function myOrgDropdownService(orgDetails) {
  return {
    type: actionTypes.MY_ORGANIZATION_DROPDOWN,
    orgDetails,
  };
}
export function myOrgDropdownSuccess(orgData) {
  return {
    type: actionTypes.MY_ORGANIZATION_DROPDOWN_SUCCESS,
    orgData,
  };
}

export function myOrgDropdownError(error) {
  return {
    type: actionTypes.MY_ORGANIZATION_DROPDOWN_FAILURE,
    error,
  };
}
export function delMyorgService(delOrgDetails) {
  return {
    type: actionTypes.DEL_MY_ORGANIZATION,
    delOrgDetails,
  };
}
export function delMyorgSuccess(orgData) {
  return {
    type: actionTypes.DEL_MY_ORGANIZATION_SUCCESS,
    orgData,
  };
}

export function delMyorgError(error) {
  return {
    type: actionTypes.DEL_MY_ORGANIZATION_FAILURE,
    error,
  };
}
export function hideAssignedOrg(showAssignedOrg, orgObj) {
  return {
    type: actionTypes.MY_ORG,
    orgObj,
    showAssignedOrg,
    organizationId: orgObj.organizationId,
    organizationName: orgObj.organizationName,
  };
}

export function inviteUserForOrg(data) {
  return {
    type: actionTypes.INVITE_ORG,
    data,
  };
}
export function showHideModal(value, data, orgModal) {
  return {
    type: actionTypes.ORG_MODAL,
    value,
    data,
    orgModal,
  };
}
export function setResponseData(response) {
  return {
    type: actionTypes.SET_RESPONSE_DATA_ORG,
    response,
  };
}
export function emptyResponse() {
  return {
    type: actionTypes.EMPTY_RESPONSE,
  };
}
export function getSpecificOrgInfoInvited(organizationId, accUserId) {
  return {
    type: actionTypes.GET_ORG_INFO,
    organizationId,
    accUserId,
  };
}
export function setSpecificOrgInfo(invitedHistory) {
  return {
    type: actionTypes.SET_ORG_INFO_INVITED,
    invitedHistory,
  };
}
export function deleteInvitedUser(id) {
  return {
    type: actionTypes.DELETE_INVITED_USER,
    id,
  };
}
export function updateUserRole(data) {
  return {
    type: actionTypes.UPDATE_ROLE,
    data,
  };
}
export function setError(errorMsg) {
  return {
    type: actionTypes.SET_ERROR_MSG,
    errorMsg,
  };
}
export function setInitalOrgInfo(initialInfo) {
  return {
    type: actionTypes.SET_INITIAL_ORG,
    initialInfo,
  };
}

export function orgProfileService(uid, history) {
  return {
    type: actionTypes.LOAD_ORG_PROFILE,
    uid: uid,
    history,
  };
}

export function orgProfileSuccess(getProfile) {
  return {
    type: actionTypes.LOAD_ORG_PROFILE_SUCCESS,
    getProfile,
  };
}

export function orgPaymentService(uid, utcFrom, utcEnd) {
  return {
    type: actionTypes.LOAD_ORG_PAYMENT,
    uid,
    utcFrom,
    utcEnd,
  };
}

export function orgPaymentSuccess(getPayment) {
  return {
    type: actionTypes.LOAD_ORG_PAYMENT_SUCCESS,
    getPayment,
  };
}
export function getSpecificOrgInfoForCountry(uid, organizationId) {
  return {
    type: actionTypes.COUNTRY_GET_SPECIFIC_ORG_INFO,
    uid,
    organizationId,
  };
}
export function setCountry(orgCountryCode) {
  return {
    type: actionTypes.SET_COUNTRY,
    orgCountryCode,
  };
}
export function setDateFormat(orgDateFormat) {
  return {
    type: actionTypes.SET_DATEFORMAT,
    orgDateFormat,
  };
}
export function setAutoPlanCreation(autoPlanCreation) {
  return {
    type: actionTypes.SET_AUTO_PLAN_CREATION,
    autoPlanCreation,
  };
}
export function syncWithGpsvts(orgId, Email, userId) {
  return {
    type: actionTypes.SYNC_WITH_GPSVTS,
    orgId,
    Email,
    userId,
  };
}
export function GpsvtsSyncSuccess(message) {
  return {
    type: actionTypes.SYNC_WITH_GPSVTS_SUCCESS,
    message,
  };
}
export function loginGpsvts(orgId, userId, emailId, login) {
  return {
    type: actionTypes.LOGIN_GPSVTS,
    orgId,
    userId,
    emailId,
    login,
  };
}
export function loginSuccess(loginMsg, actionData) {
  if (actionData.login == "login") {
    return {
      type: actionTypes.LOGIN_SUCCESS,
      loginMsg,
    };
  } else if (actionData.domain == "domain") {
    return {
      type: actionTypes.DOMAIN_SUCCESS,
      loginMsg,
    };
  }
}
export function emptyLoginMsg() {
  return {
    type: actionTypes.EMPTY_LOGIN_MESSAGE,
  };
}

export function proDomain(orgId, userId, emailId, domain) {
  return {
    type: actionTypes.DOMAIN,
    orgId,
    userId,
    emailId,
    domain,
  };
}
