import * as actionTypes from './constant'

export function getVehicleStatus(vehicleId){
    return {
        type : actionTypes.GET_VEHICLE_STATUS,
        vehicleId
    }
}

export function getVehicleSuccess(vehicle){
    return {
        type : actionTypes.STATUS_SUCCESS,
        vehicle
    }
}

export function getVehicleFail(error){
    return {
        type : actionTypes.STATUS_FAIL,
        error
    }
}
