
import * as actionTypes from "./constant";

export function claimHistoryService() {
    return {
        type: actionTypes.CLAIM_HISTORY_SERVICE
    }
}
export function claimHistorySuccess(res) {
    return {
        type: actionTypes.CLAIM_HISTORY_SUCCESS,
        res
    }
}
export function resetClaim() {
    return {
        type: actionTypes.RESET_CLAIM
    }
}
export function claimApproveService(data) {
    return {
        type: actionTypes.CLAIM_APPROVE_SERVICE,
        data
    }
}
export function claimApproveSuccess(res) {
    return {
        type: actionTypes.CLAIM_APPROVE_SUCCESS,
        res
    }
}
export function claimApproveError(error) {
    return {
        type: actionTypes.CLAIM_APPROVE_FAILURE,
        error
    }
}
export function claimEmptyService() {
    return {
        type: actionTypes.CLAIM_APPROVE_EMPTY
    }
}