export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const DO_LOGIN = 'DO_LOGIN'

export const SET_UID = 'SET_UID'
export const SET_REG_TYPE = 'SET_REG_TYPE'
export const SET_ORG_INFO = 'SET_ORG_INFO'
export const GET_DEFAULT_ORG_INFO = 'GET_DEFAULT_ORG_INFO'
export const GET_SPECIFIC_ORG_INFO = 'GET_SPECIFIC_ORG_INFO'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const EMPTY_NEW_REG_INFO = 'EMPTY_NEW_REG_INFO'
export const SWITCH_LOADER = 'SWITCH_LOADER'
export const REGISTER_PROCESS = 'REGISTER_PROCESS'
