import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as constant from './constant';
import axios from '../../../../utils/axios';
function* getTypeList({ orgId }) {
    try {
        let param = new URLSearchParams({ orgId })
        let res = yield axios.get(`getTyreList?${param}`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getTyreListSuccess(res));
    } catch (error) {
        yield put(actions.getTyreListFailure());
    }
}

function* getTyreKmReport({ tyreSerialNum, fromTimeUtc, toTimeUtc }) {
    try {
        let param = new URLSearchParams({ tyreSerialNum, fromTimeUtc, toTimeUtc })
        // let domainUrl = "https://fleetoswebapi.vamosys.com/v2/webdrs/";
        let res = yield axios.get(`getTyreKmReport?${param}`)
            .then(response => {
                return (response.data.data.kmReport)
            });
        yield put(actions.getTyreKmReportSuccess(res));
    } catch (error) {
        yield put(actions.getTyreKmReportFailure());
    }

}


export default function* tyreServiceSaga() {
    yield all([
        takeLatest(constant.GET_TYRE_LIST, getTypeList),
        takeLatest(constant.GET_TYRE_KM_REPORT, getTyreKmReport),
    ]);
}


