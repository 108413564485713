import { takeEvery, all, put } from "redux-saga/effects";
import * as actions from './action'
import { GET_ATTENTION,ATTENTION_RESPONSE,GET_ATTENTION_HISTORY } from './constant'
import axios from '../../../../../utils/axios';

 
function* viewAttentionBasedOnVehicleIdSaga(action){
    try{
        var url ='viewAttentionBasedOnVehicleId?vehicleId='+action.vehicleId;
        var response = yield axios.post(url).then(res => res);
        var attentions = response.data;
        let count = 0;
        if( attentions === null || attentions === [] || attentions === '') {
          count = 0
        }else{
          count = attentions.length
        }
        yield put(actions.setAttention(attentions,count)) 
    } catch(error){
      console.log(error);
   }
}
function* getAttentionHistoryService(action){
    try{
      var url ='getMessages?vehicleId='+action.vehicleId;
      var response = yield axios.post(url).then(res => res);
      var data = response.data;
      if( data.attentionManagement !== null || data.attentionManagement !== [] ) {
        yield put(actions.attentionHistorySuccess(data.attentionManagement))
      }
    } catch(error){
      console.log(error);
   }
}
function* responseToAttentionSaga(action){
    try{
        var url ='responseToAttention';
        var response = yield axios.post(url,action.resData).then(res => res);
        var result = response.data;
        yield put(actions.attentionResponseMsg(result)) 
    } catch(error){
      console.log(error);
   }
}
export default function* registerSaga(){
    yield all([
      takeEvery(GET_ATTENTION, viewAttentionBasedOnVehicleIdSaga),
      takeEvery(ATTENTION_RESPONSE, responseToAttentionSaga),
      takeEvery(GET_ATTENTION_HISTORY, getAttentionHistoryService),
    ]);
  }