
import * as actionTypes from "./constant";

export function getTyreConsumptionService(data) {
    return {
        type: actionTypes.GET_TYRE_CONSUMPTION,
        data
    }
}
export function getTyreConsumptionSuccess(tyreConsumptionList) {
    return {
        type: actionTypes.GET_TYRE_CONSUMPTION_SUCCESS,
        tyreConsumptionList        
    }
}

export function getTyreConsumptionError(error) {
    return {
        type: actionTypes.GET_TYRE_CONSUMPTION_FAILURE,
        error
    }
}

export function getTyreSummaryService(data) {
    return {
        type: actionTypes.GET_TYRE_SUMMARY,
        data
    }
}
export function getTyreSummarySuccess(tyreSummaryList) {
    return {
        type: actionTypes.GET_TYRE_SUMMARY_SUCCESS,
        tyreSummaryList        
    }
}

export function getTyreSummaryError(error) {
    return {
        type: actionTypes.GET_TYRE_SUMMARY_FAILURE,
        error
    }
}