import { createFilter } from 'react-search-input';
import * as actionTypes from './constant'

const initialState = {
    invites : [],
    loading: true,
    additionalLoading : false,
    vehicles: [],
    filteredVehicles: [],
    error:'',
    alert: false,
    shareStatus: {},
    currentFilter: { vehicleName: null, driverName: null },
    removeEmailid:null,
    removeEmailResponse:null,
    totalCount : 0
}
export default function sharedTrips(prevState = initialState, action){
    switch(action.type) {

        case actionTypes.GET_SHARED_TRIPS:
            return {
                ...prevState,
                loading : true
            }
        case actionTypes.SHARED_TRIPS_SUCCESS:
            return {
                ...prevState,
                invites : action.trips,
                loading: false
            }
        case actionTypes.SHARE_TRIPS:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.TRIP_SHARED_SUCCESS:
            return {
                ...prevState,
                loading: false,
                alert: true,
                shareStatus: action.data
            }
        case actionTypes.LOAD_VEHICLES:
            return {
                ...prevState,
                loading: true,
            }
        case actionTypes.ADDITIONAL_LOAD_VEHICLES:
            return {
                ...prevState,
                loading: false,
                additionalLoading : true
            }
        case actionTypes.LOAD_VEHICLES_SUCCESS:
            let updatedLiveData;
            if(action.responseType ===1){
                updatedLiveData = action.vehicles.dataStatus;
            }else{
                updatedLiveData = prevState.vehicles.concat(action.vehicles.dataStatus);
            }
            return {
                ...prevState,
                loading: false,
                additionalLoading : false,
                vehicles: updatedLiveData,
                totalCount : action.vehicles.totalCount
            }
        case actionTypes.RESET_ALERT:
            return {
                ...prevState,
                alert:false
            }
        case actionTypes.APPLY_VEHICLE_FILTER:
            let filteredVehicles = [...prevState.vehicles]
            Object.keys(initialState.currentFilter).forEach(filterParam => {
                filteredVehicles = filteredVehicles.filter(createFilter(action.filter[filterParam], filterParam));
            });
            return {
                ...prevState,
                filteredVehicles
            }
        case actionTypes.CLEAR_VEHICLE_FILTER:
            return {
                ...prevState,
                currentFilter: { vehicleName: null, driverName: null },
                filteredVehicles: [...prevState.vehicles]
            }            
        case actionTypes.REMOVE_EMAIL_FROM_SHARE_TRIP:
            return {
                ...prevState,
                removeEmailid: action.id
            }
        case actionTypes.REMOVE_EMAIL_FROM_SHARE_TRIP_SUCCESS:
            return {
                ...prevState,
                removeEmailResponse: action.data
            }
        case actionTypes.REMOVE_EMAIL_FROM_SHARE_TRIP_ERROR:
            return {
                ...prevState,
                removeEmailResponse: action.data
            }
        case actionTypes.RESET_REMOVE_EMAIL:
            return {
                ...prevState,
                removeEmailResponse: null
            }
        default : {
            return {
                ...prevState
            }
        }
    }
}
