import { LOAD_MANAGEVEHICLE_SUCCESS, LOAD_MANAGEVEHICLE_FAILURE, LOAD_MANAGEVEHICLE_LODING, ALL_VEHICLE, MOVE_VEHICLE, MOVE_VEHICLE_SUCCESS, MOVE_VEHICLE_FAILURE, MOVE_VEHICLE_EMPTY } from './constant'

export function manageVehicleService(organizationId) {
    return {
        type: LOAD_MANAGEVEHICLE_LODING,
        organizationId
    }
}

export function manageVehicleSuccess(truckData) {
    return {
        type: LOAD_MANAGEVEHICLE_SUCCESS,
        truckData
    }
}

export function manageVehicleError(error) {
    return {
        type: LOAD_MANAGEVEHICLE_FAILURE,
        error
    }
}
export function moveVehicleService(data) {
    return {
        type: MOVE_VEHICLE,
        data
    }
}

export function moveVehicleSuccess(res) {
    return {
        type: MOVE_VEHICLE_SUCCESS,
        res
    }
}

export function moveVehicleError(error) {
    return {
        type: MOVE_VEHICLE_FAILURE,
        error
    }
}
export function moveVehicleEmpty() {
    return {
        type: MOVE_VEHICLE_EMPTY        
    }
}
export function showAllVehicle(allvehicle) {
    return {
        type: ALL_VEHICLE,
        allvehicle
    }
}

