import * as actionTypes from "./constant";
export default function register(prevState = {
    photoURL : '',
    paymentHistory : [],
    serviceType  : '',
    chart : '',
    editProfileModal : false,
    priceList : []
}, action){
    switch(action.type) {
        case actionTypes.UPDATE_PROFILE_IMAGE:
            return {
                ...prevState,
                photoURL : action.photoURL
            }
        case actionTypes.SET_PAYMENT_HISTORY:
            return {
                ...prevState,
                paymentHistory : action.paymentHistory,
                serviceType : 'SET_PAYMENT_HISTORY'
            }
        case actionTypes.RESET_SERVICE:
            return {
                ...prevState,
                serviceType : ''
            }
        case actionTypes.SET_CHART:
            return {
                ...prevState,
                chart : action.chartDetail,
            }
        case actionTypes.SHOW_EDIT_PROFILE_MODAL:
            return {
                ...prevState,
                editProfileModal : true,
            }
        case actionTypes.CLOSE_EDIT_PROFILE_MODAL:
            return {
                ...prevState,
                editProfileModal : false,
            }

         /*Get Sim Price List*/
         case actionTypes.GET_SIM_PRICE:
            return {
                ...prevState,
                //loading : true
            }
        case actionTypes.GET_SIM_PRICE_SUCCESS:
            return {
                ...prevState,
                priceList : action.list.dataList,
                loading: false
        }
        case actionTypes.GET_SIM_PRICE_FAILURE:
            return {
                ...prevState,
                error : action.error,
                loading : false
            }

        default : {
            return {
                ...prevState
            }
        }
    }
}
