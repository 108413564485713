import * as actionTypes from './constant'

export default function reportsService(prevState = {
    data : '',
    loading: true,
    reportPage:"",
    error:'',
    ongoingExcel : '#',
    completedExcel : '#'
}, action){
    switch(action.type) {

        /* Plan Reports */
        case actionTypes.GET_PLAN_REPORTS:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case actionTypes.GET_PLAN_REPORTS_SUCCESS:
            return {
                ...prevState,
                data : action.getPlan.data,
                loading: false
        }

        case actionTypes.GET_PLAN_REPORTS_FAILURE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }

        /* Redirect Report page */
        case actionTypes.REPORT_PAGE:
            return {
                ...prevState,
                error : action.error,
            }
        case actionTypes.REPORT_PAGE_SUCCESS:
            return {
                ...prevState,
                data : '',
        }

        case actionTypes.REPORT_PAGE_FAILURE:
            return {
                ...prevState,
                error : action.error,
            }

         /* Get ongoing Excel */
         case actionTypes.GET_ONGOING_EXCEL:
            return {
                ...prevState,
                error : action.error,
            }
        case actionTypes.GET_ONGOING_EXCEL_SUCCESS:
            return {
                ...prevState,
                ongoingExcel : action.getExcel.data,
        }

        case actionTypes.GET_ONGOING_EXCEL_FAILURE:
            return {
                ...prevState,
                error : action.error,
            }

        /* Get Completed Excel */
        case actionTypes.GET_COMPLETED_EXCEL:
            return {
                ...prevState,
                error : action.error,
            }
        case actionTypes.GET_COMPLETED_EXCEL_SUCCESS:
            return {
                ...prevState,
                completedExcel : action.getExcel.data,
        }

        case actionTypes.GET_COMPLETED_EXCEL_FAILURE:
            return {
                ...prevState,
                error : action.error,
            }
        
        default : {
            return {
                ...prevState
            }
        }
    }
}
