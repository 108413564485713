import * as action from './constant';

export function getTyreList(orgId) {
    return {
        type: action.GET_TYRE_LIST,
        orgId
    }
}

export function getTyreListSuccess(tyreList) {
    return {
        type: action.GET_TYRE_LIST_SUCCESS,
        tyreList
    }
}

export function getTyreListFailure() {
    return {
        type: action.GET_TYRE_LIST_FAILURE,
    }
}

export function getTyreKmReport(tyreSerialNum, fromTimeUtc, toTimeUtc) {
    return {
        type: action.GET_TYRE_KM_REPORT,
        tyreSerialNum,
        fromTimeUtc,
        toTimeUtc
    }
}

export function getTyreKmReportSuccess(tyreKmReport) {
    return {
        type: action.GET_TYRE_KM_REPORT_SUCCESS,
        tyreKmReport
    }
}

export function getTyreKmReportFailure() {
    return {
        type: action.GET_TYRE_KM_REPORT_SUCCESS,
    }
}