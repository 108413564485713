export const GET_PLAN_INFO = 'GET_PLAN_INFO'
export const MAX_LOCATIONS_ALLOWED = 25
export const DELETE_LOCATION = 'DELETE_LOCATION'
export const PLAN_SUCCESS = 'PLAN_SUCCESS'
export const PLAN_FAILURE = 'PLAN_FAILURE'
export const DRIVER_SUCCESS = 'DRIVER_SUCCESS'
export const EDIT_LOCATION = 'EDIT_LOCATION'
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS'
export const RESET_LOCATION_STATUS = 'RESET_LOCATION_STATUS'
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const RESET_DELETE_LOCATION_STATUS = 'RESET_DELETE_LOCATION_STATUS'
export const ADD_PLAN = 'ADD_PLAN'
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS'
export const RESET_PLAN_DATA = 'RESET_PLAN_DATA'
export const ADD_PLAN_ERROR = 'ADD_PLAN_ERROR'
export const UPDATE_PLAN = 'UPDATE_PLAN'
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
export const RESET_PLAN_STATUS = 'RESET_PLAN_STATUS'
export const ASSIGN_DRIVER = 'ASSIGN_DRIVER'
export const SEND_SIM_CONSENT_MSG = 'SEND_SIM_CONSENT_MSG'
export const SET_ALERT_MSG = 'SET_ALERT_MSG'
export const RESET_ALERT_MSG = 'RESET_ALERT_MSG'
export const SET_DRIVER_LIST = 'SET_DRIVER_LIST'
export const GET_DRIVER_LIST = 'GET_DRIVER_LIST'
export const PLAN_ABORT = 'PLAN_ABORT'
export const PLAN_ABORT_SUCCESS = 'PLAN_ABORT_SUCCESS'
export const EMPTY_ABORT_STATUS = 'EMPTY_ABORT_STATUS'
export const STORE_GEO_LOCATION = 'STORE_GEO_LOCATION'
export const SHOW_ADD_LOCATION = 'SHOW_ADD_LOCATION'
export const HIDE_ADD_LOCATION = 'HIDE_ADD_LOCATION'
export const LOCATION_DATA = 'LOCATION_DATA'
export const LOCATION_DATA_SUCCESS = 'LOCATION_DATA_SUCCESS'
export const LOCATION_DATA_FAILURE = 'LOCATION_DATA_FAILURE'

export const getPlanType = (type) => {
    if(type === 'LOADING'){
        return 1
    } else if (type === 'UNLOADING'){
        return 2
    } else if (type === 'SERVICE'){
        return 3
    } else if (type === 'SHED'){
        return 4
    } else {
        return 1
    }
  }


