import { LOGIN_SUCCESS, LOGIN_FAILURE,DO_LOGIN,SET_UID,SET_REG_TYPE,SET_ORG_INFO,EMPTY_NEW_REG_INFO ,SWITCH_LOADER} from './constant'
export default function login(prevState = {
    userInfo : {},
    error: '',
    errorStatus: false,
    uid : '',
    newRegInfo : '',
    switchLoader : false,
    orgInfo : {
        "organizationId": '',
        "role": '',
        "organizationName": '',
        "createdUserid": '',
        "orgOwnerId" : '',
    }
}, action){
    switch(action.type) {
        case LOGIN_SUCCESS:
            return {
                ...prevState,
                userInfo : action.userInfo,
                errorStatus: false,
            }
        case LOGIN_FAILURE:
            return {
                ...prevState,
                error : action.error,
                errorStatus : true,
            }
        case SET_UID:
            return {
                ...prevState,
                uid : action.uid,
            }
        case DO_LOGIN:
            return {
                ...prevState,
                uid : action.data.uid,
            }
        case SET_REG_TYPE:
            return {
                ...prevState,
                newRegInfo : action.newRegInfo,
            }
        case SET_ORG_INFO:
            return {
                ...prevState,
                orgInfo : action.orgInfo
            }
        case EMPTY_NEW_REG_INFO:
            return {
                ...prevState,
                newRegInfo : '',
            }
        case SWITCH_LOADER:
            return {
                ...prevState,
                switchLoader : action.value,
            }
        default : {
            return {
                ...prevState
            }
        }
    }
}
