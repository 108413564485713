import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../../../utils/axios';

function* deleteExpenseService(action) {
    try {
        let res = yield axios.post(`removeExpense?seqNum=${action.seqNum}&userId=${action.userId}`,)
            .then(response => {
                return (response.data)
            });

        yield put(actions.deleteExpenseSuccess(res));
    } catch (error) {
        yield put(actions.deleteExpenseError(error));
    }
}
function* logService(action) {
    try {
        let res = yield axios.get(`getExpenseAudit?expenseSeqNum=${action.seqNum}`)
            .then(response => {
                return (response.data)
            });

        yield put(actions.logSuccess(res));
    } catch (error) {
        yield put(actions.logError(error));
    }
}

export default function* delExpenseSaga() {
    yield all([
        takeLatest(actionTypes.DELETE_EXPENSE, deleteExpenseService),
        takeLatest(actionTypes.LOG_EXPENSE, logService)
    ]);

}

