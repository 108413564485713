// utils/API.js
import axios from "axios";
// import {WEBURLV2} from './index';
import { StorageClass } from './StorageClass'
const storage = new StorageClass();
let domainUrl = "";
if (window.location.hostname === "fleetos.vamosys.com") {
  domainUrl = "https://fleetoswebapi.vamosys.com";
} else {
  domainUrl = "http://209.97.163.4:9910"; 
  // domainUrl = "http://68.183.224.220:9910";
}
const AxiosInstance = axios.create({
  baseURL: domainUrl + "/v2/webdrs/",
});

AxiosInstance.defaults.headers.common['Accept'] = 'application/json';
AxiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
// AxiosInstance.defaults.headers.common['Authorization'] = 'Bearer '+storage.getItem('token');
AxiosInstance.interceptors.request.use(req => {
  req.headers.Authorization = 'Bearer ' + storage.getItem('token');
  return req;
}, error => {
  return Promise.reject(error);
}
);
AxiosInstance.interceptors.response.use(response => { return response }, error => {
  const status = error.response ? error.response.status : null

  if (status === 401) {
    window.location.href = "/login"
  }

  return Promise.reject(error);
});

export default AxiosInstance;
