export const GET_MAINTENANCE_TYPE = "GET_MAINTENANCE_TYPE";
export const GET_MAINTENANCE_TYPE_SUCCESS = "GET_MAINTENANCE_TYPE_SUCCESS";
export const GET_MAINTENANCE_TYPE_FAILURE = "GET_MAINTENANCE_TYPE_FAILURE";
export const GET_VEHICLELIST_MAINTENANCE = "GET_VEHICLELIST_MAINTENANCE";
export const GET_VEHICLELIST_MAINTENANCE_SUCCESS = "GET_VEHICLELIST_MAINTENANCE_SUCCESS";
export const GET_VEHICLELIST_MAINTENANCE_FAILURE = "GET_VEHICLELIST_MAINTENANCE_FAILURE";
export const CREATE_MAINTENANCE = "CREATE_MAINTENANCE";
export const CREATE_MAINTENANCE_SUCCESS = "CREATE_MAINTENANCE_SUCCESS";
export const CREATE_MAINTENANCE_FAILURE = "CREATE_MAINTENANCE_FAILURE";
export const UPDATE_MAINTENANCE = "UPDATE_MAINTENANCE";
export const UPDATE_MAINTENANCE_SUCCESS = "UPDATE_MAINTENANCE_SUCCESS";
export const UPDATE_MAINTENANCE_FAILURE = "UPDATE_MAINTENANCE_FAILURE";
export const VIEW_MAINTENANCE = "VIEW_MAINTENANCE";
export const VIEW_MAINTENANCE_SUCCESS = "VIEW_MAINTENANCE_SUCCESS";
export const VIEW_MAINTENANCE_FAILURE = "VIEW_MAINTENANCE_FAILURE";
export const DELETE_MAINTENANCE = "DELETE_MAINTENANCE";
export const DELETE_MAINTENANCE_SUCCESS = "DELETE_MAINTENANCE_SUCCESS";
export const DELETE_MAINTENANCE_FAILURE = "DELETE_MAINTENANCE_FAILURE";
export const EMPTY_RESPONSE_REMINDER = "EMPTY_RESPONSE_REMINDER";

