import * as actionTypes from './constant'

/* Create Location */
export function locationCreate(data){
    return {
        type : actionTypes.LOCATION_CREATE,
        data
    }
}

export function locationCreateSuccess(createData){
    return {
        type : actionTypes.LOCATION_CREATE_SUCCESS,
        createData
    }
}

/* Edit Location && View Location  */
export function getLocation(data){
    return {
        type : actionTypes.LOCATION_GET,
        data
    }
}

export function locationGetSuccess(getData){
    return {
        type : actionTypes.LOCATION_GET_SUCCESS,
        getData
    }
}

/* Update Location */
export function locationUpdate(data){
    return {
        type : actionTypes.LOCATION_UPDATE,
        data
    }
}

export function locationUpdateSuccess(updateData){
    return {
        type : actionTypes.LOCATION_UPDATE_SUCCESS,
        updateData
    }
}

/* Delete Location */
export function locationDelete(data){
    return {
        type : actionTypes.LOCATION_DELETE,
        data
    }
}

export function locationDeleteSuccess(deleteData){
    return {
        type : actionTypes.LOCATION_DELETE_SUCCESS,
        deleteData
    }
}


/* Change status Location */
export function locationStatus(data){
    return {
        type : actionTypes.LOCATION_STATUS,
        data
    }
}

export function locationStatusSuccess(statusData){
    return {
        type : actionTypes.LOCATION_STATUS_SUCCESS,
        statusData
    }
}


/* Location List */
export function locationList( uid, history){
    return {
        type : actionTypes.LOCATION_LIST,
        uid,
        history
    }
}

export function locationListSuccess(locationData){
    return {
        type : actionTypes.LOCATION_LIST_SUCCESS,
        locationData
    }
}



export function locationError(error){
    return {
        type : actionTypes.LOCATION_FAILURE,
        error
    }
}
