import * as actionTypes from "./constant";

export function addExpenseService(payLoad){
    return {
        type : actionTypes.ADD_EXPENSE,
        payLoad    
    }
}
export function addExpenseSuccess(addSuccessData){
    return {
        type : actionTypes.ADD_EXPENSE_SUCCESS,
        addSuccessData
    }
}

export function addExpenseError(error){
    return {
        type : actionTypes.ADD_EXPENSE_FAILURE,
        error
    }
}

export function editExpenseService(payLoad){
    return {
        type : actionTypes.EDIT_EXPENSE,
        payLoad    
    }
}
export function editExpenseSuccess(editSuccessData){
    return {
        type : actionTypes.EDIT_EXPENSE_SUCCESS,
        editSuccessData
    }
}

export function editExpenseError(error){
    return {
        type : actionTypes.EDIT_EXPENSE_FAILURE,
        error
    }
}
export function emptyExpenseResponse(){
    return {
        type : actionTypes.EMPTY_EXPENSE_RESPONSE,
    }
}