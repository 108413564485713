import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from "./constant";
import axios from '../../../../../utils/axios';

function* instructionService(actionData) {
    try {
        let res = yield axios.post(`createInstruction`, actionData.payload)
            .then(response => {
                return (response.data)
            });
        var obj = {
            "response": res,
            "type": actionData.payload.type
        }
        yield put(actions.instructionResponse(obj));
    } catch (error) {
        yield put(actions.instructionError(error));
    }
}
function* instructionHistoryService(actionData) {
    try {
        let res = yield axios.post(`getInstructionForSpecificPlan?vehicleId=${actionData.payload}`).then(response => {
            return (response.data)
        });
        yield put(actions.instructionHistorySuccess(res));
    } catch (error) {
        yield put(actions.instructionHistoryError(error));
    }
}
export default function* instructionSaga() {
    yield all([
        yield takeLatest(actionTypes.INSTRUCTION_SUCCESS, instructionService),
        yield takeLatest(actionTypes.INSTRUCTION_HISTORY_SERVICE, instructionHistoryService)
    ]);

}