import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../../utils/axios';

function* getExpenseTypeService(action) {
    try {
        let res = yield axios.get(`getExpenseType`)
            .then(response => {
                return (response.data.dataList)
            });

        yield put(actions.getExpenseTypeSuccess(res));
    } catch (error) {
        yield put(actions.getExpenseTypeError(error));
    }
}
function* getDriversExpenseService(action) {
    var planNo;
    if(action.planNo){
        planNo = action.planNo; 
    }
    else{
        planNo = '';
    }
    try {
        let res = yield axios.get(`getDriversForExpense?orgOwnerId=${action.orgOwnerId}&vehicleId=${action.vehicleId}&planNo=${planNo}`)
            .then(response => {
                return (response.data.data)
            });

        yield put(actions.getDriversExpenseSuccess(res));
    } catch (error) {
        yield put(actions.getDriversExpenseError(error));
    }
}
function* getExpenseHistoryService(action) {
    try {
        let res = yield axios.get(`viewExpenses?vehicleId=${action.id}&planNo=${action.planNo}`)
            .then(response => {
                return (response.data.data)
            });

        yield put(actions.getExpenseHistorySuccess(res));
    } catch (error) {
        yield put(actions.getExpenseHistoryError(error));
    }
}

export default function* expenseSaga() {
    yield all([
        takeLatest(actionTypes.GET_EXPENSE_TYPE, getExpenseTypeService),
        takeLatest(actionTypes.GET_EXPENSE_HISTORY, getExpenseHistoryService),
        takeLatest(actionTypes.GET_EXPENSE_DRIVER, getDriversExpenseService),
    ]);

}

