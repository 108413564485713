import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../utils/axios';

function* fuelList(action){
    try{
        const data = yield axios({
            url: `getFuelPriceListForAll?date=${action.date}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response;
        });
        yield put(actions.fuelListSuccess(data));
    } catch(error){
        console.log('error',error);
        yield put(actions.fuelListError(error));
    }
}

function* favorite(action){
    try{
        yield axios({
            url: `addOrRemoveFuelFavoriteListByOrg?orgId=${action.orgId}&district=${action.district}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response;
        });
        yield favoriteList(action);
    } catch(error){
        yield put(actions.favoriteError(error));
    }
}

function* favoriteList(action){
    try{
        const data = yield axios({
            url: `getFuelFavoriteListByOrganisation?orgId=${action.orgId}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response;
        });
        yield put(actions.favoriteListSuccess(data));
    } catch(error){
        yield put(actions.favoriteListError(error));
    }
}

function* fuelHistory(action){
    try{
        const data = yield axios({
            url: `getFuelPriceListBasedOnDistrict?date=${action.date}&district=${action.district}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response;
        });
        yield put(actions.fuelHistorySuccess(data));
    } catch(error){
        yield put(actions.fuelHistoryError(error));
    }
}

export default function* OrganizationConfigSaga(){
    yield takeLatest(constant.FUEL_LIST, fuelList);
    yield takeLatest(constant.FAVORITE_HISTORY, favoriteList);
    yield takeLatest(constant.FAVORITE, favorite);
    yield takeLatest(constant.FUEL_HISTORY, fuelHistory);
}