import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../../utils/axios';

function* tripCompletedChart(record){
    try{
        const getTripCompletedTrip = yield axios({
            url: `getPlanCompletionChart?orgId=${record.data.orgId}&type=${record.data.type}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'POST',
        }).then( response=>{ 
            return response.data
        });
        yield put(actions.tripCompletedChartSuccess(getTripCompletedTrip));
    } catch(error){
        yield put(actions.tripCompletedChartError(error));
    }
}

function* driverCompletedChart(record){
    try{
        const driverTripCompletedTrip = yield axios({
            url: `getPlanCompletionChartBasedOnDriver?orgId=${record.data.orgId}&driverId=${record.data.driverId}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'POST',
        }).then( response=>{ 
            return response.data
        });
        yield put(actions.driverBasedCompletedChartSuccess(driverTripCompletedTrip));
    } catch(error){
        yield put(actions.driverBasedCompletedChartError(error));
    }
}

function* getDriver(id){
    try{
        const data = yield axios({
            url: `getDriverList?fleetOwner=${id.orgOwnerId}`,
            method: 'GET',
        })
        .then( response=>{ 
            return response.data; 
        });
        yield put(actions.getDriverSuccess(data));
    } catch(error){
        yield put(actions.driverError(error));
    }
}

function* dashboardChart(record){
    try{
        const dashboardCharts = yield axios({
            url: `getVehicleWiseChartReport?orgId=${record.data.orgId}&year=${record.data.year}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'POST',
        }).then( response=>{ 
            return response.data
        });
        yield put(actions.dashboardChartSuccess(dashboardCharts));
    } catch(error){
        yield put(actions.dashboardChartError(error));
    }
}


export default function* reportChartSaga(){
    yield takeLatest(constant.TRIPS_COMPLETED_CHART, tripCompletedChart);
    yield takeLatest(constant.GET_CHART_DRIVER_LIST, getDriver);
    yield takeLatest(constant.DRIVER_COMPLETED_CHART, driverCompletedChart);
    yield takeLatest(constant.DASHBOARD_CHART, dashboardChart)
}