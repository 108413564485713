import * as actionTypes from "./constant";

export default function tyreReportsReducer(prevState = {
    tyreConsumptionList: [],
    response: null,
    tyreSummaryList: [] 
}, action) {
    switch (action.type) {
        case actionTypes.GET_TYRE_CONSUMPTION:
            return {
                ...prevState,
            }
        case actionTypes.GET_TYRE_CONSUMPTION_SUCCESS:
            return {
                ...prevState,
                tyreConsumptionList: action.tyreConsumptionList,
            }
        case actionTypes.GET_TYRE_CONSUMPTION_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
            case actionTypes.GET_TYRE_SUMMARY:
            return {
                ...prevState,
            }
        case actionTypes.GET_TYRE_SUMMARY_SUCCESS:
            return {
                ...prevState,
                tyreSummaryList: action.tyreSummaryList,
            }
        case actionTypes.GET_TYRE_SUMMARY_FAILURE:
            return {
                ...prevState,
                error: action.error,         
            }
            default: {
            return {
                ...prevState
            }
        }
    }
}
