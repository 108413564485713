import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';

//get service type
function* getTypeService() {
    try {
        let res = yield axios.get(`getTyreServiceList`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getTypeSuccess(res));
    } catch (error) {
        yield put(actions.getTypeError(error));
    }
}

// get tyre details
function* getTyreDetailService(data) {
    try {
        let res = yield axios.get(`getTyreInfo?orgId=${data.organizationId}&userId=${data.uid}`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getTyreDetailSuccess(res));
    } catch (error) {
        yield put(actions.getTyreDetailError(error));
    }
}

// create
function* createTyreService(action) {
    try {
        let res = yield axios.post(`saveTyreServiceLog`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.createTyreSuccess(res));
    } catch (error) { 
        yield put(actions.createTyreError(error));
    }
}


// create
function* updateTyreService(action) {
    try {
        let res = yield axios.post(`updateTyreServiceLog`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.updateTyreServiceSuccess(res));
    } catch (error) { 
        yield put(actions.updateTyreServiceError(error));
    }
}
// view
function* getViewTyreService(action) {
    try {
        let res = yield axios.get(`getAllTyreServiceLog?orgId=${action.organizationId}&userId=${action.uid}`)
            .then(response => {
                return (response.data.data)
            });
        yield put(actions.getViewTyreSuccess(res));
    } catch (error) {
        yield put(actions.getViewTyreError(error));
    }
}

// delete
function* deleteTyreService(action) {
    action.data["userId"] = action.userId;
    try {
        let res = yield axios.post(`removeServiceLog`, action.data)
            .then(response => {
                return (response.data)
            });
        yield put(actions.deleteTyreSuccess(res));
    } catch (error) {
        yield put(actions.deleteTyreError(error));
    }
}


export default function* tyreServiceSaga() {
    yield all([
        takeLatest(actionTypes.GET_TYPE_SERVICE, getTypeService),
        takeLatest(actionTypes.GET_TYRE_DETAILS, getTyreDetailService),
        takeLatest(actionTypes.VIEW_TYRE_SERVICE, getViewTyreService),
        takeLatest(actionTypes.CREATE_TYRE_SERVICE, createTyreService),
        takeLatest(actionTypes.UPDATE_TYRE_SERVICE, updateTyreService),
        takeLatest(actionTypes.DELETE_TYRE_SERVICE, deleteTyreService),
    ]);
}


