import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';

function* getTyreConsumptionService(data) {
    try {
        // let res = yield axios.get(`getTyreConsumptionReportList?orgId=${data.orgId}&fleetOwnerId=${data.fleetOwnerId}&fromDateUtc=${data.fromDateUtc}&toDateUtc=${data.toDateUtc}&tyreBrand=${data.tyreBrand}&tyreSerialNum=${data.tyreSerialNum}`)
        //     .then(response => {
        //         return (response.data.dataList)
        //     });
        // yield put(actions.getDocTypeSuccess(res));

        let response = [
            {
                "fromDateUtc": 166732632737,
                "toDateUtc": 16687878787878,
                "tyreBrand": "MRF",
                "tyreSerialNum": "YUY89899",
                "vehicleId": "TN09NJ9898",
                "tyreStatus": "",
                "positionInVehicle": "",
                "distanceTravelled": "",
                "startingDepth": "",
                "endingDepth": ""
            },
            {
                "fromDateUtc": 166732632737,
                "toDateUtc": 16687878787878,
                "tyreBrand": "MRF",
                "tyreSerialNum": "YUY89899",
                "vehicleId": "TN09NJ9898",
                "tyreStatus": "NEW",
                "positionInVehicle": "Front",
                "startDate": 166732632737,
                "endDate": 166732632737,
                "distanceTravelled": "26KM",
                "startingDepth": "20cm",
                "endingDepth": "15cm"
            },
            {
                "fromDateUtc": 166732632737,
                "toDateUtc": 16687878787878,
                "tyreBrand": "MRF",
                "tyreSerialNum": "YUY89899",
                "vehicleId": "TN09NJ9898",
                "tyreStatus": "",
                "positionInVehicle": "",
                "startDate": 166732632737,
                "endDate": 166732632737,
                "distanceTravelled": "",
                "startingDepth": "",
                "endingDepth": ""
            },
            {
                "fromDateUtc": 166732632737,
                "toDateUtc": 16687878787878,
                "tyreBrand": "MRF",
                "tyreSerialNum": "YUY89899",
                "vehicleId": "TN09NJ9898",
                "tyreStatus": "",
                "positionInVehicle": "",
                "startDate": "",
                "endDate": "",
                "distanceTravelled": "",
                "startingDepth": "",
                "endingDepth": ""
            }
        ];
        yield put(actions.getTyreConsumptionSuccess(response));

    } catch (error) {
        yield put(actions.getTyreConsumptionError(error));
    }
}
function* getTyreSummaryService(data) {
    try {
        // let res = yield axios.get(`getTyreConsumptionReportList?orgId=${data.orgId}&fleetOwnerId=${data.fleetOwnerId}&fromDateUtc=${data.fromDateUtc}&toDateUtc=${data.toDateUtc}&tyreBrand=${data.tyreBrand}&tyreSerialNum=${data.tyreSerialNum}`)
        //     .then(response => {
        //         return (response.data.dataList)
        //     });
        // yield put(actions.getDocTypeSuccess(res));

        let response = [
            {
                "fromDateUtc": 166732632737,
                "toDateUtc": 16687878787878,
                "tyreBrand": "MRF",
                "tyreSerialNum": "YUY89899",
                "vehicleId": "TN09NJ9898",
                "fitmentDate": 16756565767,
                "tyreOdoDate": 1676879808098,
                "tyreOdo": 333,
                "noOfSwaps": 8,
                "status": "-"
            },
            {
                "fromDateUtc": 166732632737,
                "toDateUtc": 16687878787878,
                "tyreBrand": "MRF",
                "tyreSerialNum": "YUY89899",
                "vehicleId": "TN09NJ9898",
                "fitmentDate": 16756565767,
                "tyreOdoDate": 1676879808098,
                "tyreOdo": 333,
                "noOfSwaps": 8,
                "status": "-"
            },
            {
                "fromDateUtc": 166732632737,
                "toDateUtc": 16687878787878,
                "tyreBrand": "MRF",
                "tyreSerialNum": "YUY89899",
                "vehicleId": "TN09NJ9898",
                "fitmentDate": 16756565767,
                "tyreOdoDate": 1676879808098,
                "tyreOdo": 333,
                "noOfSwaps": 8,
                "status": "-"
            },
            {
                "fromDateUtc": 166732632737,
                "toDateUtc": 16687878787878,
                "tyreBrand": "MRF",
                "tyreSerialNum": "YUY89899",
                "vehicleId": "TN09NJ9898",
                "fitmentDate": 16756565767,
                "tyreOdoDate": 1676879808098,
                "tyreOdo": 333,
                "noOfSwaps": 8,
                "status": "-"
            }
        ];
        yield put(actions.getTyreSummarySuccess(response));

    } catch (error) {
        yield put(actions.getTyreSummaryError(error));
    }
}


export default function* tyreReportSaga() {
    yield all([ 
        takeLatest(actionTypes.GET_TYRE_CONSUMPTION, getTyreConsumptionService),
        takeLatest(actionTypes.GET_TYRE_SUMMARY, getTyreSummaryService)
    ]);

}


