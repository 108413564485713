export const DRIVER_PERFORMANCE = 'DRIVER_PERFORMANCE'
export const DRIVER_PERFORMANCE_SUCCESS = 'DRIVER_PERFORMANCE_SUCCESS'
export const DRIVER_PERFORMANCE_FAILURE = 'DRIVER_PERFORMANCE_FAILURE'

export const ADD_AMOUNT = 'ADD_AMOUNT'
export const ADD_AMOUNT_SUCCESS = 'ADD_AMOUNT_SUCCESS'
export const ADD_AMOUNT_FAILURE = 'ADD_AMOUNT_FAILURE'

export const PAYMENT_AUDIT = 'PAYMENT_AUDIT'
export const PAYMENT_AUDIT_SUCCESS = 'PAYMENT_AUDIT_SUCCESS'
export const PAYMENT_AUDIT_FAILURE = 'PAYMENT_AUDIT_FAILURE'

export const CREDIT_AMOUNT = 'CREDIT_AMOUNT'