import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as planActions from '../../../containers/fleetOwners/dashboard/plan/action'
import { LOAD_PROFILE, NOTIFICATION, STORE_NOTIFICATION, GET_FLEET_DRIVER, UPDATE_DEFAULT_ORG, KYC_PROCESS, UPLOAD_EXCEL, UPLOAD_EXCEL_SUCCESS_OR_FAILURE } from './constant'
import axios from '../../../utils/axios';
//import axios from '../../../utils/axiosWithoutToken';

function* profile(actionData) {
    try {
        const getProfile = yield axios({
            url: `getSpecificFleetowner?userId=` + actionData.uid,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        yield put(actions.profileSuccess(getProfile));
        //yield put (actionData.history.push('/login'))
    } catch (error) {
        yield put(actions.profileError(error));
    }
}

function* getFleetDriverList(actionData) {
    try {
        const getDriver = yield axios({
            url: `getFleetDriversV2?fleetOwner=` + actionData.uid,
            method: 'GET',
        })
            .then(response => {
                return response.data
            });
        yield put(planActions.driverSuccessData(getDriver.data));
    } catch (error) {
        console.log(error)
    }
}

function* notificationService(actionData) {
    let payload = {
        'fcmTocken': actionData.token,
        'fleetOwnerId': actionData.uid
    }
    try {
        let response = yield axios.post(`addFleetOwnerWebDevice`, payload)
            .then(res => {
                return res.data
            });
        yield put(actions.notificationSuccess(response));
    } catch (error) {
        yield put(actions.notificationError(error));
    }
}
function* storeNotifications(actionData) {
    try {
        let response = yield axios.get(`getNotificationList?fleetOwner=${actionData.data.fleetOwner}&fromTimeUtc=${actionData.data.fromTimeUtc}&toTimeUtc=${actionData.data.toTimeUtc}`)
            .then(res => {
                return res.data.dataList
            });
        yield put(actions.storeNotificationsSuccess(response));
    } catch (error) {
        yield put(actions.storeNotificationsError(error));
    }
}
function* kycProcessService(actionData) {
    let payLoad = {
        'fleetownerId': actionData.data.fleetownerId,
        'gstNo': actionData.data.gstNo
    }
    try {
        let response = yield axios.post(`/fleetOwnerKycProcess`, payLoad)
            .then(res => {
                return res.data
            });
        yield put(actions.kycProcessSuccess(response));
    } catch (error) {
        yield put(actions.kycProcessError(error));
    }
}
function* updateDefaultOrg(actionData) {
    try {
        yield axios({
            url: `updateDefaultOrg?orgId=${actionData.orgId}&fleetOwner=${actionData.fleetOwner}`,
            method: 'GET',
        }).then(response => {
            return response.data
        });
    } catch (error) {
        console.log("update org", error)
    }
}

function* uploadExcel(actionData) {
    try {
        let res = yield axios.post(`uploadBulTripExcel?orgId=${actionData.orgId}`, actionData.imgData)
            .then(response => {
                return (response.data)
            });
        yield put(actions.uploadExcelSuccessOrFailure(res));
    } catch (error) {
        yield put(actions.uploadExcelSuccessOrFailure(error));
    }
}

export default function* profileSaga() {
    yield all([takeLatest(LOAD_PROFILE, profile),
    takeLatest(NOTIFICATION, notificationService),
    takeLatest(STORE_NOTIFICATION, storeNotifications),
    takeLatest(KYC_PROCESS, kycProcessService),
    takeLatest(UPDATE_DEFAULT_ORG, updateDefaultOrg),
    takeLatest(GET_FLEET_DRIVER, getFleetDriverList),
    takeLatest(UPLOAD_EXCEL, uploadExcel),
    ])
}