import * as constType from './constant'

export function createHandOver(  data){
    return {
        type : constType.CREATE_HANDOVER,
        data
    }
}

export function createHandOverSuccess(handover){
    return {
        type : constType.CREATE_HANDOVER_SUCCESS,
        handover
    }
}

export function updateHandOver(data){
    return {
        type : constType.UPDATE_HANDOVER,
        data
    }
}

export function updateHandOverSuccess(handover){
    return {
        type : constType.UPDATE_HANDOVER_SUCCESS,
        handover
    }
}

export function deleteHandOver(seqNum){
    return {
        type : constType.DELETE_HANDOVER,
        seqNum
    }
}

export function deleteHandOverSuccess(handover){
    return {
        type : constType.DELETE_HANDOVER_SUCCESS,
        handover
    }
}

export function forceHandOver(vehicleId){
    return {
        type : constType.FORCE_HANDOVER,
        vehicleId
    }
}

export function forceHandOverSuccess(handover){
    return {
        type : constType.FORCE_HANDOVER_SUCCESS,
        handover
    }
}

export function clearStore(){
    return {
        type : constType.CLEAR_STORE,
    }
}

export function handOverError(error){
    return {
        type : constType.HANDOVER_FAILURE,
        error
    }
}

