import * as constType from './constant'

export default function truckHistory(prevState = {
    data: [],
    vehicleList: [],
    driverId: '',
    driverName: '',
    vehicleId: '',
    driverList: [],
    loading: false,
    vehicleLoading: false,
    historyLoading: false,
    history: "",
    error: '',
    displayMode: 2,
    podList: [],
    getReports:[],
    fuelData:{
        totalFuelConsume:0,
        totalDistance:0,
        mileage:0,
    },
}, action) {
    switch (action.type) {
        case constType.LOAD_TRUCKHISTORY_LODING:

            return {
                ...prevState,
                error: action.error,
                driverId: action.payload.driverId,
                driverName: action.payload.driverName,
                vehicleId: action.payload.vehicleId,
                loading: true,
            }
        case constType.LOAD_TRUCKHISTORY_SUCCESS:
            return {
                ...prevState,
                data: action.truckData.data,
                loading: false,
            }
        case constType.SAVE_POD_LIST:
            return {
                ...prevState,
                podList: action.payload,
                loading: false,
                vehicleLoading: false
            }
        case constType.LOAD_TRUCKHISTORY_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,         
            }

        /* Vehicle Action */
        case constType.LOAD_VEHICLE:
            return {
                ...prevState,
                error: action.error,
                vehicleLoading: true
            }
        case constType.LOAD_VEHICLE_SUCCESS:
            return {
                ...prevState,
                vehicleList: action.vehicleData.dataList,
                loading: false,
                vehicleLoading: false           
             }
        case constType.LOAD_VEHICLE_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                vehicleLoading: false           
             }

        /* Driver Action */
        case constType.GET_LOAD_DRIVER:
            return {
                ...prevState,
                error: action.error,
                vehicleLoading: true
            }
        case constType.GET_LOAD_DRIVER_SUCCESS:
            return {
                ...prevState,
                driverList: action.driverData,
                vehicleLoading: false
            }
        case constType.GET_LOAD_DRIVER_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                vehicleLoading: false            
            }

        /* History Detail page */
        case constType.HISTORY_DETAIL:
            return {
                ...prevState,
                error : action.error,
                historyLoading : true
            }
        case constType.HISTORY_DETAIL_SUCCESS:
            return {
                ...prevState,
                history : action.getDetail,
                historyLoading: false
        }

        case constType.HISTORY_DETAIL_FAILURE:
            return {
                ...prevState,
                error : action.error,
                historyLoading : false
            }
            case constType.GET_TRIP_REPORTS:
                return {
                    ...prevState,
                    historyLoading : true
                }
            case constType.GET_TRIP_REPORTS_SUCCESS:
                return {
                    ...prevState,
                    getReports : action.getReports,
                    historyLoading: false
            }
    
            case constType.GET_TRIP_REPORTS_FAILURE:
                return {
                    ...prevState,
                    error : action.error,
                    historyLoading : false
                }
    
        case constType.DISPLAY_MODE:
            return {
                ...prevState,
                displayMode: action.mode
            }

        case constType.GET_FUEL_DATA:
            return {
                ...prevState,
                fuelData: {
                    totalFuelConsume:0,
                    totalDistance:0,
                    mileage:0,
                },
            }
        case constType.GET_FUEL_DATA_SUCCESS:
            return {
                ...prevState,
                fuelData: action.fuelData,
            }
        case constType.GET_FUEL_DATA_FAILURE:
            return {
                ...prevState,
                fuelData: {
                    totalFuelConsume:0,
                    totalDistance:0,
                    mileage:0,
                    loading: false,
                },
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
