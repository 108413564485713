import * as actionTypes from "./constant";

export default function document(prevState = {
    getAttentionList: []
}, action) {
    switch (action.type) {
        case actionTypes.GET_ATTENTION_LIST:
            return {
                ...prevState,
            }
        case actionTypes.GET_ATTENTION_LIST_SUCCESS:
            return {
                ...prevState,
                getAttentionList: action.getAttentionList
            }
        case actionTypes.GET_ATTENTION_LIST_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
