import { takeEvery, all, put } from "redux-saga/effects";
import * as actions from './action'
import {GET_STATE, GET_CITY ,GET_STATE_CITY } from './constant'
import axios from '../../utils/axiosWithoutToken';

function* getCityService(action){

    try{
      var res = yield axios.post('getCities?stateId='+action.stateId).then(response => response);
      if(res.data.length) {
          var cities= res.data;
          cities = cities.map(item => {
            return {value: item, label: item };
          });
          yield put(actions.setCity(cities)) 
      }
    } catch(error){
      yield put(actions.registerError(error));
   }
}
function* getStateService(actionData){
    try{
        var stateData = yield axios.post('getStates?countryId='+actionData.countryCode+'&web=true').then(res => res);
        if(stateData.data.length) {
          var states= stateData.data;
          states = states.map(st => {
            return {value: st.id, label: st.name };
          });
          yield put(actions.setStateList(states)) 
      }
        
    } catch(error){
       yield put(actions.registerError(error));
    }
}
function* getStateCityService(actionData){
  try{
      var stateData = yield axios.post('getStates?countryId='+actionData.countryCode+'&web=true').then(res => res);
      if(stateData.data.length) {
        var states= stateData.data;
        states = states.map(st => {
          return {value: st.id, label: st.name };
        });
        yield put(actions.setStateList(states))
        const stateDetail = states.find(element => element.label === actionData.stateName);
        yield put(actions.getCity(stateDetail.value)) 
    }
  } catch(error){
     yield put(actions.registerError(error));
  }
}
export default function* registerSaga(){
  yield all([
    takeEvery(GET_STATE, getStateService),
    takeEvery(GET_CITY, getCityService),
    takeEvery(GET_STATE_CITY, getStateCityService)
  ]);
}
