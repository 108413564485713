let domainUrl = "";
if(window.location.hostname==="fleetos.vamosys.com"){
   domainUrl = "https://fleetoswebapi.vamosys.com";
}else{
  domainUrl = "http://209.97.163.4:9910";
  // domainUrl = "http://68.183.224.220:9910";
}
export const BASEURL = domainUrl
export const AUTHENTICATE_URL = BASEURL + "/authenticate"
export const ALLTRUCKURL = BASEURL + "/basedrs/getVehicleListBasedOnFleetowner?fleetOwner=l7NGIB57MWQrpfQxH33ahmRrCTX2"
export const MANAGEVEHICLEURL = BASEURL + "/v2/basedrs/getVehicleListBasedOnFleetowner?fleetOwner=Thirumaniraj.vamosys36863@gmail.com"
export const WEBURL = BASEURL+'/v2/basedrs';
export const HANDOVERURL = BASEURL + "/v2/basedrs/createMovementPlan" 
export const VEHICLEOWNERURL = BASEURL + "/v2/basedrs/changeVehicleOwner"
export const WEBURLV2 = BASEURL + "v2/webdrs/"
export const TERMSCOND_URL = "https://vamosys.com/terms-of-use/"