import * as actionTypes from "./constant";

export default function myOrg(prevState = {
    invitedHistory: [],
    loading: false,
    error: '',
    response: "",
    modal: false,
    orgObj: "",
    orgList: [],
    invitedOrgList: [],
    createdOrgRes: '',
    showAssignedOrg: false,
    organizationId: '',
    organizationName: '',
    editedOrgRes: '',
    editedData: '',
    delOrgRes: '',
    orgModal: "",
    orgProfile: '',
    orgPayment: [],
    orgCountryCode: '',
    syncMessage: '',
    gpsvtsLoginData: {}
}, action) {
    switch (action.type) {
        case actionTypes.CREATE_ORGANIZATION:
            return {
                ...prevState,
                createdOrgRes: '',
                error: action.error,
                loading: true,
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''
            }
        case actionTypes.CREATE_ORGANIZATION_SUCCESS:
            return {
                ...prevState,
                createdOrgRes: action.orgData,
                loading: false,
                modal: false,
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''
            }
        case actionTypes.CREATE_ORGANIZATION_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                createdOrgRes: '',
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''
            }
        case actionTypes.EDIT_ORGANIZATION:
            return {
                ...prevState,
                editedOrgRes: '',
                error: action.error,
                loading: false,
                createdOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''
            }
        case actionTypes.EDIT_ORGANIZATION_SUCCESS:
            return {
                ...prevState,
                editedOrgRes: action.editOrgData,
                orgObj: action.editOrgData.data,
                loading: false,
                modal: false,
                createdOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''
            }
        case actionTypes.EDIT_ORGANIZATION_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                createdOrgRes: '',
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''
            }
        case actionTypes.MY_ORGANIZATION_DROPDOWN:
            return {
                ...prevState,
                createdOrgRes: '',
                leaveOrgResponse: '',
                error: action.error,
                loading: true,
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                modal: false
            }
        case actionTypes.MY_ORGANIZATION_DROPDOWN_SUCCESS:
            return {
                ...prevState,
                orgList: action.orgData.myOrg,
                createdOrgRes: '',
                leaveOrgResponse: '',
                invitedOrgList: action.orgData.invitedOrg,
                loading: false,
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                modal: false
            }
        case actionTypes.MY_ORGANIZATION_DROPDOWN_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                createdOrgRes: '',
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''

            }
        case actionTypes.DEL_MY_ORGANIZATION:
            return {
                ...prevState,
                createdOrgRes: '',
                leaveOrgResponse: '',
                error: action.error,
                loading: true,
                editedOrgRes: '',
                delOrgRes: "",
                response: ''
            }
        case actionTypes.DEL_MY_ORGANIZATION_SUCCESS:
            return {
                ...prevState,
                createdOrgRes: '',
                leaveOrgResponse: '',
                loading: false,
                editedOrgRes: '',
                delOrgRes: action.orgData,
                orgObj: action.orgData.data,
                response: '',
            }
        case actionTypes.DEL_MY_ORGANIZATION_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false,
                createdOrgRes: '',
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''
            }
        case actionTypes.GET_ORG_INFO:
            return {
                ...prevState,
                loading: true,
                response: "",
                editedOrgRes: '',
                delOrgRes: '',
                leaveOrgResponse: ''
            }
        case actionTypes.SET_RESPONSE_DATA_ORG:
            return {
                ...prevState,
                response: action.response,
                editedOrgRes: '',
                delOrgRes: '',
                loading: false,
                leaveOrgResponse: ''
            }
        case actionTypes.EMPTY_RESPONSE:
            return {
                ...prevState,
                response: "",
                createdOrgRes: '',
                delOrgRes: '',
                editedOrgRes: '',
                leaveOrgResponse: ''
            }
        case actionTypes.SET_ORG_INFO_INVITED:
            return {
                ...prevState,
                invitedHistory: action.invitedHistory,
                loading: false,
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                leaveOrgResponse: ''
            }
        case actionTypes.SET_ERROR_MSG:
            return {
                ...prevState,
                leaveOrgResponse: '',
                editedOrgRes: '',
                delOrgRes: ''
            }
        case actionTypes.ORG_MODAL:
            return {
                ...prevState,
                modal: action.value,
                createdOrgRes: "",
                editedOrgRes: '',
                delOrgRes: '',
                response: '',
                orgModal: action.orgModal,
                leaveOrgResponse: ''
            }
        case actionTypes.MY_ORG:
            return {
                ...prevState,
                showAssignedOrg: action.showAssignedOrg,
                organizationId: action.organizationId,
                organizationName: action.organizationName,
                createdOrgRes: '',
                editedOrgRes: '',
                response: '',
                orgObj: action.orgObj,
                delOrgRes: '',
                leaveOrgResponse: ''
            }
        case actionTypes.SET_INITIAL_ORG:
            return {
                ...prevState,
                createdOrgRes: '',
                editedOrgRes: '',
                response: '',
                orgObj: action.initialInfo,
                delOrgRes: '',
                modal: false,
                leaveOrgResponse: ''
            }
        case actionTypes.LOAD_ORG_PROFILE:
            return {
                ...prevState,
                uid: action.uid,
            }
        case actionTypes.LOAD_ORG_PROFILE_SUCCESS:
            return {
                ...prevState,
                orgProfile: action.getProfile,
            }
        case actionTypes.LOAD_ORG_PAYMENT:
            return {
                ...prevState,
                uid: action.uid,
            }
        case actionTypes.LOAD_ORG_PAYMENT_SUCCESS:
            return {
                ...prevState,
                orgPayment: action.getPayment.dataList,
            }
        case actionTypes.SET_COUNTRY:
            return {
                ...prevState,
                orgCountryCode: action.orgCountryCode
            }
        case actionTypes.SYNC_WITH_GPSVTS:
            return {
                ...prevState,
                syncMessage: ''
            }
        case actionTypes.SYNC_WITH_GPSVTS_SUCCESS:
            return {
                ...prevState,
                syncMessage: action.message.data
            }
        case actionTypes.LOGIN_GPSVTS:
            return {
                ...prevState,
                gpsvtsLoginData: {}
            }
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...prevState,
                gpsvtsLoginData: action.loginMsg.data
            }
        case actionTypes.EMPTY_LOGIN_MESSAGE:
            return {
                ...prevState,
                gpsvtsLoginData: {}
            }
        case actionTypes.DOMAIN:
            return {
                ...prevState,
                proDomain: {}
            }
        case actionTypes.DOMAIN_SUCCESS:
                return {
                    ...prevState,
                    proDomain: action.loginMsg.data
                }
        default: {
            return {
                ...prevState
            }
        }
    }
}
