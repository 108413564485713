import { takeEvery, all, put } from "redux-saga/effects";
import * as actions from './action'
import { GET_VEHICLE_DETAILS,CHECK_VEHICLE_EXIST,ADD_EDIT_TRUCK,REMOVE_VEHICLE} from './constant'
import axios from '../../../utils/axios';

function* getVehicleDetailsService(action){
    try{
        var url ='getVehicleDetails?vehicleId='+action.vehicleId;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            var vehicleInfo = response.data;
            yield put(actions.setVehicleInfo(vehicleInfo)) 
        }
    } catch(error){
      console.log(error);
   }
}
function* checkVehicleExistenceService(action){
    try{
        var url ='checkVehicleExist?vehicleRegNum='+action.vehicleId;
        var response = yield axios.post(url).then(res => res);
        var result = response.data;
        yield put(actions.vehicleExistenceResponse(result)) 
    } catch(error){
      console.log(error);
   }
}
function* addEditTruckService(action){
    try{
        var response = yield axios.post(action.url,action.data).then(res => res);
        var result = response.data;
        yield put(actions.addEditTruckResponse(result,'')) 
    } catch(error){
      console.log(error);
      yield put(actions.addEditTruckResponse(result,error)) 
   }
}
function* removeVehicleService(action){
    try{
        var url ='changeVehicleStatus?vehicleId='+action.vehicleId+'&status=false';
        var response = yield axios.post(url).then(res => res);
        var result = response.data;
        yield put(actions.removeVehicleServiceResponse(result)) 
    } catch(error){
      console.log(error);
   }
}

export default function* registerSaga(){
  yield all([
    takeEvery(GET_VEHICLE_DETAILS, getVehicleDetailsService),
    takeEvery(CHECK_VEHICLE_EXIST, checkVehicleExistenceService),
    takeEvery(ADD_EDIT_TRUCK, addEditTruckService),
    takeEvery(REMOVE_VEHICLE, removeVehicleService)
  ]);
}
