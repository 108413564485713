import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../../utils/axios';

function* getPlanReports(record){
    try{
        const getPlan = yield axios({
            url: `getPlanReports?orgId=${record.data.orgId}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'GET',
        }).then( response=>{ 
            return response.data
        });
        yield put(actions.getPlanReportsSuccess(getPlan));
    } catch(error){
        console.log("config created")
    }
}

function* redirectReport(record){
    try{
        const report = yield axios({
            url: `getSpecificPlanDetails?orgId=${record.data.orgId}&planNo=${record.data.planNo}&vehicleId=${record.data.vehicleId}&userId=${record.data.userId}`,
            method: 'GET',
        }).then( response=>{ 
            return response.data.data
        });
        const getHistory = report.planList[0];        

        yield put(
            record.history.push({
                pathname : `/fleet-owners/reports/history/history-detailed`,
                target: '_blank',
                state : { getHistory}
            })
        );
    } catch(error){
        console.log("config created")
    }
}

function* getOngoingExcel(record){
    try{
        const getExcel = yield axios({
            url: `getTotalOngoingCompletedExcel?orgId=${record.data.orgId}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'GET',
        }).then( response=>{ 
            return response.data
        });
        yield put(actions.getOngoingExcelSuccess(getExcel));
    } catch(error){
        yield put(actions.getOngoingExcelError(error));
    }
}
function* getCompletedExcel(record){
    try{
        const getExcel = yield axios({
            url: `getTotalTripsCompletedExcel?orgId=${record.data.orgId}&fromTimeUtc=${record.data.fromTimeUtc}&toTimeUtc=${record.data.toTimeUtc}`,
            method: 'GET',
        }).then( response=>{ 
            return response.data
        });
        yield put(actions.getCompletedExcelSuccess(getExcel));
    } catch(error){
        yield put(actions.getCompletedExcelError(error));
    }
}


export default function* getPlanReportSaga(){
    yield takeLatest(constant.GET_PLAN_REPORTS, getPlanReports);
    yield takeLatest(constant.REPORT_PAGE, redirectReport);
    yield takeLatest(constant.GET_ONGOING_EXCEL, getOngoingExcel);
    yield takeLatest(constant.GET_COMPLETED_EXCEL, getCompletedExcel);
}