import * as actionTypes from './constant'

/* Create Location */
export function getPlanReports(data) {
    return {
        type: actionTypes.GET_PLAN_REPORTS,
        data
    }
}

export function getPlanReportsSuccess(getPlan) {
    return {
        type: actionTypes.GET_PLAN_REPORTS_SUCCESS,
        getPlan
    }
}

export function getPlanReportsError(error) {
    return {
        type: actionTypes.GET_PLAN_REPORTS_FAILURE,
        error
    }
}

/* redirect report page */
export function reportPage(data,history) {
    return {
        type: actionTypes.REPORT_PAGE,
        data,
        history
    }
}

export function reportPageSuccess(getPlan) {
    return {
        type: actionTypes.REPORT_PAGE_SUCCESS,
        getPlan
    }
}

export function reportPageError(error) {
    return {
        type: actionTypes.REPORT_PAGE_FAILURE,
        error
    }
}

/* get excel page */
export function getOngoingExcel(data,history) {
    return {
        type: actionTypes.GET_ONGOING_EXCEL,
        data,
        history
    }
}

export function getOngoingExcelSuccess(getExcel) {
    return {
        type: actionTypes.GET_ONGOING_EXCEL_SUCCESS,
        getExcel
    }
}

export function getOngoingExcelError(error) {
    return {
        type: actionTypes.GET_ONGOING_EXCEL_FAILURE,
        error
    }
}

/* get completed excel  */
export function getCompletedExcel(data,history) {
    return {
        type: actionTypes.GET_COMPLETED_EXCEL,
        data,
        history
    }
}

export function getCompletedExcelSuccess(getExcel) {
    return {
        type: actionTypes.GET_COMPLETED_EXCEL_SUCCESS,
        getExcel
    }
}

export function getCompletedExcelError(error) {
    return {
        type: actionTypes.GET_COMPLETED_EXCEL_FAILURE,
        error
    }
}
