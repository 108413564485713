import * as consent from './constant';

const defaultState = {
    tyreList: [],
    tyreKmReport:{},
    loading: false
}

const getTyreList = (state, action) => ({ ...state, loading: true });
const getTyreListSuccess = (state, action) => ({ ...state, tyreList: action.tyreList, loading: false });
const getTyreListFailure = (state, action) => ({ ...state, loading: false });
const getTyreKmReport = (state, action) => ({ ...state, loading: true });
const getTyreKmReportSuccess = (state, action) => ({ ...state, tyreKmReport: action.tyreKmReport, loading: false });
const getTyreKmReportFailure = (state, action) => ({ ...state, loading: true });

export default function (prevState = defaultState, action) {
    switch (action.type) {
        case consent.GET_TYRE_LIST: return getTyreList(prevState, action);
        case consent.GET_TYRE_LIST_SUCCESS: return getTyreListSuccess(prevState, action);
        case consent.GET_TYRE_LIST_FAILURE: return getTyreListFailure(prevState, action);
        case consent.GET_TYRE_KM_REPORT: return getTyreKmReport(prevState, action);
        case consent.GET_TYRE_KM_REPORT_SUCCESS: return getTyreKmReportSuccess(prevState, action);
        case consent.GET_TYRE_KM_REPORT_FAILURE: return getTyreKmReportFailure(prevState, action);
        default: return prevState;
    }
}