import * as actionTypes from "./constant";

export default function claimApproval(prevState = {
    loading: false,
    claimRes: [],
    setClaim: '',
    response: ''
}, action) {
    switch (action.type) {
        case actionTypes.CLAIM_HISTORY_SERVICE:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.CLAIM_HISTORY_SUCCESS:
            return {
                ...prevState,
                claimRes: action.res,
                loading: false,
                setClaim: 'SET_CLAIM_DATA'
            }
        case actionTypes.CLAIM_APPROVE_SERVICE:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.CLAIM_APPROVE_SUCCESS:
            return {
                ...prevState,
                response: action.res,
                loading: false
            }
        case actionTypes.CLAIM_APPROVE_FAILURE:
            return {
                ...prevState,
                response: action.error,
                loading: false
            }
        case actionTypes.CLAIM_APPROVE_EMPTY:
            return {
                ...prevState,
                response: ''
            }
        case actionTypes.RESET_CLAIM:
            return {
                ...prevState,
                loading: false,
                setClaim: ''
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
