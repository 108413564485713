import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';

function* getAllVehiclesTyreService(data) {
    try {
        let res = yield axios.get(`getListOfAllVehicleTyres?orgId=${data.organizationId}&userId=${data.uid}`)
            .then(response => {
                return (response.data.data)
            });
        yield put(actions.getAllVehiclesTyreSuccess(res));
    } catch (error) {
        yield put(actions.getAllVehiclesTyreError(error));
    }
}

export default function* allVehicleTyreSaga() {
    yield all([
        takeLatest(actionTypes.GET_VEHICLE_TYRE_LIST, getAllVehiclesTyreService)
    ]);

}


