import { combineReducers } from 'redux';
import login from '../containers/signIn/reducer';
// import product from '../productList/reducer';
import vehicleReducer from '../containers/fleetOwners/dashboard/reducer';
import InstructionReducer from '../containers/fleetOwners/dashboard/attentionHistory/Instruction/reducer';
import allTruck from '../containers/fleetOwners/allTrucks/reducer';
import handOver from '../containers/fleetOwners/allTrucks/reducer';
import manageVehicle from '../containers/fleetOwners/manageVehicle/reducer';
import Register from '../containers/Register/reducer';
import AddTruck from '../containers/fleetOwners/AddTruck/reducer';
import Attention from '../containers/fleetOwners/dashboard/attentionHistory/attention/reducer';
import TruckHistory from '../containers/fleetOwners/dashboard/history/reducer';
import Profile from '../containers/fleetOwners/Profile/reducer';
import SimUsage from '../containers/fleetOwners/SIMConsent/reducer';
import GetUserProfile from '../components/Layouts/appLayout/reducer';
import truckStatusV2 from '../containers/fleetOwners/dashboard/truckStatus/reducer';
import driver from '../containers/fleetOwners/drivers/reducer';
import myOrg from '../containers/fleetOwners/organization/MyOrganization/reducer';
import assignOrg from '../containers/fleetOwners/organization/AssignedOrganization/reducer';
import myLocation from '../containers/fleetOwners/Location/reducer';
import planReducer from '../containers/fleetOwners/dashboard/plan/reducer';
import document from '../containers/fleetOwners/documents/reducer';
import tripDetail from '../containers/fleetOwners/dashboard/tripDetail/reducer';
import sharedTrips from '../containers/fleetOwners/sharedTrips/reducer';
import sharedTripsDetail from '../containers/fleetOwners/sharedTrips/details/reducer';
import addExpense from '../containers/fleetOwners/dashboard/attentionHistory/Expenses/AddExpenses/reducer';
import expense from '../containers/fleetOwners/dashboard/attentionHistory/Expenses/reducer';
import expenseHistroy from '../containers/fleetOwners/dashboard/attentionHistory/Expenses/ExpensesHistory/reducer';
import HandOver from '../containers/fleetOwners/dashboard/handover/reducer';
import Pod from '../containers/fleetOwners/dashboard/pod/reducer';
import OrgIncentive from '../containers/fleetOwners/Incentive/organization/reducer';
import DriverIncentive from '../containers/fleetOwners/Incentive/driver/reducer';
import FuelPrice from '../containers/FuelPrice/reducer';
import OrgReports from '../containers/fleetOwners/reports/organization/reducer';
import maintenance from '../containers/fleetOwners/FMS/maintenance/reducer';
import docRenewal from '../containers/fleetOwners/FMS/docRenewal/reducer';
import service from '../containers/fleetOwners/FMS/service/reducer';
import simCreditReminder from '../containers/fleetOwners/FMS/simCredits/reducer';
import expenseReports from '../containers/fleetOwners/reports/expenseReports/reducer';
import driverVerify from '../containers/fleetOwners/superAdmin/driverVerify/reducer';
import audit from '../containers/fleetOwners/superAdmin/audits/reducer';
import claimApproval from '../containers/fleetOwners/superAdmin/claimApprovals/reducer';
import migratedVehicles from '../containers/fleetOwners/superAdmin/migratedVehicles/reducer';
import reportChart from '../containers/fleetOwners/reports/chart/reducer';
import recharge from '../containers/fleetOwners/mobileRecharge/reducer';
import driverPerformance from '../containers/fleetOwners/Incentive/driverPerformance/reducer';
import tyreManagementReducer from '../containers/fleetOwners/tyreManagement/Tyres/reducer';
import vehicleTyreReducer from '../containers/fleetOwners/tyreManagement/ListOfAllVehiclesTyres/reducer';
import tyreReportsReducer from '../containers/fleetOwners/reports/tyreReports/reducer';
import tyreService from '../containers/fleetOwners/tyreManagement/ServiceLogs/reducer';
import tyreGraphReport from '../containers/fleetOwners/tyreManagement/tyreGraphReport/reducer';

const rootReducer = combineReducers({
    userState: login,
    truckData : allTruck,
    userProfile: GetUserProfile,
    handOverInfo : handOver,
    vehicle: vehicleReducer,
    manageVehicle: manageVehicle,
    registerInfo : Register,
    instruction : InstructionReducer,
    addTruck : AddTruck,
    attention : Attention,
    truckHistoryData :  TruckHistory,
    profile : Profile,
    myLocation: myLocation,
    simUsage : SimUsage,
    // productState : product
    truckStatusV2,
    driver,
    myOrg,
    assignOrg,
    planInfo: planReducer,
    document,
    tripDetail,
    sharedTrips,
    sharedTripsDetail,
    addExpense,
    expense,
    expenseHistroy,
    HandOver,
    podStatus: Pod,
    OrgIncentive,
    DriverIncentive,
    OrgReports,
    maintenance,
    docRenewal,
    service,
    simCreditReminder,
    FuelPrice,
    expenseReports,
    driverVerify,
    audit,
    claimApproval,
    migratedVehicles,
    reportChart,
    recharge,
    driverPerformance,
    vehicleTyreReducer,
    tyreReportsReducer,
    tyreManagementReducer,
    tyreService,
    tyreGraphReport
});

export default rootReducer;
