export const ADD_DRIVER = 'ADD_DRIVER'
export const EDIT_DRIVER = 'EDIT_DRIVER'
export const SET_RESPONSE_DATA = 'SET_RESPONSE_DATA'
export const GET_DRIVERS = 'GET_DRIVERS'
export const SET_DRIVER_DETAILS = 'SET_DRIVER_DETAILS'
export const REMOVE_DRIVER = 'REMOVE_DRIVER'
export const INVITE_DRIVER = 'INVITE_DRIVER'
export const UPDATE_FAVOURITE_STATUS = 'UPDATE_FAVOURITE_STATUS'
export const SEND_CONSENT_MSG = 'SEND_CONSENT_MSG'
export const EMPTY_RESPONSE = 'EMPTY_RESPONSE'
export const MODAL = 'MODAL'
export const CHANGE_DRIVER_LIST_T = 'CHANGE_DRIVER_LIST_T'
export const REFRESH_DRIVER = 'REFRESH_DRIVER'

export const EXCEL_DOWNLOAD = 'EXCEL_DOWNLOAD'
export const EXCEL_DOWNLOAD_SUCCESS = 'EXCEL_DOWNLOAD_SUCCESS'
export const EXCEL_DOWNLOAD_FAILURE = 'EXCEL_DOWNLOAD_FAILURE'
export const GET_DRIVERS_WITH_SEARCH_TEXT = 'GET_DRIVERS_WITH_SEARCH_TEXT'
export const DRIVER_LIST = 'DRIVER_LIST'


