import * as actionTypes from "./constant";

export default function simCreditReminder(prevState = {
    response: null,
    scrList: [],
    loading: false,
    delResponse: null
}, action) {
    switch (action.type) {
        case actionTypes.CREATE_SCR:
            return {
                ...prevState
            }
        case actionTypes.CREATE_SCR_SUCCESS:
            return {
                ...prevState,
                response: action.response,
                loading: false
            }
        case actionTypes.CREATE_SCR_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case actionTypes.UPDATE_SCR:
            return {
                ...prevState
            }
        case actionTypes.UPDATE_SCR_SUCCESS:
            return {
                ...prevState,
                response: action.response,
                loading: false
            }
        case actionTypes.UPDATE_SCR_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case actionTypes.VIEW_SCR:
            return {
                ...prevState
            }
        case actionTypes.VIEW_SCR_SUCCESS:
            return {
                ...prevState,
                scrList: action.scrList,
                loading: false
            }
        case actionTypes.VIEW_SCR_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case actionTypes.DELETE_SCR:
            return {
                ...prevState
            }
        case actionTypes.DELETE_SCR_SUCCESS:
            return {
                ...prevState,
                delResponse: action.response,
                loading: false
            }
        case actionTypes.DELETE_SCR_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case actionTypes.EMPTY_RESPONSE_REMINDER:
            return {
                ...prevState,
                response: null,
                delResponse: null
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
