import { 
    REGISTER_SUCCESS, 
    REGISTER_FAILURE, 
    DO_REGISTER,
    GET_STATE,
    GET_CITY ,
    SET_STATE,
    SET_CITY ,
    GET_STATE_CITY
} from './constant'

export function registerService(history){
    return {
        type : DO_REGISTER,
        history
    }
}

export function registerSuccess(userData){
    return {
        type : REGISTER_SUCCESS,
        userData
    }
}

export function registerError(error){
    return {
        type : REGISTER_FAILURE,
        error
    }
}
export function getStateList(countryCode){
    return {
        type : GET_STATE,
        countryCode
    }
}

export function getCity(stateId){
    return {
      type: GET_CITY,
      stateId: stateId
    }
  }
export function setStateList(stateList){
    return {
        type : SET_STATE,
        stateList: stateList,
    }
}

export function setCity(city){
    return {
        type : SET_CITY,
        city:city
    }
}
export function getStateCity(countryCode,stateName){
    return {
        type : GET_STATE_CITY,
        countryCode : countryCode,
        stateName : stateName
    }
}
export function editFleetowner(countryCode,stateName){
    return {
        type : GET_STATE_CITY,
        countryCode : countryCode,
        stateName : stateName
    }
}

