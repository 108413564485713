import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import { LOAD_ALLTRUCK_LODING, HAND_OVER } from './constant'
import * as Utils from '../../../utils'
import axios from 'axios'

 
function* allTruckService(actionData){

    try{
    const allTruckData = yield axios({
            url: `${Utils.WEBURL}/getVehicleListBasedOnFleetowner?fleetOwner=${actionData.uid}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });

         yield put(actions.allTruckSuccess(allTruckData));
         yield put (actionData.history.push('/home'))
    } catch(error){
        yield put(actions.allTruckError(error));
    }
}
function* handOverService(actionData){

        const date = new Date(actionData.payload.handover_date_time);
        const milliSeconds = date.getTime();
        const data = {
            "ownerName": actionData.payload.transporterName,
            'location': '',
            'plannedTime':'',
            'own': actionData.payload.own,
            'vehicleId': actionData.payload.vehicleId,
            "contactPerson" : actionData.payload.contact_person,
            "pickUpLoc" : actionData.payload.handover_location,
            "pickUpTime" : milliSeconds,
        }
    try{
    if(data.own !== "OWN"){
        
         yield axios({
            url: `${Utils.VEHICLEOWNERURL}?vehicleId=${data.vehicleId}&owner=Drs`,
            method: 'POST',
        })
       
    }
    else{
       
         yield axios({
            url: `${Utils.VEHICLEOWNERURL}?vehicleId=${data.vehicleId}&owner=Own`,
            method: 'POST',
        })
    }
    const handOverData = yield axios({
            url: `${Utils.HANDOVERURL}?vehicleId=${data.vehicleId}&ownerName=${data.ownerName}&location=''&plannedTime=''&own=${data.own}&contactPerson=${data.contactPerson}&pickUpLoc=${data.pickUpLoc}&pickUpTime=${data.pickUpTime}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });

        yield put(actions.handOverSuccess(handOverData));
         yield put (actionData.history.push('/fleetOwners/AllTrucks'))
    } catch(error){
        yield put(actions.handOverError(error));
    }
}

export default function* allTruckSaga(){
    yield takeLatest(LOAD_ALLTRUCK_LODING, allTruckService)
    yield takeLatest(HAND_OVER, handOverService)

}