import * as actionTypes from './constant'

export default function performanceService(prevState = {
    list: [],
    loading: true,
    addAmount : '',
    paymentAudit: '',
    creditAmount: '',
    error: '',
}, action) {
    switch (action.type) {

        /* View Config Data */
        case actionTypes.DRIVER_PERFORMANCE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case actionTypes.DRIVER_PERFORMANCE_SUCCESS:
            return {
                ...prevState,
                list: action.data.dataList,
                loading: false
            }
        case actionTypes.DRIVER_PERFORMANCE_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }

        /* Add Amount */
        case actionTypes.ADD_AMOUNT:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.ADD_AMOUNT_SUCCESS:
            return {
                ...prevState,
                addAmount: action.data,
            }
        case actionTypes.ADD_AMOUNT_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        
         /* Payment Audit */
         case actionTypes.PAYMENT_AUDIT:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.PAYMENT_AUDIT_SUCCESS:
            return {
                ...prevState,
                paymentAudit: action.data,
            }
        case actionTypes.PAYMENT_AUDIT_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }

        case actionTypes.CREDIT_AMOUNT:
            return {
                ...prevState,
                creditAmount : action.amount,
                error: action.error,
            }

        default: {
            return {
                ...prevState
            }
        }

    }
}
