import * as actionTypes from './constant'

/*Fuel list*/
export function fuelList(date) {
    return {
        type: actionTypes.FUEL_LIST,
        date
    }
}

export function fuelListSuccess(list) {
    return {
        type: actionTypes.FUEL_LIST_SUCCESS,
        list
    }
}

export function fuelListError(error) {
    return {
        type: actionTypes.FUEL_LIST_FAILURE,
        error
    }
}

/*Fuel History*/
export function fuelHistory(date,district) {
    return {
        type: actionTypes.FUEL_HISTORY,
        date,
        district
    }
}

export function fuelHistorySuccess(history) {
    return {
        type: actionTypes.FUEL_HISTORY_SUCCESS,
        history
    }
}

export function fuelHistoryError(error) {
    return {
        type: actionTypes.FUEL_HISTORY_FAILURE,
        error
    }
}

/*Fuel favorite list*/
export function favorite(orgId,district) {
    return {
        type: actionTypes.FAVORITE,
        orgId,
        district
    }
}

export function favoriteSuccess(data) {
    return {
        type: actionTypes.FAVORITE_SUCCESS,
        data
    }
}

export function favoriteError(error) {
    return {
        type: actionTypes.FAVORITE_FAILURE,
        error
    }
}

/*Fuel favorite list*/
export function favoriteList(orgId) {
    return {
        type: actionTypes.FAVORITE_HISTORY,
        orgId
    }
}

export function favoriteListSuccess(list) {
    return {
        type: actionTypes.FAVORITE_HISTORY_SUCCESS,
        list
    }
}

export function favoriteListError(error) {
    return {
        type: actionTypes.FAVORITE_HISTORY_FAILURE,
        error
    }
}    

export function collapseStatus(status) {
    return {
        type: actionTypes.COLLAPSE_STATUS,
        status
    }
} 