import {
    LOAD_PROFILE_SUCCESS, LOAD_PROFILE_FAILURE, LOAD_PROFILE,
    NOTIFICATION, NOTIFICATION_FAILURE, NOTIFICATION_SUCCESS,
    STORE_NOTIFICATION, STORE_NOTIFICATION_FAILURE, STORE_NOTIFICATION_SUCCESS, KYC_PROCESS, KYC_PROCESS_SUCCESS, KYC_PROCESS_EMPTY, UPLOAD_EXCEL, UPLOAD_EXCEL_SUCCESS_OR_FAILURE
} from './constant'

export default function profile(prevState = {
    uid: '',
    data: '',
    driverList: '',
    loading: false,
    error: '',
    notificationData: [],
    res: '',
    uploadMessage: ''
}, action) {
    switch (action.type) {
        case LOAD_PROFILE:
            return {
                ...prevState,
                uid: action.uid,
            }
        case LOAD_PROFILE_SUCCESS:
            return {
                ...prevState,
                data: action.getProfile,
                loading: false
            }
        case LOAD_PROFILE_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case NOTIFICATION:
            return {
                ...prevState
            }
        case NOTIFICATION_SUCCESS:
            return {
                ...prevState
            }
        case NOTIFICATION_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case STORE_NOTIFICATION:
            return {
                ...prevState
            }
        case STORE_NOTIFICATION_SUCCESS:
            return {
                ...prevState,
                notificationData: action.notificationHistory
            }
        case STORE_NOTIFICATION_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case KYC_PROCESS:
            return {
                ...prevState
            }
        case KYC_PROCESS_SUCCESS:
            return {
                ...prevState,
                res: action.res
            }
        case KYC_PROCESS_EMPTY:
            return {
                ...prevState,
                res: ''
            }
        case UPLOAD_EXCEL:
            return {
                ...prevState,
            }
        case UPLOAD_EXCEL_SUCCESS_OR_FAILURE:
            return {
                ...prevState,
                uploadMessage: action.res
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
