import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as orgInfoAction from '../../../signIn/action'
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';

const errorMsg = {
    "message": "FAILURE",
    "data": null,
    "errorDesc": "Something went wrong!",
    "errorCode": 1029
}
function* createOrganizationService(actionData) {
    try {
        let response = yield axios.post(`createOrganization?organizationName=${actionData.orgDetails.organizationName}&userId=${actionData.orgDetails.userId}&simTracking=${actionData.orgDetails.simTracking}&checkListSubmission=${actionData.orgDetails.checkListSubmission}&mileage=${actionData.orgDetails.mileage}&fuelLevel=${actionData.orgDetails.fuelLevel}&telemeticsUrl=${actionData.orgDetails.telemeticsUrl}&telemeticsUserId=${actionData.orgDetails.telemeticsUserId}&fragile=${actionData.orgDetails.fragile}&orgCountryCode=${actionData.orgDetails.orgCountryCode}&formatDate=${actionData.orgDetails.formatDate}&autoPlanCreation=${actionData.orgDetails.autoPlanCreation}`)
            .then(res => {
                return (res.data)
            });
        yield put(actions.createOrganizationSuccess(response))
    } catch (error) {
        yield put(actions.createOrganizationError(error));
    }
}
function* editOrganizationService(actionData) {
    try {
        let response = yield axios.post(`updateOrg`, actionData.editOrgDetails)
            .then(res => {
                return (res.data)
            });
        yield put(actions.editOrganizationSuccess(response))
    } catch (error) {
        yield put(actions.editOrganizationError(error));
    }
}
function* myOrgDropdownService(actionData) {
    try {
        let response = yield axios.post(`getOrganizationBasedOnFleetowner?fleetownerId=${actionData.orgDetails}`)
            .then(res => {
                return (res.data.data);
            });
        yield put(actions.myOrgDropdownSuccess(response))
    } catch (error) {
        yield put(actions.myOrgDropdownError(error));
    }
}
function* getFleetownersForSpecificOrgService(actionData) {
    try {
        let response = yield axios.post(`getFleetownersForSpecificOrg?organizationId=${actionData.organizationId}&accUserId=${actionData.accUserId}`).then(res => res);
        let result = response.data;
        if (result.message === "SUCCESS") {
            yield put(actions.setSpecificOrgInfo(result.dataList))
        } else {
            yield put(actions.setError(errorMsg))
        }
    } catch (error) {
        yield put(actions.setError(errorMsg))
    }
}
function* inviteOrganizationService(actionData) {
    const { organizationId, emailId, role, accUserId } = actionData.data;
    try {
        let response = yield axios.post(`inviteOrganization?organizationId=${organizationId}&emailId=${emailId}&role=${role}&accUserId=${accUserId}`).then(res => res);
        let responseData = response.data;
        yield put(actions.setResponseData(responseData))
    } catch (error) {
        yield put(actions.setError(errorMsg))
    }
}
function* deleteOrganizationRoleService(actionData) {
    try {
        let response = yield axios.post(`deleteOrganizationRole?id=${actionData.id}`).then(res => res);
        let responseData = response.data;
        yield put(actions.setResponseData(responseData))
    } catch (error) {
        yield put(actions.setError(errorMsg))
    }
}
function* delMyorgService(actionData) {
    try {
        let response = yield axios.post(`deleteOrganization?orgId=${actionData.delOrgDetails.orgId}&userId=${actionData.delOrgDetails.userId}&status=${actionData.delOrgDetails.status}`)
            .then(res => {
                return (res.data);
            });
        yield put(actions.delMyorgSuccess(response))
    } catch (error) {
        yield put(actions.delMyorgError(error))
    }
}
function* updateOrganizationRoleService(actionData) {
    const { fleetownerId, organizationId, role } = actionData.data;
    try {
        let response = yield axios.post(`updateOrganizationRole?fleetownerId=${fleetownerId}&organizationId=${organizationId}&role=${role}`).then(res => res);
        let responseData = response.data;
        yield put(actions.setResponseData(responseData))
    } catch (error) {
        yield put(actions.setError(errorMsg))
    }
}

function* orgProfile(actionData) {
    try {
        const getProfile = yield axios({
            url: `getSpecificFleetowner?userId=` + actionData.uid,
            method: 'POST',
        })
            .then(res => {
                return res.data
            });
        yield put(actions.orgProfileSuccess(getProfile));
    } catch (error) {
        //yield put(actions.profileError(error));
    }
}
function* getSpecificOrgInfoService(actionData) {
    try {
        let result = yield axios.post(`getOrgInfo?orgId=${actionData.organizationId}&userId=${actionData.uid}`).then(res => res.data);
        if (result.message === "SUCCESS") {
            let orgInfo = result.data;
            if (orgInfo) {
                yield put(actions.setCountry(orgInfo.orgCountryCode));
                yield put(actions.setDateFormat(orgInfo.formatDate));
                yield put(actions.setAutoPlanCreation(orgInfo.autoPlanCreation));
                yield put(orgInfoAction.setOrgInfo(orgInfo));
            } else {
                yield put(actionData.history.push('/login'))
            }
        }
    } catch (error) {
        console.log(error);
    }
}
function* orgPaymentHistory(record) {
    try {
        var url = `viewPaymentHistory?fleetownerId=${record.uid}&fromTimeUtc=${record.utcFrom}&toTimeUtc=${record.utcEnd}`;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            let data = response.data;
            let { message, dataList } = data;
            if (message === "SUCCESS") {
                if (dataList) {
                    yield put(actions.orgPaymentSuccess(data))
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
}
function* syncWithGpsvts(actionData) {
    try {
        var url = `syncFleet?Email=${actionData.Email}&orgId=${actionData.orgId}&userId=${actionData.userId}`;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            yield put(actions.GpsvtsSyncSuccess(response));
        }
    } catch (error) {
        console.log(error);
    }
}
function* loginGpsvts(actionData) {
    try {
        var url = `getCustomToken?orgId=${actionData.orgId}&uid=${actionData.userId}&email=${actionData.emailId}`;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            yield put(actions.loginSuccess(response,actionData));
        }
    } catch (error) {
        console.log(error);
    }
}
export default function* myOrgSaga() {
    yield all([
        takeLatest(actionTypes.CREATE_ORGANIZATION, createOrganizationService),
        takeLatest(actionTypes.EDIT_ORGANIZATION, editOrganizationService),
        takeLatest(actionTypes.MY_ORGANIZATION_DROPDOWN, myOrgDropdownService),
        takeLatest(actionTypes.GET_ORG_INFO, getFleetownersForSpecificOrgService),
        takeLatest(actionTypes.INVITE_ORG, inviteOrganizationService),
        takeLatest(actionTypes.DELETE_INVITED_USER, deleteOrganizationRoleService),
        takeLatest(actionTypes.UPDATE_ROLE, updateOrganizationRoleService),
        takeLatest(actionTypes.DEL_MY_ORGANIZATION, delMyorgService),
        takeLatest(actionTypes.LOAD_ORG_PROFILE, orgProfile),
        takeLatest(actionTypes.LOAD_ORG_PAYMENT, orgPaymentHistory),
        takeLatest(actionTypes.COUNTRY_GET_SPECIFIC_ORG_INFO, getSpecificOrgInfoService),
        takeLatest(actionTypes.SYNC_WITH_GPSVTS, syncWithGpsvts),
        takeLatest(actionTypes.LOGIN_GPSVTS, loginGpsvts),
        takeLatest(actionTypes.DOMAIN,loginGpsvts)
        // takeLatest([actionTypes.DOMAIN,actionTypes.LOGIN_GPSVTS], loginGpsvts)
    ]);

}