export const LOAD_TRUCKHISTORY_LODING = 'LOAD_TRUCKHISTORY_LODING'
export const LOAD_TRUCKHISTORY_SUCCESS = 'LOAD_TRUCKHISTORY_SUCCESS'
export const LOAD_TRUCKHISTORY_FAILURE = 'LOAD_TRUCKHISTORY_FAILURE'

export const LOAD_VEHICLE = 'LOAD_VEHICLE'
export const LOAD_VEHICLE_SUCCESS = 'LOAD_VEHICLE_SUCCESS'
export const LOAD_VEHICLE_FAILURE = 'LOAD_VEHICLE_FAILURE'

export const GET_LOAD_DRIVER = 'GET_LOAD_DRIVER'
export const GET_LOAD_DRIVER_SUCCESS = 'GET_LOAD_DRIVER_SUCCESS'
export const GET_LOAD_DRIVER_FAILURE = 'GET_LOAD_DRIVER_FAILURE'

export const DISPLAY_MODE = 'DISPLAY_MODE'
export const SAVE_POD_LIST = 'SAVE_POD_LIST'

export const HISTORY_DETAIL = 'HISTORY_DETAIL'
export const HISTORY_DETAIL_SUCCESS = 'HISTORY_DETAIL_SUCCESS'
export const HISTORY_DETAIL_FAILURE = 'HISTORY_DETAIL_FAILURE'

export const GET_TRIP_REPORTS = 'GET_TRIP_REPORTS'
export const GET_TRIP_REPORTS_SUCCESS = 'GET_TRIP_REPORTS_SUCCESS'
export const GET_TRIP_REPORTS_FAILURE = 'GET_TRIP_REPORTS_FAILURE'

export const GET_FUEL_DATA = 'GET_FUEL_DATA'
export const GET_FUEL_DATA_SUCCESS = 'GET_FUEL_DATA_SUCCESS'
export const GET_FUEL_DATA_FAILURE = 'GET_FUEL_DATA_FAILURE'


