
import * as actionTypes from "./constant";
// get type
export function getServiceTypeService(){
    return {
        type : actionTypes.GET_SERVICE_TYPE       
    }
}
export function getServiceTypeSuccess(serviceTypes){
    return {
        type : actionTypes.GET_SERVICE_TYPE_SUCCESS,
        serviceTypes
    }
}

export function getServiceTypeError(error){
    return {
        type : actionTypes.GET_SERVICE_TYPE_FAILURE,
        error
    }
}
// get vehiclelist
export function getVehiclesService(organizationId) {
    return {
        type: actionTypes.GET_VEHICLELIST_SERVICE,
        organizationId
    }
}
export function getVehiclesSuccess(vehicleList) {
    return {
        type: actionTypes.GET_VEHICLELIST_SERVICE_SUCCESS,
        vehicleList
    }
}

export function getVehiclesError(error) {
    return {
        type: actionTypes.GET_VEHICLELIST_SERVICE_FAILURE,
        error
    }
}
// create reminder
export function createServiceService(payLoad) {
    return {
        type: actionTypes.CREATE_SERVICE,
        payLoad
    }
}
export function createServiceSuccess(response) {
    return {
        type: actionTypes.CREATE_SERVICE_SUCCESS,
        response
    }
}

export function createServiceError(error) {
    return {
        type: actionTypes.CREATE_SERVICE_FAILURE,
        error
    }
}

// update reminder
export function updateServiceService(payLoad) {
    return {
        type: actionTypes.UPDATE_SERVICE,
        payLoad
    }
}
export function updateServiceSuccess(response) {
    return {
        type: actionTypes.UPDATE_SERVICE_SUCCESS,
        response
    }
}

export function updateServiceError(error) {
    return {
        type: actionTypes.UPDATE_SERVICE_FAILURE,
        error
    }
}

// view reminders
export function getViewServiceService(organizationId) {
    return {
        type: actionTypes.VIEW_SERVICE,
        organizationId
    }
}
export function getViewServiceSuccess(viewServiceList) {
    return {
        type: actionTypes.VIEW_SERVICE_SUCCESS,
        viewServiceList
    }
}
export function getViewServiceError(error) {
    return {
        type: actionTypes.VIEW_SERVICE_FAILURE,
        error
    }
}

// delete reminder

export function deleteServiceService(id) {
    return {
        type: actionTypes.DELETE_SERVICE,
        id
    }
}
export function deleteServiceSuccess(delResponse) {
    return {
        type: actionTypes.DELETE_SERVICE_SUCCESS,
        delResponse
    }
}

export function deleteServiceError(error) {
    return {
        type: actionTypes.DELETE_SERVICE_FAILURE,
        error
    }
}
// empty response

export function emptyResponse() {
    return {
        type: actionTypes.EMPTY_RESPONSE_REMINDER        
    }
}





