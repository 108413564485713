import { REGISTER_SUCCESS, REGISTER_FAILURE,SET_STATE, GET_STATE,SET_CITY } from './constant'
export default function register(prevState = {
    stateList: [],
    city: [],
    loader: false
    
}, action){
    switch(action.type) {
        case REGISTER_SUCCESS:
            return {
                ...prevState,
                userInfo : action.userInfo
            }
        case REGISTER_FAILURE:
            return {
                ...prevState,
                error : action.error
            }
        case GET_STATE:
                return {
                    ...prevState,
                }
        case SET_CITY:
            return {
                ...prevState,
                city : action.city
            }
        case SET_STATE:
            return {
                ...prevState,
                stateList : action.stateList
            }
        default : {
            return {
                ...prevState
            }
        }
    }
}
