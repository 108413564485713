import * as actionTypes from './constant'

export default function locationService(prevState = {
    list: [],
    create: '',
    update: '',
    driverList: '',
    loading: true,
    formLoading: true,
    redeemLoading: true,
    redeem: '',
    planLoading: true,
    redeemPoints: '',
    planList: '',
    error: '',
}, action) {
    switch (action.type) {

        //View Config Data
        case actionTypes.VIEW_DRIVER_CONFIG:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case actionTypes.VIEW_DRIVER_CONFIG_SUCCESS:
            return {
                ...prevState,
                list: action.createData.dataList,
                loading: false
            }
        case actionTypes.VIEW_DRIVER_CONFIG_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }

        //Create  Config
        case actionTypes.DRIVER_CONFIG:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case actionTypes.DRIVER_CONFIG_SUCCESS:
            return {
                ...prevState,
                create: action.createData,
                loading: false
            }

        case actionTypes.DRIVER_CONFIG_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }

        //Update driver  Config
        case actionTypes.UPDATE_DRIVER_CONFIG:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case actionTypes.UPDATE_DRIVER_CONFIG_SUCCESS:
            return {
                ...prevState,
                update: action.updateData,
                loading: false
            }
        case actionTypes.UPDATE_DRIVER_CONFIG_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }

        //Get Driver List
        case actionTypes.LOAD_DRIVER:
            return {
                ...prevState,
                error: action.error,
                formLoading: true
            }
        case actionTypes.LOAD_DRIVER_SUCCESS:
            return {
                ...prevState,
                driverList: action.driverData,
                formLoading: false
            }
        case actionTypes.LOAD_DRIVER_FAILURE:
            return {
                ...prevState,
                error: action.error,
                formLoading: true
            }

        //Driver Redeem List
        case actionTypes.DRIVER_REDEEM:
            return {
                ...prevState,
                error: action.error,
                redeemLoading: true
            }
        case actionTypes.DRIVER_REDEEM_SUCCESS:
            return {
                ...prevState,
                redeem: action.redeemData.data,
                redeemLoading: false
            }

        case actionTypes.DRIVER_REDEEM_FAILURE:
            return {
                ...prevState,
                error: action.error,
                redeemLoading: true
            }

        //Driver Plan List
        case actionTypes.DRIVER_PLAN:
            return {
                ...prevState,
                error: action.error,
                planLoading: true
            }
        case actionTypes.DRIVER_PLAN_SUCCESS:
            return {
                ...prevState,
                planList: action.planData.dataList,
                planLoading: false
            }

        case actionTypes.DRIVER_PLAN_FAILURE:
            return {
                ...prevState,
                error: action.error,
                planLoading: true
            }

        //Redeem Point 
        case actionTypes.REDEEM_POINT:
            return {
                ...prevState,
                error: action.error,
                //redeemLoading: true
            }
        case actionTypes.REDEEM_POINT_SUCCESS:
            return {
                ...prevState,
                redeemPoints: action.redeemPoints,
                //redeemLoading: false
            }

        case actionTypes.REDEEM_POINT_FAILURE:
            return {
                ...prevState,
                error: action.error,
                //redeemLoading: true
            }

        default: {
            return {
                ...prevState
            }
        }

    }
}
