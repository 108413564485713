export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS';
export const EDIT_ORGANIZATION_FAILURE = 'EDIT_ORGANIZATION_FAILURE';
export const MY_ORGANIZATION_DROPDOWN = 'MY_ORGANIZATION_DROPDOWN';
export const MY_ORGANIZATION_DROPDOWN_SUCCESS = 'MY_ORGANIZATION_DROPDOWN_SUCCESS';
export const MY_ORGANIZATION_DROPDOWN_FAILURE = 'MY_ORGANIZATION_DROPDOWN_FAILURE';
export const DEL_MY_ORGANIZATION = 'DEL_MY_ORGANIZATION';
export const DEL_MY_ORGANIZATION_SUCCESS = 'DEL_MY_ORGANIZATION_SUCCESS';
export const DEL_MY_ORGANIZATION_FAILURE = 'DEL_MY_ORGANIZATION_FAILURE';
export const INVITE_ORG = 'INVITE_ORG';
export const SET_RESPONSE_DATA_ORG = 'SET_RESPONSE_DATA_ORG';
export const EMPTY_RESPONSE = 'EMPTY_RESPONSE';
export const GET_ORG_INFO = 'GET_ORG_INFO';
export const SET_ORG_INFO_INVITED = 'SET_ORG_INFO_INVITED';
export const SET_ERROR_MSG = 'SET_ERROR_MSG';
export const DELETE_INVITED_USER = 'DELETE_INVITED_USER';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const ORG_MODAL = 'ORG_MODAL';
export const MY_ORG = 'MY_ORG';
export const SET_INITIAL_ORG = 'SET_INITIAL_ORG';
export const LOAD_ORG_PROFILE = "LOAD_ORG_PROFILE";
export const LOAD_ORG_PROFILE_SUCCESS = "LOAD_ORG_PROFILE_SUCCESS";
export const LOAD_ORG_PAYMENT = "LOAD_ORG_PAYMENT";
export const LOAD_ORG_PAYMENT_SUCCESS = "LOAD_ORG_PAYMENT_SUCCESS";
export const SET_COUNTRY = "SET_COUNRTY";
export const SET_DATEFORMAT = "SET_DATEFORMAT";
export const SET_AUTO_PLAN_CREATION = "SET_AUTO_PLAN_CREATION";
export const COUNTRY_GET_SPECIFIC_ORG_INFO = "COUNTRY_GET_SPECIFIC_ORG_INFO";
export const SYNC_WITH_GPSVTS = "SYNC_WITH_GPSVTS";
export const SYNC_WITH_GPSVTS_SUCCESS = "SYNC_WITH_GPSVTS_SUCCESS";
export const LOGIN_GPSVTS = "LOGIN_GPSVTS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const EMPTY_LOGIN_MESSAGE = "EMPTY_LOGIN_MESSAGE";
export const DOMAIN = 'DOMAIN';
export const DOMAIN_SUCCESS = 'DOMAIN_SUCCESS';







