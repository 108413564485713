import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';


function* migratedVehHistoryService() {
    try {
        let response = yield axios.get(`getMigrationRequest`).then(res => res);
        yield put(actions.migratedVehHistorySuccess(response.data.dataList))
    } catch (error) {
        console.log(error);
    }
}
function* migratedVehApproveService(actionData) {
    try {
        let response = yield axios.post(`updateMigrateProcess`, actionData.data).then(res => res);
        yield put(actions.migratedVehApproveSuccess(response.data))
    } catch (error) {
        yield put(actions.migratedVehApproveError(error))
    }
}

export default function* claimApprovalSaga() {
    yield all([
        takeLatest(actionTypes.MIGRATED_VEH_HISTORY_SERVICE, migratedVehHistoryService),
        takeLatest(actionTypes.MIGRATED_VEH_APPROVE_SERVICE, migratedVehApproveService)
    ]);
}
