import * as actionTypes from "./constant";

export function getAttentionListService(){
    return {
        type : actionTypes.GET_ATTENTION_LIST        
    }
}
export function getAttentionListSuccess(getAttentionList){
    return {
        type : actionTypes.GET_ATTENTION_LIST_SUCCESS,
        getAttentionList
    }
}

export function getAttentionListError(error){
    return {
        type : actionTypes.GET_ATTENTION_LIST_FAILURE,
        error
    }
}