import * as actionTypes from "./constant";

export default function vehicleTyreReducer(prevState = {
    vehicleTyreList: [],
    response: null,
    loading: false   
}, action) {
    switch (action.type) {
        case actionTypes.GET_VEHICLE_TYRE_LIST:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.GET_VEHICLE_TYRE_LIST_SUCCESS:
            return {
                ...prevState,
                vehicleTyreList: action.vehicleTyreList,
                loading: false
            }
        case actionTypes.GET_VEHICLE_TYRE_LIST_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
    
        default: {
            return {
                ...prevState
            }
        }
    }
}
