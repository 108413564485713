import * as actionTypes from "./constant";
export default function recharge(prevState = {
    paymentHistory: [],
    serviceType: '',
}, action) {
    switch (action.type) {

        case actionTypes.SET_PAYMENT_HISTORY_VALUE:
            return {
                ...prevState,
                paymentHistory: action.paymentHistory,
                serviceType: 'SET_PAYMENT_HISTORY_VALUE'
            }
            case actionTypes.RESET_SERVICE_VALUE:
                return {
                    ...prevState,
                    serviceType : ''
                }
        default: {
            return {
                ...prevState
            }
        }
    }
}
