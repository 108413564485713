export const MY_ORGANIZATION_DROPDOWN= 'MY_ORGANIZATION_DROPDOWN';
export const MY_ORGANIZATION_DROPDOWN_SUCCESS= 'MY_ORGANIZATION_DROPDOWN_SUCCESS';
export const MY_ORGANIZATION_DROPDOWN_FAILURE= 'MY_ORGANIZATION_DROPDOWN_FAILURE';
export const LEAVE_ORG= 'LEAVE_ORG';
export const LEAVE_ORG_SUCCESS= 'LEAVE_ORG_SUCCESS';
export const LEAVE_ORG_FAILURE= 'LEAVE_ORG_FAILURE';
export const MODAL= 'MODAL';
export const MY_ORG= 'MY_ORG';
export const EMPTY_RESPONSE_ORG_A='EMPTY_RESPONSE_ORG_A'


