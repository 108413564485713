import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as vehiAction from '../fleetOwners/AddTruck/action';
import { DO_LOGIN, GET_SPECIFIC_ORG_INFO, REFRESH_TOKEN, REGISTER_PROCESS } from './constant'
import * as Utils from '../../utils'
import axios from 'axios'
import axiosBaseDrs from '../../utils/axiosWithoutToken';
import setAuthorizationToken from '../../utils/setAuthorizationToken'
import { StorageClass } from '../../utils/StorageClass'
function* loginService(actionData) {
    const storage = new StorageClass();
    //get Default org
    try {
        // get Default Org Info and set Token
        var updateVehicle;
        if (actionData.vehicle === 'null' || !actionData.vehicle) {
            updateVehicle = '';
        }
        else {
            updateVehicle = actionData.vehicle;
        }
        if (updateVehicle !== "") {
            let updateVehicleResult = yield axiosBaseDrs.post(`updateReferenceVehicleId?userId=${actionData.data.uid}&vehicleId=${updateVehicle}`).then(res => res.data.message);
            if (updateVehicleResult === 'SUCCESS') {
                let result = yield axiosBaseDrs.post(`getDefaultOrg?userId=${actionData.data.uid}`).then(res => res.data);
                if (result.message === "SUCCESS") {
                    let orgInfo = result.data;
                    if (orgInfo) {
                        yield put(actions.setOrgInfo(orgInfo));
                        yield storage.setItem('organizationId', orgInfo.organizationId);
                        try {
                            //Temporary hide  JSW token Check
                            // let data = {
                            //     "username": actionData.data.uid,
                            //     "password": actionData.data.emailId ? actionData.data.emailId : actionData.data.phoneCode + actionData.data.contactNumber,
                            //     "organizationId": orgInfo.organizationId
                            // }
                            // const userData = yield axios({
                            //     url: Utils.AUTHENTICATE_URL,
                            //     method: 'POST',
                            //     data: JSON.stringify(data)
                            // })
                            //     .then(response => {
                            //         return response.data
                            //     });
                            // yield storage.setItem('token', userData.token);
                            const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJQWlc0bnpRb05wTmo0TU5KSGNYV3JEdmYzM20xIiwiZXhwIjoxNjMzMTAxNzczLCJpYXQiOjE2MzMwODM3NzN9._MUUrZ5Ioh_A_hyqcp8G1WS-JBT05YNt-fe9WIp4p1qtkKpU39ZRHA8aknnuA1c8_eFqz_vIGLqrZYE-ca0Txw'
                            yield storage.setItem('token', token);

                            if (orgInfo.vehicleIdForReference) {
                                yield axiosBaseDrs({
                                    url: `/getDocumentsForPlan?vehicleId=${orgInfo.vehicleIdForReference}`,
                                    method: 'POST',
                                }).then(response => {
                                    return response.data
                                });
                                var vehicleData = {
                                    "vehicleName": orgInfo.vehicleIdForReference,
                                    "vehicleRegistrationNum": orgInfo.vehicleIdForReference,
                                    "vehicleMake": '',
                                    "vehicleType": '',
                                    "fastTag": "N",
                                    "tonnage": '',
                                    "speedLimit": '',
                                    "mileage": '',
                                    "truckAxleType": '',
                                    "batterySerNum1": '',
                                    "batterySerNum2": '',
                                    "rcNum": "-",
                                    "engineNum": '',
                                    "chassisNum": '',
                                    "specialToolList": "-",
                                    "tyreSerialNum": '',
                                    "truckPermitFileName": '',
                                    "regCopyFileName": '',
                                    "insuranceCopyFileName": '',
                                    "pollutionCertficateFileName": '',
                                    "bodyType": '',
                                    "fastTagNum": "-",
                                    "vehicleModel": '',
                                    "organizationId": orgInfo.organizationId,
                                    "vehicleMode": ''
                                }
                                vehicleData = { ...vehicleData, 'userId': actionData.data.uid }
                                yield put(vehiAction.addEditTruckDetails('addVehicleId', vehicleData))
                                yield put(actionData.history.push('/fleet-owners/manage-vehicle'))
                            }
                            else {
                                yield put(actionData.history.push('/fleet-owners/dashboard'))
                            }
                        } catch (error) {
                            yield put(actions.loginError(error));
                        }
                    } else {
                        yield put(actionData.history.push('/login'))
                    }

                }
            }
        } else {
            let result = yield axiosBaseDrs.post(`getDefaultOrg?userId=${actionData.data.uid}`).then(res => res.data);
            if (result.message === "SUCCESS") {
                let orgInfo = result.data;
                if (orgInfo) {
                    yield put(actions.setOrgInfo(orgInfo));
                    yield storage.setItem('organizationId', orgInfo.organizationId);
                    try {
                        //Temporary hide  JSW token Check
                        // let data = {
                        //     "username": actionData.data.uid,
                        //     "password": actionData.data.emailId ? actionData.data.emailId : actionData.data.phoneCode + actionData.data.contactNumber,
                        //     "organizationId": orgInfo.organizationId
                        // }
                        // const userData = yield axios({
                        //     url: Utils.AUTHENTICATE_URL,
                        //     method: 'POST',
                        //     data: JSON.stringify(data)
                        // })
                        //     .then(response => {
                        //         return response.data
                        //     });
                        // yield storage.setItem('token', userData.token);

                        const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJQWlc0bnpRb05wTmo0TU5KSGNYV3JEdmYzM20xIiwiZXhwIjoxNjMzMTAxNzczLCJpYXQiOjE2MzMwODM3NzN9._MUUrZ5Ioh_A_hyqcp8G1WS-JBT05YNt-fe9WIp4p1qtkKpU39ZRHA8aknnuA1c8_eFqz_vIGLqrZYE-ca0Txw'
                        yield storage.setItem('token', token);
                        yield put(actionData.history.push('/fleet-owners/dashboard'))

                    } catch (error) {
                        yield put(actions.loginError(error));
                    }
                } else {
                    yield put(actionData.history.push('/login'))
                }
            }
        }
    } catch (error) {
        yield put(actions.loginError(error));
    }
}

function* registerService(actionData) {
    let result = yield axiosBaseDrs.post(`addFleetowner`, actionData.data).then(res => res.data);
    if (result === "SUCCESS") {
        yield put(actions.loginService(actionData.data, actionData.history, actionData.vehicle));
    }
}

function* getSpecificOrgService(actionData) {
    try {
        let result = yield axiosBaseDrs.post(`getOrgInfo?orgId=${actionData.organizationId}&userId=${actionData.uid}`).then(res => res.data);
        if (result.message === "SUCCESS") {
            let orgInfo = result.data;
            if (orgInfo) {
                yield put(actions.setOrgInfo(orgInfo));
            } else {
                yield put(actionData.history.push('/login'))
            }
        }
    } catch (error) {
        yield put(actions.loginError(error));
    }
}
function* refreshTokenService(actionData) {
    try {
        const storage = new StorageClass();
         //Temporary hide  JSW token Check
        // let data = {
        //     "username": actionData.data.userId,
        //     "password": actionData.data.emailId,
        //     "organizationId": storage.getItem('organizationId')
        // }
        // const userData = yield axios({
        //     url: Utils.AUTHENTICATE_URL,
        //     method: 'POST',
        //     data: JSON.stringify(data)
        // })
        //     .then(response => {
        //         return response.data
        //     });
        // yield storage.setItem('token', userData.token);
        const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJQWlc0bnpRb05wTmo0TU5KSGNYV3JEdmYzM20xIiwiZXhwIjoxNjMzMTAxNzczLCJpYXQiOjE2MzMwODM3NzN9._MUUrZ5Ioh_A_hyqcp8G1WS-JBT05YNt-fe9WIp4p1qtkKpU39ZRHA8aknnuA1c8_eFqz_vIGLqrZYE-ca0Txw'
        yield storage.setItem('token', token);
        yield put(actionData.history.push('/fleet-owners/truck-status'));
    } catch (error) {
        yield put(actions.loginError(error));
    }
}
export default function* loginSaga() {
    yield takeLatest(DO_LOGIN, loginService);
    yield takeLatest(REGISTER_PROCESS, registerService)
    yield takeLatest(GET_SPECIFIC_ORG_INFO, getSpecificOrgService)
    yield takeLatest(REFRESH_TOKEN, refreshTokenService)
}

