import { INSTRUCTION_FAILURE, INSTRUCTION_HISTORY_FAILURE, INSTRUCTION_HISTORY_SERVICE, INSTRUCTION_HISTORY_SUCCESS, INSTRUCTION_RESPONSE } from './constant'

export default function InstructionReducer(prevState = {
    data: '',
    loading: false,
    error: '',
    instructionData: []
}, action) {
    switch (action.type) {
        case INSTRUCTION_RESPONSE:
            return {
                ...prevState,
                data: action.payload,
                loading: false
            }
        case INSTRUCTION_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case INSTRUCTION_HISTORY_SERVICE:
            return {
                ...prevState,
                data: action.payload,
            }
        case INSTRUCTION_HISTORY_SUCCESS:
            return {
                ...prevState,
                instructionData: action.res.instructionData
            }
        case INSTRUCTION_HISTORY_FAILURE:
            return {
                ...prevState,
                data: action.error,
            }

        default: {
            return {
                ...prevState
            }
        }
    }
}
