import { GET_VEHICLE_DETAILS,
    SET_VEHICLE_INFO,
    VEHICLE_EXISTENCE_RESPONSE,
    RESET_SERVICE,
    ADD_EDIT_TRUCK_RESPONSE,
    REMOVE_VEHICLE_RESPONSE
} from './constant'

export default function AddTruck(prevState = {
    vehicleInfo : {
            vehicleId : '',
            vehicleMake : '',
            vehicleRegistrationNum : '',
            vehicleName : '',
            tonnage :'',
            engineNum : '',
            chassisNum : '',
            vehicleModel : 'DEFAULT',
            batterySerNum1 : '',
            batterySerNum2 :'',
            tyre1:'',tyre2:'',tyre3:'',tyre4:'',tyre5:'',tyre6:'',tyre7:'',tyre8:'',tyre9:'',tyre10:'',
            tyre11:'',tyre12:'',tyre13:'',tyre14:'',tyre15:'',tyre16:'',tyre17:'',tyre18:'',
        },
    vehicleId : '',
    vehicleInfoStatus : 0,
    loading: false,
    vehiExist : '',
    serviceType : '',
    response : '',
    error:'',
}, action){
    switch(action.type) {
        case GET_VEHICLE_DETAILS:
            return {
                ...prevState,
                vehicleId : action.vehicleId,
                vehicleInfoStatus : 0,
                response: ''
            }
        case SET_VEHICLE_INFO:
            return {
                ...prevState,
                vehicleInfo : action.vehicleInfo,
                vehicleInfoStatus : 1,
                response: ''
            }
        case VEHICLE_EXISTENCE_RESPONSE:
            return {
                ...prevState,
                vehiExist : action.vehiExist,
                serviceType : VEHICLE_EXISTENCE_RESPONSE
            }
        case RESET_SERVICE:
            return {
                ...prevState,
                serviceType : null,
                response: ''
            }
        case ADD_EDIT_TRUCK_RESPONSE:
            return {
                ...prevState,
                serviceType : ADD_EDIT_TRUCK_RESPONSE,
                response : action.response,
                error : action.error,
            }
        case REMOVE_VEHICLE_RESPONSE:
            return {
                ...prevState,
                serviceType : REMOVE_VEHICLE_RESPONSE,
                response : action.response,
            }
        default : {
            return {
                ...prevState
            }
        }
    }
}

