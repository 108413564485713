import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';
// get types
function* getMaintenanceTypeService() {
    try {
        let res = yield axios.get(`getEventTypes`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getMaintenanceTypeSuccess(res));
    } catch (error) {
        yield put(actions.getMaintenanceTypeError(error));
    }
}
// get vehicles
function* getVehiclesService(action) {
    try {
        const data = yield axios({
            url: `getVehicles?orgId=${action.organizationId}`,
            method: 'POST',
        })
            .then(response => {
                return response.data.dataList
            });
        yield put(actions.getVehiclesSuccess(data));
    } catch (error) {
        yield put(actions.getVehiclesError(error));
    }
}

// create
function* createMaintenanceService(action) {
    try {
        let res = yield axios.post(`createMaintenanceRecord`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.createMaintenanceSuccess(res));
    } catch (error) {
        yield put(actions.createMaintenanceError(error));
    }
}
// update
function* updateMaintenanceService(action) {
    try {
        let res = yield axios.post(`updateMaintenanceRecord`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.updateMaintenanceSuccess(res));
    } catch (error) {
        yield put(actions.updateMaintenanceError(error));
    }
}
// view
function* getViewMaintenanceService(action) {
    try {
        let res = yield axios.get(`getAllMaintenanceRecords?orgId=${action.organizationId}`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getViewMaintenanceSuccess(res));
    } catch (error) {
        yield put(actions.getViewMaintenanceError(error));
    }
}
// delete
function* deleteMaintenanceService(action) {
    try {
        let res = yield axios.post(`deleteMaintenanceRecord`, action.id)
            .then(response => {
                return (response.data)
            });
        yield put(actions.deleteMaintenanceSuccess(res));
    } catch (error) {
        yield put(actions.deleteMaintenanceError(error));
    }
}


export default function* maintenanceSaga() {
    yield all([
        takeLatest(actionTypes.GET_MAINTENANCE_TYPE, getMaintenanceTypeService),
        takeLatest(actionTypes.GET_VEHICLELIST_MAINTENANCE, getVehiclesService),
        takeLatest(actionTypes.CREATE_MAINTENANCE, createMaintenanceService),
        takeLatest(actionTypes.UPDATE_MAINTENANCE, updateMaintenanceService),
        takeLatest(actionTypes.VIEW_MAINTENANCE, getViewMaintenanceService),
        takeLatest(actionTypes.DELETE_MAINTENANCE, deleteMaintenanceService)
    ]);

}


