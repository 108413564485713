import * as constType from './constant'

export default function truckHistory(prevState = {
    createHandover : "",
    updateHandover : "",
    deleteHandover : "",
    forceHandover : "",
    loading: true,
    error: '',
}, action) {
    switch (action.type) {
        case constType.CREATE_HANDOVER:
            return {
                ...prevState,
                loading: true
            }

        case constType.CREATE_HANDOVER_SUCCESS:
            return {
                ...prevState,
                createHandover: action.handover,
                loading: false
            }

        case constType.UPDATE_HANDOVER:
            return {
                ...prevState,
                loading: true
            }

        case constType.UPDATE_HANDOVER_SUCCESS:
            return {
                ...prevState,
                updateHandover: action.handover,
                loading: false
            }

        case constType.DELETE_HANDOVER:
            return {
                ...prevState,
                loading: true
            }

        case constType.DELETE_HANDOVER_SUCCESS:
            return {
                ...prevState,
                deleteHandover: action.handover,
                loading: false
            }

        case constType.FORCE_HANDOVER:
            return {
                ...prevState,
                loading: true
            }

        case constType.FORCE_HANDOVER_SUCCESS:
            return {
                ...prevState,
                forceHandover: action.handover,
                loading: false
            }

        case constType.CLEAR_STORE:
            return {
                ...prevState,
                createHandover : "",
                updateHandover : "",
                deleteHandover : "",
                forceHandover : "",
                loading: false
            }

        default: {
            return {
                ...prevState
            }
        }
    }
}
