import * as actionTypes from "./constant";

export default function audit(prevState = {
    loading: false,
    auditRes: [],
}, action) {
    switch (action.type) {
        case actionTypes.VEHICLE_MIGRATION_SERVICE:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.VEHICLE_MIGRATION_SUCCESS:
            return {
                ...prevState,
                auditRes: action.res,
                loading: false
            }

        default: {
            return {
                ...prevState
            }
        }
    }
}
