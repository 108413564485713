import * as actionTypes from './constant'

export default function locationService(prevState = {
    list : [],
    favoriteList: [],
    fuelHistory : [],
    create: '',
    loading: true,
    isOpen: false,
    error:'',
}, action){
    switch(action.type) {
        /*Fuel List*/
        case actionTypes.FUEL_LIST:
            return {
                ...prevState,
                loading : true
            }
        case actionTypes.FUEL_LIST_SUCCESS:
            return {
                ...prevState,
                list : action.list.data,
                loading: false
        }
        case actionTypes.FUEL_LIST_FAILURE:
            return {
                ...prevState,
                error : action.error,
                loading : false
            }

        /*Favorite List*/
        case actionTypes.FAVORITE_HISTORY:
            return {
                ...prevState,
                loading : true
            }
        case actionTypes.FAVORITE_HISTORY_SUCCESS:
            return {
                ...prevState,
                favoriteList : action.list.data.data,
                loading: false
        }
        case actionTypes.FAVORITE_HISTORY_FAILURE:
            return {
                ...prevState,
                error : action.error,
                loading : false
            }
        
        /*Fuel history*/
            case actionTypes.FUEL_HISTORY:
                return {
                    ...prevState
                }
            case actionTypes.FUEL_HISTORY_SUCCESS:
                return {
                    ...prevState,
                    fuelHistory : action.history.data.data,
            }
            case actionTypes.FUEL_HISTORY_FAILURE:
                return {
                    ...prevState,
                    error : action.error,
                }
            
            case actionTypes.COLLAPSE_STATUS:
                return {
                    ...prevState,
                    isOpen : action.status
                }

        default : {
            return {
                ...prevState
            }
        }
    }
}
