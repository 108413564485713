/* Create */
export const GET_SHARED_TRIPS    = 'GET_SHARED_TRIPS'
export const SHARED_TRIPS_SUCCESS   = 'SHARED_TRIPS_SUCCESS'
export const SHARE_TRIPS = 'SHARE_TRIPS'
export const TRIP_SHARED_SUCCESS = 'TRIP_SHARED_SUCCESS'
export const LOAD_VEHICLES = 'LOAD_VEHICLES'
export const LOAD_VEHICLES_SUCCESS = 'LOAD_VEHICLES_SUCCESS'
export const LOAD_VEHICLES_FAIL = 'LOAD_VEHICLES_FAIL'
export const RESET_ALERT = 'RESET_ALERT'
export const APPLY_VEHICLE_FILTER = 'APPLY_VEHICLE_FILTER'
export const CLEAR_VEHICLE_FILTER = 'CLEAR_VEHICLE_FILTER'
export const ADDITIONAL_LOAD_VEHICLES = 'ADDITIONAL_LOAD_VEHICLES'
/* remove email */
export const REMOVE_EMAIL_FROM_SHARE_TRIP = "REMOVE_EMAIL_FROM_SHARE_TRIP";
export const REMOVE_EMAIL_FROM_SHARE_TRIP_SUCCESS = "REMOVE_EMAIL_FROM_SHARE_TRIP_SUCCESS";
export const REMOVE_EMAIL_FROM_SHARE_TRIP_ERROR = "REMOVE_EMAIL_FROM_SHARE_TRIP_ERROR";
export const RESET_REMOVE_EMAIL ="RESET_REMOVE_EMAIL";


