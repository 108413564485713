export const GET_VEHICLE_DETAILS= 'GET_VEHICLE_DETAILS'
export const SET_VEHICLE_INFO= 'SET_VEHICLE_INFO'
export const CHECK_VEHICLE_EXIST= 'CHECK_VEHICLE_EXIST'
export const VEHICLE_EXISTENCE_RESPONSE= 'VEHICLE_EXISTENCE_RESPONSE'
export const RESET_SERVICE= 'RESET_SERVICE'
export const ADD_EDIT_TRUCK= 'ADD_EDIT_TRUCK'
export const ADD_EDIT_TRUCK_RESPONSE= 'ADD_EDIT_TRUCK_RESPONSE'
export const REMOVE_VEHICLE= 'REMOVE_VEHICLE'
export const REMOVE_VEHICLE_RESPONSE= 'REMOVE_VEHICLE_RESPONSE'

