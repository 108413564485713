import { takeLatest, put } from "redux-saga/effects";
import * as actions from './action'
import axios from '../../../utils/axios';
import * as actionTypes from "./constant";

function* getPaymentHistoryService(action) {
    let fromdate = action.fromTimeUtc;
    let toDate = action.toTimeUtc;
    if (!fromdate) {
        fromdate = ''
    }
    if (!toDate) {
        toDate = ''
    }
    try {
        var url = `viewPaymentHistory?fleetownerId=${action.uid}&fromTimeUtc=${fromdate}&toTimeUtc=${toDate}&paymentType=${action.val}`;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            let data = response.data;
            let { message, dataList } = data;
            if (message === "SUCCESS") {
                if (dataList) {
                    yield put(actions.setPaymentHistoryValue(data))
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* rechargeSaga() {
    yield takeLatest(actionTypes.GET_PAYMENT_HISTORY_VALUE, getPaymentHistoryService)
}