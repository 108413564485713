import { put, takeLatest, all } from 'redux-saga/effects';

import * as actionTypes from './constant';
import * as actions from './actions';
import axios from '../../../../utils/axios';

export default function* () {
    yield all([
        yield takeLatest(actionTypes.SUBSCRIBE_LIVE_VEHICLES_STATUS_INITIATE, startLiveVehicleStatus),
        yield takeLatest(actionTypes.ADDITIONAL_LIVE_VEHICLES_STATUS, startLiveVehicleStatus),
        yield takeLatest(actionTypes.VEHICLE_STATUS_COUNT, vehicleStatusCount),
    ]);
}

export function* startLiveVehicleStatus(actionData) {
    let cacheData, response,
        filter = actionData.data.filter,
        responseType = actionData.data.responseType;
    try {
        if (!filter) {
            response = yield axios({
                url: `getVehicleLiveStatusBasedOrg?orgId=${actionData.data.orgId}&offset=${actionData.data.offset}&recordCount=${actionData.data.recordCount}&planType=${actionData.data.type}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } else {
            response = yield axios({
                url: `getVehicleLiveStatusBasedOrg?orgId=${actionData.data.orgId}&offset=${actionData.data.offset}&recordCount=${actionData.data.recordCount}&planType=${actionData.data.type}&vehicle=${actionData.data.vehicle}&driver=${actionData.data.driverId}&fromTimeUtc=${actionData.data.fromTimeUtc}&toTimeUtc=${actionData.data.toTimeUtc}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        }
        if (JSON.stringify(response.data) !== JSON.stringify(cacheData)) {
            let responseData = response.data
            const { dataStatus, vehCountDetails, availablePings, simDurationList } = responseData.data;
            yield put(actions.notifyLiveVehiclesSubscribersSuccess(dataStatus, vehCountDetails, availablePings, simDurationList, responseType));
            cacheData = response.data;
        }
    } catch (error) {
        console.log(error)
    }
}

export function* vehicleStatusCount(actionData) {
    try {
        const data = yield axios({
            url: `getVehicleStatusCountDetails?orgId=${actionData.data}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data; 
        });
        yield put(actions.vehicleStatusCountSuccess(data));
    } catch (error) {
        console.log(error)
    }
}
