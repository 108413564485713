export const GET_SERVICE_TYPE = "GET_SERVICE_TYPE";
export const GET_SERVICE_TYPE_SUCCESS = "GET_SERVICE_TYPE_SUCCESS";
export const GET_SERVICE_TYPE_FAILURE = "GET_SERVICE_TYPE_FAILURE";
export const GET_VEHICLELIST_SERVICE = "GET_VEHICLELIST_SERVICE";
export const GET_VEHICLELIST_SERVICE_SUCCESS = "GET_VEHICLELIST_SERVICE_SUCCESS";
export const GET_VEHICLELIST_SERVICE_FAILURE = "GET_VEHICLELIST_SERVICE_FAILURE";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAILURE = "CREATE_SERVICE_FAILURE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILURE = "UPDATE_SERVICE_FAILURE";
export const VIEW_SERVICE = "VIEW_SERVICE";
export const VIEW_SERVICE_SUCCESS = "VIEW_SERVICE_SUCCESS";
export const VIEW_SERVICE_FAILURE = "VIEW_SERVICE_FAILURE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE";
export const EMPTY_RESPONSE_REMINDER = "EMPTY_RESPONSE_REMINDER";

