import { 
GET_VEHICLE_DETAILS,
SET_VEHICLE_INFO,
CHECK_VEHICLE_EXIST,
VEHICLE_EXISTENCE_RESPONSE,
RESET_SERVICE,
ADD_EDIT_TRUCK,
ADD_EDIT_TRUCK_RESPONSE,
REMOVE_VEHICLE,
REMOVE_VEHICLE_RESPONSE
} from './constant'

export function getVehicleInfo(vehicleId){
    return {
        type : GET_VEHICLE_DETAILS,
        vehicleId : vehicleId
    }
}

export function setVehicleInfo(vehicleInfo){
    return {
        type : SET_VEHICLE_INFO,
        vehicleInfo
    }
}
export function checkVehicleExistence(vehicleId){
    return {
        type : CHECK_VEHICLE_EXIST,
        vehicleId : vehicleId
    }
}
export function vehicleExistenceResponse(vehiExist){
    return {
        type : VEHICLE_EXISTENCE_RESPONSE,
        vehiExist : vehiExist
    }
}
export function resetServiceType(){
    return {
        type : RESET_SERVICE
    }
}
export function addEditTruckDetails(url,data){
    return {
        type : ADD_EDIT_TRUCK,
        url : url,
        data : data
    }
}
export function addEditTruckResponse(result,error){
    return {
        type : ADD_EDIT_TRUCK_RESPONSE,
        response : result,
        error : error
    }
}
export function removeVehicle(vehicleId){
    return {
        type : REMOVE_VEHICLE,
        vehicleId : vehicleId
    }
}
export function removeVehicleServiceResponse(response){
    return {
        type : REMOVE_VEHICLE_RESPONSE,
        response : response
    }
}





