import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../../utils/axios';

function* getDriverPerformance(id) {
    try {
        const data = yield axios({
            url: `getDriverConfigList?orgId=${id.orgId}&userId=${id.orgOwnerId}`,
            method: 'POST',
        }).then(response => {
                return response.data;
            });
        yield put(actions.driverPerformanceSuccess(data));
    } catch (error) {
        yield put(actions.driverPerformanceError(error));
    }
}

function* addAmount(id) {
    try {
        const data = yield axios({
            url: `addAmountToDriver?orgId=${id.data.orgId}&driverId=${id.data.driverId}&amount=${id.data.payAmount}&userId=${id.data.orgOwnerId}`,
            method: 'POST',
        }).then(response => {
                return response.data;
            });
        yield put(actions.addAmountSuccess(data));
    } catch (error) {
        yield put(actions.addAmountError(error));
    }
}

function* paymentAudit(id) {
    try {
        const data = yield axios({
            url: `getIncentiveAmountAudit?orgId=${id.data.orgId}&driverId=${id.data.driverId}&fromDateUtc=${id.data.fromTimeUtc}&toDateUtc=${id.data.toTimeUtc}`,
            method: 'GET',
        }).then(response => {
                return response.data;
            });
        yield put(actions.paymentAuditSuccess(data));
    } catch (error) {
        yield put(actions.paymentAuditError(error));
    }
}

export default function* driverConfigSaga() {
    yield takeLatest(constant.DRIVER_PERFORMANCE, getDriverPerformance);
    yield takeLatest(constant.ADD_AMOUNT, addAmount);
    yield takeLatest(constant.PAYMENT_AUDIT, paymentAudit);
}