// utils/API.js

import axios from "axios";
let domainUrl = "";
if(window.location.hostname==="fleetos.vamosys.com"){
   domainUrl = "https://fleetoswebapi.vamosys.com";
}else{
  domainUrl = "http://209.97.163.4:9910";
  // domainUrl = "http://68.183.224.220:9910";
}
export default axios.create({
  baseURL: domainUrl+"/basedrs/",
});