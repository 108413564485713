import * as actionTypes from "./constant";

export function getPaymentHistoryValue(uid, fromTimeUtc, toTimeUtc, val){
    return {
        type : actionTypes.GET_PAYMENT_HISTORY_VALUE,
        uid,
        fromTimeUtc,
        toTimeUtc,
        val
    }
}
export function setPaymentHistoryValue(paymentHistory){
    return {
        type : actionTypes.SET_PAYMENT_HISTORY_VALUE,
        paymentHistory
    }
}
export function resetServiceTypeValue(){
    return {
        type : actionTypes.RESET_SERVICE_VALUE
    }
}