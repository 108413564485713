import axios from 'axios'
axios.defaults.headers.post['Content-Type'] = 'application/json'
const setAuthorizationToken = (token) => {
    if(token){
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
       delete axios.defaults.headers.common['Authorization']
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json'
   // axios.defaults.headers.post['Accept'] = 'application/json'

}
export default setAuthorizationToken