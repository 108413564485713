import * as actionTypes from "./constant";

export default function maintenance(prevState = {
    vehicleList: [],
    maintenanceTypes: [],
    response: null,
    viewMaintenanceList: [],
    loading: false,
    delResponse: null
}, action) {
    switch (action.type) {
        case actionTypes.GET_MAINTENANCE_TYPE:
            return {
                ...prevState
            }
        case actionTypes.GET_MAINTENANCE_TYPE_SUCCESS:
            return {
                ...prevState,
                maintenanceTypes: action.maintenanceTypes,
            }
        case actionTypes.GET_MAINTENANCE_TYPE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.GET_VEHICLELIST_MAINTENANCE:
            return {
                ...prevState
            }
        case actionTypes.GET_VEHICLELIST_MAINTENANCE_SUCCESS:
            return {
                ...prevState,
                vehicleList: action.vehicleList
            }
        case actionTypes.GET_VEHICLELIST_MAINTENANCE_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case actionTypes.CREATE_MAINTENANCE:
            return {
                ...prevState
            }
        case actionTypes.CREATE_MAINTENANCE_SUCCESS:
            return {
                ...prevState,
                response: action.response,
                loading: false
            }
        case actionTypes.CREATE_MAINTENANCE_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
            case actionTypes.UPDATE_MAINTENANCE:
                return {
                    ...prevState
                }
            case actionTypes.UPDATE_MAINTENANCE_SUCCESS:
                return {
                    ...prevState,
                    response: action.response,
                    loading: false
                }
            case actionTypes.UPDATE_MAINTENANCE_FAILURE:
                return {
                    ...prevState,
                    error: action.error,
                    loading: false
                }
            case actionTypes.VIEW_MAINTENANCE:
                return {
                    ...prevState
                }
            case actionTypes.VIEW_MAINTENANCE_SUCCESS:
                return {
                    ...prevState,
                    viewMaintenanceList: action.viewMaintenanceList,
                    loading: false
                }
            case actionTypes.VIEW_MAINTENANCE_FAILURE:
                return {
                    ...prevState,
                    error: action.error,
                    loading: false
                }   
                case actionTypes.DELETE_MAINTENANCE:
                    return {
                        ...prevState
                    }
                case actionTypes.DELETE_MAINTENANCE_SUCCESS:
                    return {
                        ...prevState,
                        delResponse: action.response,
                        loading: false
                    }
                case actionTypes.DELETE_MAINTENANCE_FAILURE:
                    return {
                        ...prevState,
                        error: action.error,
                        loading: false
                    }    
        case actionTypes.EMPTY_RESPONSE_REMINDER:
            return {
                ...prevState,
                response: null,
                delResponse: null
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
