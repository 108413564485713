import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../../utils/axios';
import axiosWithoutToken from '../../../../utils/axiosWithoutToken';

function* getTripDetail(event) {
    try {
        const status = yield axios({
            url: `/getDocumentsForPlan?vehicleId=${event.data}`,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        yield put(actions.getTripDetailSuccess(status));

    } catch (error) {
        yield put(actions.getTripDetailError(error));
    }
}

function* getTripDetailUsingLink(action) {
    try {
        const status = yield axiosWithoutToken({
            url: `/getDocumentsForPlan?vehicleId=${action.vehicleId}&planNumber=${action.tripNum}&trigger=true`,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        yield put(actions.getTripDetailSuccess(status));

    } catch (error) {
        yield put(actions.getTripDetailError(error));
    }
}

function* getLiveData(action) {
    try {
        const status = yield axios({
            url: `/getLocationData?driverId=${action.driverId}`,
            method: 'POST',
        }) .then(response => {
                return response.data
        });
        yield put(actions.getLiveDataSuccess(status));

    } catch (error) {
        yield put(actions.getLiveDataError(error));
    }
}

export default function* TripDetailSaga() {
    yield all([
        yield takeLatest(constant.GET_TRIP_DETAIL, getTripDetail),
        yield takeLatest(constant.GET_TRIP_DETAIL_USING_LINK, getTripDetailUsingLink),
        yield takeLatest(constant.GET_LIVE_DATA, getLiveData)

    ])

}