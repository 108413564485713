/* Create */
export const LOCATION_CREATE    = 'LOCATION_CREATE'
export const LOCATION_CREATE_SUCCESS   = 'LOCATION_CREATE_SUCCESS'

/* Edit Location && View Location */
export const LOCATION_GET    = 'LOCATION_GET'
export const LOCATION_GET_SUCCESS   = 'LOCATION_GET_SUCCESS'

/* Update */
export const LOCATION_UPDATE    = 'LOCATION_UPDATE'
export const LOCATION_UPDATE_SUCCESS   = 'LOCATION_UPDATE_SUCCESS'

/* DELETE */
export const LOCATION_DELETE    = 'LOCATION_DELETE'
export const LOCATION_DELETE_SUCCESS   = 'LOCATION_DELETE_SUCCESS'

/* Status */
export const LOCATION_STATUS    = 'LOCATION_STATUS'
export const LOCATION_STATUS_SUCCESS   = 'LOCATION_STATUS_SUCCESS'

/* List */
export const LOCATION_LIST    = 'LOCATION_LIST'
export const LOCATION_LIST_SUCCESS   = 'LOCATION_LIST_SUCCESS'
export const LOCATION_FAILURE   = 'LOCATION_FAILURE'