export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE'
export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY'
export const SET_PAYMENT_HISTORY = 'SET_PAYMENT_HISTORY'
export const GET_CHART = 'GET_CHART';
export const SET_CHART = 'SET_CHART';
export const SET_CHART_ERROR = 'SET_CHART_ERROR';
export const RESET_SERVICE = 'RESET_SERVICE'
export const SHOW_EDIT_PROFILE_MODAL = 'SHOW_EDIT_PROFILE_MODAL'
export const CLOSE_EDIT_PROFILE_MODAL = 'CLOSE_EDIT_PROFILE_MODAL';
export const GET_SIM_PRICE = 'GET_SIM_PRICE';
export const GET_SIM_PRICE_SUCCESS = 'GET_SIM_PRICE_SUCCESS';
export const GET_SIM_PRICE_FAILURE = 'GET_SIM_PRICE_FAILURE';


