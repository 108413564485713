import * as actionTypes from './constant'

export default function tripDetailService(prevState = {
    list: [],
    loading: true,
    liveDataStatus: '',
    error: '',
}, action) {
    switch (action.type) {
        case actionTypes.GET_TRIP_DETAIL:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case actionTypes.TRIP_DETAIL_SUCCESS:
            return {
                ...prevState,
                list: action.data,
                loading: false
            }
        case actionTypes.TRIP_DETAIL_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case actionTypes.GET_LIVE_DATA:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case actionTypes.GET_LIVE_DATA_SUCCESS:
            return {
                ...prevState,
                liveDataStatus: action.data,
                loading: false
            }
        case actionTypes.GET_LIVE_DATA_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case actionTypes.RESET_LIVE_DATA:
            return {
                ...prevState,
                liveDataStatus: "",
                loading: false
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
