import { 
    SET_ATTENTION,
    ATTENTION_SUCCESS,
    RESET_SERVICE,
    ATTENTION_HISTORY_SUCCESS
} from './constant'

export default function AddTruck(prevState = {
    attentionData : [],
    vehicleId : '',
    serviceType : '',
    response : '',
    attnCount : 0,
    attentionHistory : [],
    error:'',
}, action){
    switch(action.type) {
        case SET_ATTENTION:
            return {
                ...prevState,
                attentionData : action.attention,
                attnCount    : action.count,
                serviceType : SET_ATTENTION
            }
        case ATTENTION_SUCCESS:
            return {
                ...prevState,
                serviceType : ATTENTION_SUCCESS,
                response : action.response,
            }
        case RESET_SERVICE:
            return {
                ...prevState,
                serviceType : null
            }
        case ATTENTION_HISTORY_SUCCESS:
            return {
                ...prevState,
                attentionHistory : action.attentionHistory,
            }
        default : {
            return {
                ...prevState
            }
        }
    }
}

