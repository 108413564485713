import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';


function* leaveOrgService(actionData) {
    if (actionData.id) {
        try {
            let response = yield axios.post(`deleteOrganizationRole?id=${actionData.id}`)
                .then(res => {
                    return (res.data);
                });
            yield put(actions.leaveOrgSuccess(response))
        } catch (error) {
            yield put(actions.leaveOrgError(error));
        }
    }

}
export default function* createOrganizationSaga() {
    yield all([
        takeLatest(actionTypes.LEAVE_ORG, leaveOrgService),
    ]);

}