import * as actionTypes from "./constant";
// get type
export function getMaintenanceTypeService(){
    return {
        type : actionTypes.GET_MAINTENANCE_TYPE       
    }
}
export function getMaintenanceTypeSuccess(maintenanceTypes){
    return {
        type : actionTypes.GET_MAINTENANCE_TYPE_SUCCESS,
        maintenanceTypes
    }
}

export function getMaintenanceTypeError(error){
    return {
        type : actionTypes.GET_MAINTENANCE_TYPE_FAILURE,
        error
    }
}

// get vehiclelist
export function getVehiclesService(organizationId) {
    return {
        type: actionTypes.GET_VEHICLELIST_MAINTENANCE,
        organizationId
    }
}
export function getVehiclesSuccess(vehicleList) {
    return {
        type: actionTypes.GET_VEHICLELIST_MAINTENANCE_SUCCESS,
        vehicleList
    }
}

export function getVehiclesError(error) {
    return {
        type: actionTypes.GET_VEHICLELIST_MAINTENANCE_FAILURE,
        error
    }
}
// create reminder
export function createMaintenanceService(payLoad) {
    return {
        type: actionTypes.CREATE_MAINTENANCE,
        payLoad
    }
}
export function createMaintenanceSuccess(response) {
    return {
        type: actionTypes.CREATE_MAINTENANCE_SUCCESS,
        response
    }
}

export function createMaintenanceError(error) {
    return {
        type: actionTypes.CREATE_MAINTENANCE_FAILURE,
        error
    }
}

// update reminder
export function updateMaintenanceService(payLoad) {
    return {
        type: actionTypes.UPDATE_MAINTENANCE,
        payLoad
    }
}
export function updateMaintenanceSuccess(response) {
    return {
        type: actionTypes.UPDATE_MAINTENANCE_SUCCESS,
        response
    }
}

export function updateMaintenanceError(error) {
    return {
        type: actionTypes.UPDATE_MAINTENANCE_FAILURE,
        error
    }
}

// view reminders
export function getViewMaintenanceService(organizationId) {
    return {
        type: actionTypes.VIEW_MAINTENANCE,
        organizationId
    }
}
export function getViewMaintenanceSuccess(viewMaintenanceList) {
    return {
        type: actionTypes.VIEW_MAINTENANCE_SUCCESS,
        viewMaintenanceList
    }
}
export function getViewMaintenanceError(error) {
    return {
        type: actionTypes.VIEW_MAINTENANCE_FAILURE,
        error
    }
}

// delete reminder

export function deleteMaintenanceService(id) {
    return {
        type: actionTypes.DELETE_MAINTENANCE,
        id
    }
}
export function deleteMaintenanceSuccess(response) {
    return {
        type: actionTypes.DELETE_MAINTENANCE_SUCCESS,
        response
    }
}

export function deleteMaintenanceError(error) {
    return {
        type: actionTypes.DELETE_MAINTENANCE_FAILURE,
        error
    }
}
// empty response

export function emptyResponse() {
    return {
        type: actionTypes.EMPTY_RESPONSE_REMINDER        
    }
}

