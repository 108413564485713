import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';


function* getDriverVerifyService(actionData) {
    try {
        let response = yield axios.get(`getDriversListForVerification?fleetOwner=${actionData.fleetOwnerId}&fromTimeUtc=${actionData.fromTimeUtc}&toTimeUtc=${actionData.toTimeUtc}`).then(res => res);
        if (response.data.message === "SUCCESS") {
            yield put(actions.setDriverVerifyList(response.data.dataList))
        }
    } catch (error) {
        console.log(error);
    }
}
function* driverVerifyService(actionData) {
    try {
        let response = yield axios.post(`driverVerificationProcess`, actionData.payLoad).then(res => res);
        let result = response.data;
        yield put(actions.driverVerifySuccess(result))
    } catch (error) {
        console.log(error);
    }
}

export default function* driverVerifySaga() {
    yield all([
        takeLatest(actionTypes.GET_DRIVER_VERIFY, getDriverVerifyService),
        takeLatest(actionTypes.DRIVER_VERIFY_SERVICE, driverVerifyService)
    ]);
}
