import * as actionTypes from "./constant";

export default function tyreService(prevState = {
    tyreData: [],
    response: null,
    viewServiceList: [],
    loading: false,
    delResponse: null,
    serviceTypeList: []
}, action) {
    switch (action.type) {
        case actionTypes.GET_TYPE_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.GET_SERVICE_TYPE_SUCCESS:
            return {
                ...prevState,
                serviceTypeList: action.serviceTypeList,
            }
        case actionTypes.GET_SERVICE_TYPE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.GET_TYRE_DETAILS:
            return {
                ...prevState
            }
        case actionTypes.GET_TYRE_DETAILS_SUCCESS:
            return {
                ...prevState,
                tyreData: action.tyreData
            }
        case actionTypes.GET_TYRE_DETAILS_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case actionTypes.CREATE_TYRE_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.CREATE_TYRE_SERVICE_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.CREATE_TYRE_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.UPDATE_TYRE_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.UPDATE_TYRE_SERVICE_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.UPDATE_TYRE_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.VIEW_TYRE_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.VIEW_TYRE_SERVICE_SUCCESS:
            return {
                ...prevState,
                viewServiceList: action.viewServiceList,
            }
        case actionTypes.VIEW_TYRE_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }

        case actionTypes.DELETE_TYRE_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.DELETE_TYRE_SERVICE_SUCCESS:
            return {
                ...prevState,
                delResponse: action.delResponse
            }
        case actionTypes.DELETE_TYRE_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.EMPTY_RESPONSE_TYRE_LOG:
            return {
                ...prevState,
                response: null,
                delResponse: null
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
