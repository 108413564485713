import {
    INSTRUCTION_SUCCESS, INSTRUCTION_FAILURE, INSTRUCTION_RESPONSE,
    INSTRUCTION_HISTORY_SERVICE, INSTRUCTION_HISTORY_SUCCESS, INSTRUCTION_HISTORY_FAILURE
} from './constant'


export function instructionSuccess(authParams) {
    return {
        type: INSTRUCTION_SUCCESS,
        payload: authParams
    }
}

export function instructionResponse(authParams) {
    return {
        type: INSTRUCTION_RESPONSE,
        payload: authParams
    }
}

export function instructionError(error) {
    return {
        type: INSTRUCTION_FAILURE,
        error
    }
}
export function instructionHistoryService(payload) {
    return {
        type: INSTRUCTION_HISTORY_SERVICE,
        payload
    }
}
export function instructionHistorySuccess(res) {
    return {
        type: INSTRUCTION_HISTORY_SUCCESS,
        res
    }
}
export function instructionHistoryError(error) {
    return {
        type: INSTRUCTION_HISTORY_FAILURE,
        error
    }
}
