import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import { GET_ATTENTION_LIST } from './constant'
import axios from '../../../utils/axios';

function* getAttentionListService(actionData) {
    try {
        const allTruckData = yield axios({
            url: `getAttentionList`,
            method: 'POST',
        })
            .then(response => {
                return response.data
            });
        yield put(actions.getAttentionListSuccess(allTruckData));
    } catch (error) {
        yield put(actions.getAttentionListError(error));
    }
}
export default function* documentSaga() {
    yield takeLatest(GET_ATTENTION_LIST, getAttentionListService)
}