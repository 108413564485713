import * as actions from './constant';

export const loadVehiclesStart = () => ({
    type: actions.LOAD_VEHICLES_START
});

export const loadVehiclesSuccess = vehicles => ({
    type: actions.LOAD_VEHICLES_SUCCESS,
    vehicles
});
export const loadDriverInitiate = () => ({
    type: actions.LOAD_DRIVERS_INITIATE
});
export const loadDriversSuccess = drivers => ({
    type : actions.LOAD_DRIVERS_SUCCESS,
    drivers
})
export const loadDriversFail = error => ({
    type : actions.LOAD_DRIVERS_FAIL,
    error
})
export const loadDriversStart = () => ({
    type : actions.LOAD_DRIVERS_START
})
//Load View plan Data 
export const loadViewPlan = () => ({
    type: actions.VIEWPLAN_START
});
export const viewPlanFail = error => ({
    type : actions.VIEWPLAN_FAIL,
    error
})
// export const viewPlanStart = () => ({
//     type : actions.VIEWPLAN_START
// })
export const viewPlanSuccess = vehicles => ({
    type: actions.LOAD_VIEWPLAN_SUCCESS,
    vehicles
});


export const loadVehiclesFail = error => ({
    type: actions.LOAD_VEHICLES_FAIL,
    error
});
export const loadViewPlans = () => ({
    type : actions.LOAD_VIEW_PLANS
})

export const subscribeLiveVehiclesStatus = subscriptionKey => ({
    type: actions.SUBSCRIBE_LIVE_VEHICLES_STATUS,
    callback: subscriptionKey
});

export const loadVehiclesInitiate = (uid) => ({
    type: actions.LOAD_VEHICLES_INITIATE,
    uid : uid
});

export const loadCurrentVehicleInitiate = (vid) => ({
    type: actions.LOAD_CURRENT_VEHICLE_INITIATE,
    vid : vid
});

export const loadCurrentVehicleSuccess = currentVehicleData => ({
    type: actions.LOAD_CURRENT_VEHICLE_SUCCESS,
    currentVehicleData
});
export const loadCurrentVehicleFail = error => ({
    type: actions.LOAD_CURRENT_VEHICLE_FAIL,
    error
});
export const loadDrivers = data => ({
    type: actions.LOAD_DRIVERS,
});
export const subscribeLiveVehiclesStatusInitiate = (uid) => ({
    type: actions.SUBSCRIBE_LIVE_VEHICLES_STATUS_INITIATE,
    uid : uid
});
export const storeDriverInitiate = (driverId1,driverId2) => ({
    type : actions.STORE_DRIVER_INITATE,
    driverId1,
    driverId2,
})
export const storeDriverStart = () => ({
    type : actions.STORE_DRIVER_START
})
export const viewPlan = () => ({
    type : actions.VIEW_PLAN
})
export const createPlan = () => ({
    type : actions.CREATE_PLAN
})
export const unsubscribeLiveVehicleStatus = index => ({
    type: actions.UNSUBSCRIBE_LIVE_VEHICLES_STATUS,
    index
})
export const setCurrentVehicleInfo = vehicleInfo => ({
    type: actions.SET_CURRENT_VEHICLE_INFO,
    vehicleInfo
})

export const simTrackingStatus = data => ({
    type : actions.SIM_TRACKING_STATUS,
    data
})

export const simTrackingStatusSuccess = statusData => ({
    type: actions.SIM_TRACKING_STATUS_SUCCESS,
    statusData
});
export const notifyLiveVehiclesSubscribersSuccess = (dataStatus,vehCountDetails, availablePings) => ({
    type: actions.NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_SUCCESS,
    dataStatus,
    vehCountDetails,
    availablePings
});