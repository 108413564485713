import {
    LOAD_PROFILE_SUCCESS,
    LOAD_PROFILE_FAILURE,
    LOAD_PROFILE,
    NOTIFICATION,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_FAILURE,
    STORE_NOTIFICATION,
    STORE_NOTIFICATION_FAILURE,
    STORE_NOTIFICATION_SUCCESS,
    KYC_PROCESS,
    KYC_PROCESS_SUCCESS,
    KYC_PROCESS_FAILURE,
    KYC_PROCESS_EMPTY,
    UPDATE_DEFAULT_ORG,
    GET_FLEET_DRIVER,
    UPLOAD_EXCEL,
    UPLOAD_EXCEL_SUCCESS_OR_FAILURE
} from './constant'


export function profileService(uid, history) {
    return {
        type: LOAD_PROFILE,
        uid: uid,
        history
    }
}

export function profileSuccess(getProfile) {
    return {
        type: LOAD_PROFILE_SUCCESS,
        getProfile
    }
}

export function profileError(error) {
    return {
        type: LOAD_PROFILE_FAILURE,
        error
    }
}

export function getFleetDriver(uid) {
    return {
        type: GET_FLEET_DRIVER,
        uid: uid
    }
}

// export function getFleetDriverSuccess(driverData) {
//     return {
//         type: SET_FLEET_DRIVER,
//         driverData
//     }
// }

export function notificationService(uid, token) {
    return {
        type: NOTIFICATION,
        uid,
        token
    }
}

export function notificationSuccess() {
    return {
        type: NOTIFICATION_SUCCESS
    }
}

export function notificationError(error) {
    return {
        type: NOTIFICATION_FAILURE,
        error
    }
}

export function storeNotifications(data) {
    return {
        type: STORE_NOTIFICATION,
        data
    }
}

export function storeNotificationsSuccess(notificationHistory) {
    return {
        type: STORE_NOTIFICATION_SUCCESS,
        notificationHistory
    }
}

export function storeNotificationsError(error) {
    return {
        type: STORE_NOTIFICATION_FAILURE,
        error
    }
}
export function kycProcessService(data) {
    return {
        type: KYC_PROCESS,
        data
    }
}

export function kycProcessSuccess(res) {
    return {
        type: KYC_PROCESS_SUCCESS,
        res
    }
}

export function kycProcessError(error) {
    return {
        type: KYC_PROCESS_FAILURE,
        error
    }
}
export function KYCEmptyRes(error) {
    return {
        type: KYC_PROCESS_EMPTY,
        error
    }
}

export function updateDefaultOrg(fleetOwner, orgId) {
    return {
        type: UPDATE_DEFAULT_ORG,
        fleetOwner,
        orgId
    }
}

export function uploadExcel(imgData, orgId) {
    return {
        type: UPLOAD_EXCEL,
        imgData, orgId
    }
}

export function uploadExcelSuccessOrFailure(res) {
    return {
        type: UPLOAD_EXCEL_SUCCESS_OR_FAILURE,
        res
    }
}


