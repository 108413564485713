import * as actionTypes from "./constant";

export function deleteExpenseService(seqNum, userId){
    return {
        type : actionTypes.DELETE_EXPENSE,
        seqNum,
        userId
    }
}
export function deleteExpenseSuccess(delExpenseRes){
    return {
        type : actionTypes.DELETE_EXPENSE_SUCCESS,
        delExpenseRes
    }
}

export function deleteExpenseError(error){
    return {
        type : actionTypes.DELETE_EXPENSE_FAILURE,
        error
    }
}

export function logService(seqNum){
    return {
        type : actionTypes.LOG_EXPENSE,
        seqNum
    }
}
export function logSuccess(logExpenseRes){
    return {
        type : actionTypes.LOG_EXPENSE_SUCCESS,
        logExpenseRes
    }
}

export function logError(error){
    return {
        type : actionTypes.LOG_EXPENSE_FAILURE,
        error
    }
}
export function emptyDelExpenseResponse(){
    return {
        type : actionTypes.EMPTY_DEL_EXPENSE_RESPONSE,
    }
}