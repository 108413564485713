import {
    GET_PLAN_INFO, DELETE_LOCATION, PLAN_SUCCESS,
    PLAN_FAILURE, EDIT_LOCATION, LOCATION_SUCCESS,
    RESET_LOCATION_STATUS, DELETE_LOCATION_SUCCESS,
    RESET_DELETE_LOCATION_STATUS, ADD_PLAN,
    ADD_PLAN_SUCCESS, ADD_PLAN_ERROR, RESET_PLAN_STATUS,
    ASSIGN_DRIVER, UPDATE_PLAN, UPDATE_PLAN_SUCCESS, RESET_PLAN_DATA,
    SEND_SIM_CONSENT_MSG, SET_ALERT_MSG, RESET_ALERT_MSG, GET_DRIVER_LIST,
    SET_DRIVER_LIST, PLAN_ABORT, PLAN_ABORT_SUCCESS, EMPTY_ABORT_STATUS, STORE_GEO_LOCATION,
    SHOW_ADD_LOCATION, HIDE_ADD_LOCATION,
    LOCATION_DATA, LOCATION_DATA_SUCCESS, LOCATION_DATA_FAILURE,DRIVER_SUCCESS
} from './constant'

export function planService(seqNo, vehicleId, createPlanAttn) {
    return {
        type: GET_PLAN_INFO,
        seqNo,
        vehicleId,
        createPlanAttn
    }
}

export function planSuccessData(planData) {
    return {
        type: PLAN_SUCCESS,
        planData
    }
}
export function driverSuccessData(driverData) {
    return {
        type: DRIVER_SUCCESS,
        driverData
    }
}
export function planDataError(error) {
    return {
        type: PLAN_FAILURE,
        error
    }
}

export function deleteLocation(seqNo) {
    return {
        type: DELETE_LOCATION,
        seqNo
    }
}
export function editLocation(locationInfo) {
    return {
        type: EDIT_LOCATION,
        locationInfo
    }
}
export function locationSuccess(data) {
    return {
        type: LOCATION_SUCCESS,
        data
    }
}

export function resetPlanDataStatus() {
    return {
        type: RESET_PLAN_DATA

    }
}
export function resetLocationStatus() {
    return {
        type: RESET_LOCATION_STATUS,
    }
}

export function deleteLocationSuccess() {
    return {
        type: DELETE_LOCATION_SUCCESS,
    }
}

export function resetDeleteLocationStatus() {
    return {
        type: RESET_DELETE_LOCATION_STATUS,
    }
}

export function addPlan(plan, assignDriverData) {
    return {
        type: ADD_PLAN,
        plan,
        assignDriverData
    }
}

export function updatePlan(plan, assignDriverData, isDriverChanged) {
    return {
        type: UPDATE_PLAN,
        plan,
        assignDriverData,
        isDriverChanged
    }
}

export function assignDriver(assignDriverData) {
    return {
        type: ASSIGN_DRIVER,
        assignDriverData
    }
}
export function addPlanSuccess() {
    return {
        type: ADD_PLAN_SUCCESS

    }
}

export function updatePlanSuccess() {
    return {
        type: UPDATE_PLAN_SUCCESS

    }
}

export function addPlanError(error) {
    return {
        type: ADD_PLAN_ERROR,
        error
    }
}

export function resetPlanStatus() {
    return {
        type: RESET_PLAN_STATUS
    }
}
export function sendConsentMsg(data) {
    return {
        type: SEND_SIM_CONSENT_MSG,
        data
    }
}

export function setAlertMsg(response) {
    return {
        type: SET_ALERT_MSG,
        response
    }
}
export function resetAlertMsg() {
    return {
        type: RESET_ALERT_MSG,
    }
}
export function getDriverList(orgOwnerId) {
    return {
        type: GET_DRIVER_LIST,
        orgOwnerId
    }
}
export function setDriverList(driverData) {
    return {
        type: SET_DRIVER_LIST,
        driverData
    }
}
export function abortPlan(data) {
    return {
        type: PLAN_ABORT,
        data
    }
}
export function abortPlanServiceSuccess(abortDataStatus) {
    return {
        type: PLAN_ABORT_SUCCESS,
        abortDataStatus
    }
}
export function emptyAbortStatus() {
    return {
        type: EMPTY_ABORT_STATUS,
    }
}

export function storeGeoLocation(data) {
    return {
        type: STORE_GEO_LOCATION,
        data
    }
}
export function openAddLocation(data) {
    return {
        type: SHOW_ADD_LOCATION,
        data
    }
}
export function closeAddLocation(data) {
    return {
        type: HIDE_ADD_LOCATION,
        data
    }
}
export function locationData(uid) {
    return {
        type: LOCATION_DATA,
        uid
    }
}
export function locationDataSuccess(locationDatas) {
    return {
        type: LOCATION_DATA_SUCCESS,
        locationDatas
    }
}

export function locationDataError(error) {
    return {
        type: LOCATION_DATA_FAILURE,
        error
    }
}



