import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';

function* getTyreInventoryService(action) {
    try {
        let res = yield axios.get(`getTyreInventory?orgId=${action.organizationId}`)
            .then(response => {
                return (response.data.data)
            });
        yield put(actions.getTyreInventorySuccess(res));

    } catch (error) {
        yield put(actions.getTyreInventoryError(error));
    }
}

// get vehicles
function* getVehiclesService(action) {
    try {
        const data = yield axios({
            url: `getVehicles?orgId=${action.organizationId}`,
            method: 'POST',
        })
            .then(response => {
                return response.data.dataList
            });
        yield put(actions.getVehiclesSuccess(data));
    } catch (error) {
        yield put(actions.getVehiclesError(error));
    }
}
// get tyre brand
function* getTyreBrandService() {
    try {
        const data = yield axios({
            url: `getTyreBrands`,
            method: 'GET',
        })
            .then(response => {
                return response.data.dataList
            });
        yield put(actions.getTyreBrandSuccess(data));
    } catch (error) {
        yield put(actions.getTyreBrandError(error));
    }
}
// add
function* addTyreService(action) {
    try {
        let res = yield axios.post(`saveTyreDetails`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.addTyreSuccess(res));
    } catch (error) {
        yield put(actions.addTyreError(error));
    }
}
// UPDATE
function* updateTyreService(action) {
    try {
        let res = yield axios.post(`updateTyreDetails`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.updateTyreSuccess(res));
    } catch (error) {
        yield put(actions.updateTyreError(error));
    }
}
// move
function* migrateTyreService(action) {
    try {
        let res = yield axios.post(`migarateTyre`, action.data)
            .then(response => {
                return (response.data)
            });
        yield put(actions.migrateTyreSuccess(res));
    } catch (error) {
        yield put(actions.migrateTyreError(error));
    }
}

// auditTyreService
function* auditTyreService(action) {
    try {
        let res = yield axios.get(`getTyreAuditHistory?tyreSerNum=${action.id}&userId=${action.uid}`)
            .then(response => {
                return (response.data.data)
            });
        yield put(actions.auditTyreSuccess(res));
    } catch (error) {
        yield put(actions.auditTyreError(error));
    }
}

// TyreHistoryService
function* getHistoryTyreService(action) {
    try {
        let res = yield axios.get(`getTyreTimelineReport?tyreSerNum=${action.serialNum}`)
            .then(response => {
                return (response.data)
            });
        yield put(actions.getHistoryTyreSuccess(res));
    } catch (error) {
        yield put(actions.getHistoryTyreError(error));
    }
}


//getTyrePositions
function* getTyrePositions() {
    try {
        let res = yield axios.get(`getTyrePositions`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getTyrePositionsSuccess(res));
    } catch (error) {
        // yield put(actions.getHistoryTyreError(error));
        console.error(error)
    }
}

export default function* tyreManagementSaga() {
    yield all([
        takeLatest(actionTypes.GET_VEHICLELIST_TYRE, getVehiclesService),
        takeLatest(actionTypes.GET_TYRE_BRAND, getTyreBrandService),
        takeLatest(actionTypes.ADD_TYRE, addTyreService),
        takeLatest(actionTypes.UPDATE_TYRE, updateTyreService),
        takeLatest(actionTypes.GET_TYRE_INVENTORY, getTyreInventoryService),
        takeLatest(actionTypes.MIGRATE_TYRE, migrateTyreService),
        takeLatest(actionTypes.GET_TYRE_AUDITS, auditTyreService),
        takeLatest(actionTypes.TYRE_HISTORY_SERVICE, getHistoryTyreService),
        takeLatest(actionTypes.GET_TYRE_POSITIONS, getTyrePositions),
    ]);
}


