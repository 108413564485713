import * as actionTypes from "./constant";

export default function expense(prevState = {
    expenseTypes: [],
    expenseHistroy: null,
    error: null,
    driverList: null,
    loading: false
}, action) {
    switch (action.type) {
        case actionTypes.GET_EXPENSE_TYPE:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.GET_EXPENSE_TYPE_SUCCESS:
            return {
                ...prevState,
                expenseTypes: action.expenseTypes,
                loading: false
            }
        case actionTypes.GET_EXPENSE_TYPE_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_EXPENSE_DRIVER:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.GET_EXPENSE_DRIVER_SUCCESS:
            return {
                ...prevState,
                driverList: action.driverList,
                loading: false
            }
        case actionTypes.GET_EXPENSE_DRIVER_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_EXPENSE_HISTORY:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.GET_EXPENSE_HISTORY_SUCCESS:
            return {
                ...prevState,
                expenseHistroy: action.expenseHistroy,
                loading: false
            }
        case actionTypes.GET_EXPENSE_HISTORY_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
