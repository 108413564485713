import * as actionTypes from "./constant";

export default function myDriver(prevState = {
    driverList: [],
    loading: true,
    error: '',
    modal: false,
    excelDownloadLink: '#',
    response: "",
    isDriverModalOpen: false,
    driverDataWithSearch: {}
}, action) {
    switch (action.type) {
        case actionTypes.GET_DRIVERS:
            return {
                ...prevState,
                loading: true,
                response: ""
            }
        case actionTypes.SET_RESPONSE_DATA:
            return {
                ...prevState,
                response: action.response,
                modal: false,
            }
        case actionTypes.SET_DRIVER_DETAILS:
            return {
                ...prevState,
                driverList: action.driverList,
                loading: false,
            }
        case actionTypes.MODAL:
            return {
                ...prevState,
                modal: action.value,
            }
        case actionTypes.EMPTY_RESPONSE:
            return {
                ...prevState,
                response: ""
            }
        case actionTypes.CHANGE_DRIVER_LIST_T:
            return {
                ...prevState,
                isDriverModalOpen: action.boo
            }

        //Excel Download
        case actionTypes.EXCEL_DOWNLOAD:
            return {
                ...prevState,
                error: action.error,
                excelDownload: true
            }
        case actionTypes.EXCEL_DOWNLOAD_SUCCESS:
            return {
                ...prevState,
                excelDownloadLink: action.data.data,
                excelDownload: false
            }
        case actionTypes.EXCEL_DOWNLOAD_FAILURE:
            return {
                ...prevState,
                error: action.error,
                excelDownload: false
            }
        case actionTypes.DRIVER_LIST:
            return {
                ...prevState,
                driverDataWithSearch: action.driverData,
                loading: false
            }
        case actionTypes.GET_DRIVERS_WITH_SEARCH_TEXT:
            return {
                ...prevState,
                loading: true
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
