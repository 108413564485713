import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../../../utils/axios';

function* addExpenseService(action) {
    try {
        let res = yield axios.post(`addExpense`, action.payLoad)
            .then(response => {
                return (response.data)
            });

        yield put(actions.addExpenseSuccess(res));
    } catch (error) {
        yield put(actions.addExpenseError(error));
    }
}
function* editExpenseService(action) {
    try {
        let res = yield axios.post(`updateExpense`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.editExpenseSuccess(res));
    } catch (error) {
        yield put(actions.editExpenseError(error));
    }
}

export default function* addExpenseSaga() {
    yield all([
        takeLatest(actionTypes.ADD_EXPENSE, addExpenseService),
        takeLatest(actionTypes.EDIT_EXPENSE, editExpenseService)
    ]);

}

