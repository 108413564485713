export const LOAD_PROFILE = 'LOAD_PROFILE'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS'
export const LOAD_PROFILE_FAILURE = 'LOAD_PROFILE_FAILURE'
export const GET_FLEET_DRIVER = "GET_FLEET_DRIVER"
export const SET_FLEET_DRIVER = "SET_FLEET_DRIVER"
export const NOTIFICATION = 'NOTIFICATION'
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_FAILURE = 'NOTIFICATION_FAILURE'
export const STORE_NOTIFICATION = 'STORE_NOTIFICATION'
export const STORE_NOTIFICATION_SUCCESS = 'STORE_NOTIFICATION_SUCCESS'
export const STORE_NOTIFICATION_FAILURE = 'STORE_NOTIFICATION_FAILURE'
export const KYC_PROCESS = 'KYC_PROCESS'
export const KYC_PROCESS_SUCCESS = 'KYC_PROCESS_SUCCESS'
export const KYC_PROCESS_FAILURE = 'KYC_PROCESS_FAILURE'
export const KYC_PROCESS_EMPTY = 'KYC_PROCESS_EMPTY'
export const UPDATE_DEFAULT_ORG = 'UPDATE_DEFAULT_ORG'
export const UPLOAD_EXCEL = 'UPLOAD_EXCEL'
export const UPLOAD_EXCEL_SUCCESS_OR_FAILURE = ' UPLOAD_EXCEL_SUCCESS_OR_FAILURE'

