import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';


function* vehicleMigrationService(actionData) {
    try {
        let response = yield axios.get(`getVehicleAuditInfo?orgId=${actionData.orgId}`).then(res => res);
        let result = response.data;
        if (result.message === "SUCCESS") {
            yield put(actions.vehicleMigrationSuccess(response.data.dataList))
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* auditSaga() {
    yield all([
        takeLatest(actionTypes.VEHICLE_MIGRATION_SERVICE, vehicleMigrationService),
    ]);
}
