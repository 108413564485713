import * as actionTypes from "./constant";

export default function tyreManagementReducer(prevState = {
    vehicleTyreList: [],
    response: null,
    loading: false,
    assignedTyreList: [],
    unassignedTyreList: [],
    auditList: [],
    tyreHistory: '',
    tyreBrandList: [],
    tyrePositions: []
}, action) {
    switch (action.type) {
        case actionTypes.GET_TYRE_INVENTORY:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.GET_TYRE_INVENTORY_SUCCESS:
            return {
                ...prevState,
                assignedTyreList: action.tyreInventoy.assignedTyreList,
                unAssignedTyreList: action.tyreInventoy.unAssignedTyreList,
                loading: false
            }
        case actionTypes.GET_TYRE_INVENTORY_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }

        case actionTypes.GET_VEHICLELIST_TYRE:
            return {
                ...prevState
            }
        case actionTypes.GET_VEHICLELIST_TYRE_SUCCESS:
            return {
                ...prevState,
                vehicleList: action.vehicleList
            }
        case actionTypes.GET_VEHICLELIST_TYRE_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case actionTypes.GET_TYRE_BRAND:
            return {
                ...prevState
            }
        case actionTypes.GET_TYRE_BRAND_SUCCESS:
            return {
                ...prevState,
                tyreBrandList: action.tyreBrandList
            }
        case actionTypes.GET_TYRE_BRAND_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case actionTypes.ADD_TYRE:
            return {
                ...prevState,
            }
        case actionTypes.ADD_TYRE_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.ADD_TYRE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.UPDATE_TYRE:
            return {
                ...prevState,
            }
        case actionTypes.UPDATE_TYRE_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.UPDATE_TYRE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.MIGRATE_TYRE:
            return {
                ...prevState,
            }
        case actionTypes.MIGRATE_TYRE_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.MIGRATE_TYRE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }

        case actionTypes.GET_TYRE_AUDITS:
            return {
                ...prevState,
            }
        case actionTypes.GET_TYRE_AUDITS_SUCCESS:
            return {
                ...prevState,
                auditList: action.auditList
            }
        case actionTypes.GET_TYRE_AUDITS_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.TYRE_HISTORY_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.TYRE_HISTORY_SUCCESS:
            return {
                ...prevState,
                tyreHistory: action.tyreHistory
            }
        case actionTypes.TYRE_HISTORY_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.EMPTY_RESPONSE_TYRE:
            return {
                ...prevState,
                response: null,
                delResponse: null
            }
        case actionTypes.GET_TYRE_POSITIONS_SUCCESS:
            return {
                ...prevState,
                tyrePositions: action.data
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
