
import * as actionTypes from "./constant";
// get type
export function getDocTypeService() {
    return {
        type: actionTypes.GET_DOC_TYPE
    }
}
export function getDocTypeSuccess(docTypes) {
    return {
        type: actionTypes.GET_DOC_TYPE_SUCCESS,
        docTypes
    }
}

export function getDocTypeError(error) {
    return {
        type: actionTypes.GET_DOC_TYPE_FAILURE,
        error
    }
}
// get vehiclelist
export function getVehiclesService(organizationId) {
    return {
        type: actionTypes.GET_VEHICLELIST_DOC,
        organizationId
    }
}
export function getVehiclesSuccess(vehicleList) {
    return {
        type: actionTypes.GET_VEHICLELIST_DOC_SUCCESS,
        vehicleList
    }
}

export function getVehiclesError(error) {
    return {
        type: actionTypes.GET_VEHICLELIST_DOC_FAILURE,
        error
    }
}

export function getDriversService(organizationId) {
    return {
        type: actionTypes.GET_DRIVERLIST_DOC,
        organizationId
    }
}
export function getDriversSuccess(driversList) {
    return {
        type: actionTypes.GET_DRIVERLIST_DOC_SUCCESS,
        driversList
    }
}

export function getDriversError(error) {
    return {
        type: actionTypes.GET_DRIVERLIST_DOC_FAILURE,
        error
    }
}
// create reminder
export function createDocService(payLoad) {
    return {
        type: actionTypes.CREATE_DOC,
        payLoad
    }
}
export function createDocSuccess(response) {
    return {
        type: actionTypes.CREATE_DOC_SUCCESS,
        response
    }
}

export function createDocError(error) {
    return {
        type: actionTypes.CREATE_DOC_FAILURE,
        error
    }
}

// update reminder
export function updateDocService(payLoad) {
    return {
        type: actionTypes.UPDATE_DOC,
        payLoad
    }
}
export function updateDocSuccess(response) {
    return {
        type: actionTypes.UPDATE_DOC_SUCCESS,
        response
    }
}

export function updateDocError(error) {
    return {
        type: actionTypes.UPDATE_DOC_FAILURE,
        error
    }
}

// view reminders
export function getViewDocService(organizationId) {
    return {
        type: actionTypes.VIEW_DOC,
        organizationId
    }
}
export function getViewDocSuccess(viewDocumentList) {
    return {
        type: actionTypes.VIEW_DOC_SUCCESS,
        viewDocumentList
    }
}
export function getViewDocError(error) {
    return {
        type: actionTypes.VIEW_DOC_FAILURE,
        error
    }
}

// delete reminder

export function deleteDocService(delDocs) {
    return {
        type: actionTypes.DELETE_DOC,
        delDocs
    }
}
export function deleteDocSuccess(delResponse) {
    return {
        type: actionTypes.DELETE_DOC_SUCCESS,
        delResponse
    }
}

export function deleteDocError(error) {
    return {
        type: actionTypes.DELETE_DOC_FAILURE,
        error
    }
}


// empty response

export function emptyResponse() {
    return {
        type: actionTypes.EMPTY_RESPONSE_REMINDER
    }
}









