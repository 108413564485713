import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../../utils/axios';

function* configCreate(data){
    try{
    const create = yield axios({
            url: `updateIncentiveOrgConfig`,
            data:data.data,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.configCreateSuccess(create));
    } catch(error){
        console.log("config created")
    }
}

function* configView(data){
    try{
    const create = yield axios({
            url: `/getOrgIncentiveConfig?orgId=${data.orgId}`,
            method: 'GET',
        })
        .then( response=>{ 
            return response.data
        });
        yield put(actions.configViewSuccess(create));
    } catch(error){
        console.log("config View")
    }
}


export default function* OrganizationConfigSaga(){
    yield takeLatest(constant.CONFIG_VIEW, configView);
    yield takeLatest(constant.CONFIG_CREATE, configCreate);
}