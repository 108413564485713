import { put, select,all, takeLatest } from 'redux-saga/effects';
// import * as Utils from '../../../utils'
import axios from '../../../utils/axios'
import * as actionTypes from './constant';
import * as actions from './actions';
import { StorageClass } from '../../../utils/StorageClass'

export default function* dashbordSaga(){
    yield all([
        yield takeLatest(actionTypes.LOAD_VEHICLES_INITIATE, loadVehicles),
        yield takeLatest(actionTypes.LOAD_CURRENT_VEHICLE_INITIATE, loadCurrentVehicle),
        yield takeLatest(actionTypes.SUBSCRIBE_LIVE_VEHICLES_STATUS_INITIATE, startLiveVehicleStatus),
        yield takeLatest(actionTypes.LOAD_DRIVERS_INITIATE, loadDrivers),
        yield takeLatest(actionTypes.LOAD_VIEW_PLANS, loadViewPlan),
        yield takeLatest(actionTypes.SIM_TRACKING_STATUS, simTrackingStatus)
    ]);
  }
  
export function* loadDrivers() {
    yield put(actions.loadDriversStart());

    try {
        let orgData = '';
        let orgOwnerId = yield select(state => state.userState.orgInfo.orgOwnerId);
        let uid = yield select(state => state.userState.uid);
        if(orgOwnerId===''||orgOwnerId===undefined){
            const storage = new StorageClass();
            const organizationId = storage.getItem('organizationId');
            orgData = yield axios.post(`getOrgInfo?orgId=${organizationId}&userId=${uid}`).then(res => res.data);
            orgOwnerId =(orgData.message==="SUCCESS")? orgData.data.orgOwnerId:'';
        }
        const driverData = yield axios.get(`getFleetDriversV2?fleetOwner=${orgOwnerId}`).then(res => res.data);
        const driverList = driverData.data;
        yield put(actions.loadDriversSuccess(driverList));
        yield put(actions.storeDriverStart(null, null));
    } catch (error) {
        yield put(actions.loadDriversFail(error));
    }
}
export function* loadViewPlan(seqNum) {
    yield put(actions.loadViewPlan());
    
    try {
        // TODO: Fetch the fleetOwner dynamically
        const result = yield axios({
            url: `getTripPlannerDataBasedOnSeqNum?seqNum=${seqNum}`,
            method: 'POST',
        });
        yield put(actions.viewPlanSuccess(result.data));
    } catch (error) {
        yield put(actions.viewPlanFail(error));
    }
}

export function* storeDrivers(driverId, driverId2) {
    yield put(actions.storeDriverStart(driverId, driverId2));
}
export function* loadVehicles(action) {
    yield put(actions.loadVehiclesStart());
    const organizationId = yield select(state => state.userState.orgInfo.organizationId);
    try {
        // TODO: Fetch the fleetOwner dynamically
        const result = yield axios({
            url: `getVehicleListBasedOnOrgnaization?orgId=${organizationId}`,
            method: 'POST',
            
        });
        yield put(actions.loadVehiclesSuccess(result.data));
    } catch (error) {
        yield put(actions.loadVehiclesFail(error));
    }
}
export function* loadCurrentVehicle(action) {
    try {
        // TODO: Fetch the fleetOwner dynamically
        const result = yield axios({
            url: `getVehicleLiveStatusBasedOnVehicle?vehicleId=${action.vid}`,
            method: 'POST',
            
        });
        yield put(actions.loadCurrentVehicleSuccess(result.data));
    } catch (error) {
        yield put(actions.loadCurrentVehicleFail(error));
    }
}

export function* startLiveVehicleStatus(action) {
    const organizationId = yield select(state => state.userState.orgInfo.organizationId);
    const response = yield axios({
        url: `getVehicleLiveStatusBasedOrg?orgId=${organizationId}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    let responseData = response.data
    const { dataStatus , vehCountDetails, availablePings } = responseData.data;
    yield put(actions.notifyLiveVehiclesSubscribersSuccess(dataStatus,vehCountDetails,availablePings));
    //yield put(actions.notifyLiveVehiclesSubscribersSuccess(response.data));
}

export function* simTrackingStatus(data) {

    try {
        const response = yield axios({
            url: `simTrackingEnableOrDisable?vehicleId=${data.data.vehicleId}&status=${data.data.status}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        yield put(actions.simTrackingStatusSuccess(response.data));
    } catch (error) {
        console.log('sim tracking status fail')
    }
}