import * as actionTypes from "./constant";

export default function service(prevState = {
    vehicleList: [],
    serviceTypes: [],
    response: null,
    viewServiceList: [],
    loading: false,
    delResponse: null
}, action) {
    switch (action.type) {
        case actionTypes.GET_SERVICE_TYPE:
            return {
                ...prevState,
            }
        case actionTypes.GET_SERVICE_TYPE_SUCCESS:
            return {
                ...prevState,
                serviceTypes: action.serviceTypes,
            }
        case actionTypes.GET_SERVICE_TYPE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.GET_VEHICLELIST_SERVICE:
            return {
                ...prevState
            }
        case actionTypes.GET_VEHICLELIST_SERVICE_SUCCESS:
            return {
                ...prevState,
                vehicleList: action.vehicleList
            }
        case actionTypes.GET_VEHICLELIST_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case actionTypes.CREATE_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.CREATE_SERVICE_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.CREATE_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.VIEW_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.VIEW_SERVICE_SUCCESS:
            return {
                ...prevState,
                viewServiceList: action.viewServiceList,
            }
        case actionTypes.VIEW_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.UPDATE_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.UPDATE_SERVICE_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.UPDATE_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.DELETE_SERVICE:
            return {
                ...prevState,
            }
        case actionTypes.DELETE_SERVICE_SUCCESS:
            return {
                ...prevState,
                delResponse: action.delResponse
            }
        case actionTypes.DELETE_SERVICE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.EMPTY_RESPONSE_REMINDER:
            return {
                ...prevState,
                response: null,
                delResponse: null
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
