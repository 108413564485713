import { LOAD_MANAGEVEHICLE_SUCCESS, LOAD_MANAGEVEHICLE_LODING, LOAD_MANAGEVEHICLE_FAILURE, ALL_VEHICLE, MOVE_VEHICLE, MOVE_VEHICLE_SUCCESS, MOVE_VEHICLE_FAILURE, MOVE_VEHICLE_EMPTY } from './constant'

export default function manageVehicle(prevState = {
    data: [],
    allvehicle: 'yes',
    loading: true,
    error: '',
    moveVehRes: ''
}, action) {
    switch (action.type) {
        case LOAD_MANAGEVEHICLE_LODING:
            return {
                ...prevState,
                error: action.error,
                loading: true,
                moveVehRes: ''
            }
        case LOAD_MANAGEVEHICLE_SUCCESS:
            return {
                ...prevState,
                data: action.truckData,
                loading: false,
                moveVehRes: ''
            }
        case LOAD_MANAGEVEHICLE_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case MOVE_VEHICLE:
            return {
                ...prevState,
                loading: true
            }
        case MOVE_VEHICLE_SUCCESS:
            return {
                ...prevState,
                moveVehRes: action.res,
                loading: false
            }
        case MOVE_VEHICLE_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: false
            }
        case MOVE_VEHICLE_EMPTY:
            return {
                ...prevState,
                moveVehRes: '',
                loading: false
            }
        case ALL_VEHICLE:
            return {
                ...prevState,
                allvehicle: action.allvehicle,
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
