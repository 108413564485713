import * as actionTypes from './constant'

/*GET DRIVER LIST*/
export function getDriver(orgOwnerId) {
    return {
        type: actionTypes.LOAD_DRIVER,
        orgOwnerId: orgOwnerId
    }
}

export function driverSuccess(driverData) {
    let driverList = [];
    let fav = driverData.data.favourite;
    let unFav = driverData.data.unfavourite;
    let data = fav.concat(unFav);
    data.forEach(element => {
        driverList.push({ label: element.name, value: element.driverId });
    });
    return {
        type: actionTypes.LOAD_DRIVER_SUCCESS,
        driverData: driverList
    }
}

export function driverError(error) {
    return {
        type: actionTypes.LOAD_DRIVER_FAILURE,
        error
    }
}

/*Driver Config Create*/
export function driverConfig(data) {
    return {
        type: actionTypes.DRIVER_CONFIG,
        data
    }
}

export function driverConfigSuccess(createData) {
    return {
        type: actionTypes.DRIVER_CONFIG_SUCCESS,
        createData
    }
}

export function driverConfigError(error) {
    return {
        type: actionTypes.DRIVER_CONFIG_FAILURE,
        error
    }
}


/*Driver incentive List*/
export function viewDriverConfig(orgId, orgOwnerId) {
    return {
        type: actionTypes.VIEW_DRIVER_CONFIG,
        orgId,
        orgOwnerId
    }
}

export function viewDriverConfigSuccess(createData) {
    return {
        type: actionTypes.VIEW_DRIVER_CONFIG_SUCCESS,
        createData
    }
}

export function viewDriverConfigError(error) {
    return {
        type: actionTypes.VIEW_DRIVER_CONFIG_FAILURE,
        error
    }
}

/*Update Driver  incentive*/
export function updateDriverConfig(data) {
    return {
        type: actionTypes.UPDATE_DRIVER_CONFIG,
        data
    }
}

export function updateDriverSuccess(updateData) {
    return {
        type: actionTypes.UPDATE_DRIVER_CONFIG_SUCCESS,
        updateData
    }
}

export function updateDriverError(error) {
    return {
        type: actionTypes.UPDATE_DRIVER_CONFIG_FAILURE,
        error
    }
}

/* Driver Redeem list */
export function driverRedeemList(data) {
    return {
        type: actionTypes.DRIVER_REDEEM,
        data
    }
}

export function driverRedeemSuccess(redeemData) {
    return {
        type: actionTypes.DRIVER_REDEEM_SUCCESS,
        redeemData
    }
}

export function driverRedeemError(error) {
    return {
        type: actionTypes.DRIVER_REDEEM_FAILURE,
        error
    }
}

/* Driver Plan list */
export function driverPlanList(data) {
    return {
        type: actionTypes.DRIVER_PLAN,
        data
    }
}

export function driverPlanSuccess(planData) {
    return {
        type: actionTypes.DRIVER_PLAN_SUCCESS,
        planData
    }
}

export function driverPlanError(error) {
    return {
        type: actionTypes.DRIVER_PLAN_FAILURE,
        error
    }
}

/* Redeem Point */
export function redeemPoint(data) {
    return {
        type: actionTypes.REDEEM_POINT,
        data
    }
}

export function redeemPointSuccess(redeemPoints) {
    return {
        type: actionTypes.REDEEM_POINT_SUCCESS,
        redeemPoints
    }
}

export function redeemPointError(error) {
    return {
        type: actionTypes.REDEEM_POINT_FAILURE,
        error
    }
}
