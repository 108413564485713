import * as actionTypes from './constant'

/* Driver Performance List */
export function driverPerformance(orgId, orgOwnerId) {
    return {
        type: actionTypes.DRIVER_PERFORMANCE,
        orgId, 
        orgOwnerId
    }
}

export function driverPerformanceSuccess(data) {
    return {
        type: actionTypes.DRIVER_PERFORMANCE_SUCCESS,
        data
    }
}

export function driverPerformanceError(error) {
    return {
        type: actionTypes.DRIVER_PERFORMANCE_FAILURE,
        error
    }
}

/* ADD AMOUNT */
export function addAmount(data) {
    return {
        type: actionTypes.ADD_AMOUNT,
        data
    }
}

export function addAmountSuccess(data) {
    return {
        type: actionTypes.ADD_AMOUNT_SUCCESS,
        data
    }
}

export function addAmountError(error) {
    return {
        type: actionTypes.ADD_AMOUNT_FAILURE,
        error
    }
}

/* PAYMENT AUDIT LIST */
export function paymentAudit(data) {
    return {
        type: actionTypes.PAYMENT_AUDIT,
        data
    }
}

export function paymentAuditSuccess(data) {
    return {
        type: actionTypes.PAYMENT_AUDIT_SUCCESS,
        data
    }
}

export function paymentAuditError(error) {
    return {
        type: actionTypes.PAYMENT_AUDIT_FAILURE,
        error
    }
}

export function creditAmount(amount) {
    return {
        type: actionTypes.CREDIT_AMOUNT,
        amount
    }
}




