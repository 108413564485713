
import * as actionTypes from "./constant";

export function vehicleMigrationService(orgId) {
    return {
        type: actionTypes.VEHICLE_MIGRATION_SERVICE,
        orgId
    }
}
export function vehicleMigrationSuccess(res) {
    return {
        type: actionTypes.VEHICLE_MIGRATION_SUCCESS,
        res
    }
}