import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../utils/axios';

/* Create location api call */
function* locationCreate(data){
    try{
    const create = yield axios({
            url: `addLocationPoints`,
            data:data.data,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });

         yield put(actions.locationCreateSuccess(create));

    } catch(error){
        console.log("location created failed")
    }
}


/* Edit  location && view location api call */
function* locationGet(data){
    try{
        
        const getData = yield axios({
            url: `edit/${data.data}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });

        yield put(actions.locationGetSuccess(getData));

    } catch(error){
        console.log("location edit or view failed")
    }
}

/* Update location api call */
function* locationUpdate(data){
    try{
    const update = yield axios({
            url: `updateLocations`,
            data:data.data,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });

         yield put(actions.locationUpdateSuccess(update));

    } catch(error){
        console.log("location update failed")
    }
}

/* Delete location api call */
function* locationDelete(data){
    try{

    const  deleteData = yield axios({
            url: `removeLocations/${data.data}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });

        yield put(actions.locationDeleteSuccess(deleteData));

    } catch(error){
        console.log("location  Delete failed")
    }
}

/* location List api call */
function* locationList(data){
    try{
    const create = yield axios({
            url: `viewLocationHistory?userId=${data.uid}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });

         yield put(actions.locationListSuccess(create));

    } catch(error){
        console.log("location created failed")
    }
}

/* location List api call */
function* locationStatus(data){
    try{
    const status = yield axios({
            url: `changeLocationStatus?id=${data.data.Id}&status=${data.data.status}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });

         yield put(actions.locationStatusSuccess(status));

    } catch(error){
        console.log("location status failed")
    }
}

export default function* MyLocationSaga(){
    yield takeLatest(constant.LOCATION_CREATE, locationCreate)
    yield takeLatest(constant.LOCATION_GET, locationGet)
    yield takeLatest(constant.LOCATION_UPDATE, locationUpdate)
    yield takeLatest(constant.LOCATION_DELETE, locationDelete)
    yield takeLatest(constant.LOCATION_LIST, locationList)
    yield takeLatest(constant.LOCATION_STATUS, locationStatus)

}