import { 
    GET_ATTENTION,
    SET_ATTENTION,
    ATTENTION_RESPONSE,
    ATTENTION_SUCCESS,
    RESET_SERVICE,
    GET_ATTENTION_HISTORY,
    ATTENTION_HISTORY_SUCCESS
    } from './constant'
    
    export function getAttention(vehicleId){
        return {
            type : GET_ATTENTION,
            vehicleId : vehicleId
        }
    }
    
    export function setAttention(attention,count){
        return {
            type : SET_ATTENTION,
            attention,
            count
        }
    }
    export function responseToAttention(resData){
        return {
            type : ATTENTION_RESPONSE,
            resData : resData
        }
    }
    export function attentionResponseMsg(response){
        return {
            type : ATTENTION_SUCCESS,
            response : response
        }
    }
    export function resetServiceType(){
        return {
            type : RESET_SERVICE
        }
    }
    export function getAttentionHistory(vehicleId){
        return {
            type : GET_ATTENTION_HISTORY,
            vehicleId
        }
    }
    export function attentionHistorySuccess(attentionHistory){
        return {
            type : ATTENTION_HISTORY_SUCCESS,
            attentionHistory
        }
    }
    
    
    
    
    
    