export const TRIPS_COMPLETED_CHART           = 'TRIPS_COMPLETED_CHART'
export const TRIPS_COMPLETED_CHART_SUCCESS   = 'TRIPS_COMPLETED_CHART_SUCCESS'
export const TRIPS_COMPLETED_CHART_FAILURE   = 'TRIPS_COMPLETED_CHART_FAILURE'

export const GET_CHART_DRIVER_LIST                 = 'GET_CHART_DRIVER_LIST'
export const GET_CHART_DRIVER_LIST_SUCCESS         = 'GET_CHART_DRIVER_LIST_SUCCESS'
export const GET_CHART_DRIVER_LIST_FAILURE         = 'GET_CHART_DRIVER_LIST_FAILURE'

export const DRIVER_COMPLETED_CHART          =  'DRIVER_COMPLETED_CHART'
export const DRIVER_COMPLETED_CHART_SUCCESS  =  'DRIVER_COMPLETED_CHART_SUCCESS'
export const DRIVER_COMPLETED_CHART_FAILURE  =  'DRIVER_COMPLETED_CHART_FAILURE'

export const DASHBOARD_CHART          =  'DASHBOARD_CHART'
export const DASHBOARD_CHART_SUCCESS  =  'DASHBOARD_CHART_SUCCESS'
export const DASHBOARD_CHART_FAILURE  =  'DASHBOARD_CHART_FAILURE'