import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';

function* getExpenseFleetReportsService(action) {
    try {
        let res = yield axios.get(`getExpenseFleetReports?orgId=${action.data.orgId}&fromTimeUtc=${action.data.fromTimeUtc}&toTimeUtc=${action.data.toTimeUtc}&filter=${action.data.filter}`)
            .then(response => {
                return (response.data)
            });
        yield put(actions.getExpenseFleetReportsSuccess(res));
    } catch (error) {
        yield put(actions.getExpenseFleetReportsError(error));
    }
}
export default function* expenseReportsSaga() {
    yield all([
        takeLatest(actionTypes.GET_EXPENSE_FLEET_REPORTS, getExpenseFleetReportsService),
    ]);

}


