
import * as actionTypes from "./constant";

export function migratedVehHistoryService() {
    return {
        type: actionTypes.MIGRATED_VEH_HISTORY_SERVICE
    }
}
export function migratedVehHistorySuccess(res) {
    return {
        type: actionTypes.MIGRATED_VEH_HISTORY_SUCCESS,
        res
    }
}
export function resetMigratedVeh() {
    return {
        type: actionTypes.RESET_MIGRATED_VEH
    }
}
export function migratedVehApproveService(data) {
    return {
        type: actionTypes.MIGRATED_VEH_APPROVE_SERVICE,
        data
    }
}
export function migratedVehApproveSuccess(res) {
    return {
        type: actionTypes.MIGRATED_VEH_APPROVE_SUCCESS,
        res
    }
}
export function migratedVehApproveError(error) {
    return {
        type: actionTypes.MIGRATED_VEH_APPROVE_FAILURE,
        error
    }
}
export function migratedVehApproveEmptyService() {
    return {
        type: actionTypes.MIGRATED_VEH_APPROVE_EMPTY
    }
}