import {
    GET_PLAN_INFO, PLAN_SUCCESS, PLAN_FAILURE, EDIT_LOCATION,
    LOCATION_SUCCESS, RESET_LOCATION_STATUS, RESET_DELETE_LOCATION_STATUS,
    DELETE_LOCATION_SUCCESS, ADD_PLAN, ADD_PLAN_SUCCESS, ADD_PLAN_ERROR,
    RESET_PLAN_STATUS, UPDATE_PLAN_SUCCESS, RESET_PLAN_DATA, SET_ALERT_MSG,
    RESET_ALERT_MSG, SET_DRIVER_LIST, PLAN_ABORT_SUCCESS, EMPTY_ABORT_STATUS,
    SHOW_ADD_LOCATION, HIDE_ADD_LOCATION,
    LOCATION_DATA, LOCATION_DATA_SUCCESS,DRIVER_SUCCESS
} from './constant'

export default function planReducer(prevState = {
    data: [],
    planData: {},
    loading: true,
    editLocationStatus: false,
    planDataStatus: false,
    deleteLocationStatus: false,
    updatePlanStatus: false,
    addPlanStatus: false,
    updated: true,
    alertMsg: '',
    abortDataStatus: '',
    error: '',
    isAddLoc: false,
    caption: [],
    locationData: {}
}, action) {
    switch (action.type) {
        case GET_PLAN_INFO:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }
        case EDIT_LOCATION:
            return {
                ...prevState,
                editLocationStatus: false,
                loading: true
            }

        case LOCATION_SUCCESS:
            return {
                ...prevState,
                editLocationStatus: true,
                planData: {
                    ...prevState.planData,
                    loadingLocationList: action.data.dataList,
                    unLoadingLocationList: action.data.dataUnloadingList,
                },
                loading: false
            }
        case RESET_LOCATION_STATUS:
            return {
                ...prevState,
                editLocationStatus: false,
            }

        case PLAN_SUCCESS:
            return {
                ...prevState,
                planData: action.planData,
                loading: false,
                planDataStatus: true
            }
        case DRIVER_SUCCESS:
            return {
                ...prevState,
                data: action.driverData,
                loading: false,
            }
        case RESET_PLAN_DATA:
            return {
                ...prevState,
                planDataStatus: false
            }
        case PLAN_FAILURE:
            return {
                ...prevState,
                error: action.error,
                loading: true
            }

        case DELETE_LOCATION_SUCCESS:
            return {
                ...prevState,
                deleteLocationStatus: true
            }
        case RESET_DELETE_LOCATION_STATUS:
            return {
                ...prevState,
                deleteLocationStatus: false,

            }
        case ADD_PLAN:
            return {
                ...prevState,
                loading: true

            }
        case ADD_PLAN_SUCCESS:
            return {
                ...prevState,
                loading: false,
                addPlanStatus: true

            }
        case ADD_PLAN_ERROR:
            return {
                ...prevState,
                error: action.error,
                loading: false

            }
        case UPDATE_PLAN_SUCCESS:
            return {
                ...prevState,
                loading: false,
                updatePlanStatus: true

            }
        case RESET_PLAN_STATUS:
            return {
                ...prevState,
                updatePlanStatus: false,
                addPlanStatus: false

            }
        case SET_ALERT_MSG:
            return {
                ...prevState,
                alertMsg: action.response,
            }
        case RESET_ALERT_MSG:
            return {
                ...prevState,
                alertMsg: '',
            }
        case SET_DRIVER_LIST:
            return {
                ...prevState,
                data: action.driverData,
            }
        case PLAN_ABORT_SUCCESS:
            return {
                ...prevState,
                abortDataStatus: action.abortDataStatus,
            }
        case EMPTY_ABORT_STATUS:
            return {
                ...prevState,
                abortDataStatus: '',
            }
        case SHOW_ADD_LOCATION:
            return {
                ...prevState,
                isAddLoc: true,
                caption: action.data
            }
        case HIDE_ADD_LOCATION:
            return {
                ...prevState,
                isAddLoc: false,
                caption: action.data
            }
        case LOCATION_DATA:
            return {
                ...prevState,
            }
        case LOCATION_DATA_SUCCESS:
            return {
                ...prevState,
                locationData: action.locationDatas
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
