
import * as actionTypes from "./constant";

// get type
export function getTypeService(){
    return {
        type : actionTypes.GET_TYPE_SERVICE
    }
}
export function getTypeSuccess(serviceTypeList){
    return {
        type : actionTypes.GET_SERVICE_TYPE_SUCCESS,
        serviceTypeList
    }
}

export function getTypeError(error){
    return {
        type : actionTypes.GET_SERVICE_TYPE_FAILURE,
        error
    }
}

//get tyre details
export function getTyreDetailService(organizationId, uid){
    return {
        type : actionTypes.GET_TYRE_DETAILS,
        organizationId,
        uid      
    }
}
export function getTyreDetailSuccess(tyreData){
    return {
        type : actionTypes.GET_TYRE_DETAILS_SUCCESS,
        tyreData
    }
}

export function getTyreDetailError(error){
    return {
        type : actionTypes.GET_TYRE_DETAILS_FAILURE,
        error
    }
}

// create tyre
export function createTyreService(payLoad) {
    return {
        type: actionTypes.CREATE_TYRE_SERVICE,
        payLoad
    }
}
export function createTyreSuccess(response) {
    return {
        type: actionTypes.CREATE_TYRE_SERVICE_SUCCESS,
        response
    }
}

export function createTyreError(error) {
    return {
        type: actionTypes.CREATE_TYRE_SERVICE_FAILURE,
        error
    }
}
// update tyre
export function updateTyreService(payLoad) {
    return {
        type: actionTypes.UPDATE_TYRE_SERVICE,
        payLoad
    }
}
export function updateTyreServiceSuccess(response) {
    return {
        type: actionTypes.UPDATE_TYRE_SERVICE_SUCCESS,
        response
    }
}

export function updateTyreServiceError(error) {
    return {
        type: actionTypes.UPDATE_TYRE_SERVICE_FAILURE,
        error
    }
}
// // view tyre service
export function getViewTyreService(organizationId, uid) {
    return {
        type: actionTypes.VIEW_TYRE_SERVICE,
        organizationId,
        uid
    }
}
export function getViewTyreSuccess(viewServiceList) {
    return {
        type: actionTypes.VIEW_TYRE_SERVICE_SUCCESS,
        viewServiceList
    }
}
export function getViewTyreError(error) {
    return {
        type: actionTypes.VIEW_TYRE_SERVICE_FAILURE,
        error
    }
}

// delete 

export function deleteTyreService(data, userId) {
    return {
        type: actionTypes.DELETE_TYRE_SERVICE,
        data,
        userId
    }
}
export function deleteTyreSuccess(delResponse) {
    return {
        type: actionTypes.DELETE_TYRE_SERVICE_SUCCESS,
        delResponse
    }
}

export function deleteTyreError(error) {
    return {
        type: actionTypes.DELETE_TYRE_SERVICE_SUCCESS,
        error
    }
}

//empty response

export function emptyResponse() {
    return {
        type: actionTypes.EMPTY_RESPONSE_TYRE_LOG       
    }
}





