import * as actionTypes from "./constant";

export default function docRenewal(prevState = {
    vehicleList: [],
    docTypes: [],
    response: null,
    delResponse: null,
    viewDocumentList: []
}, action) {
    switch (action.type) {
        case actionTypes.GET_DOC_TYPE:
            return {
                ...prevState,
            }
        case actionTypes.GET_DOC_TYPE_SUCCESS:
            return {
                ...prevState,
                docTypes: action.docTypes,
            }
        case actionTypes.GET_DOC_TYPE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.GET_VEHICLELIST_DOC:
            return {
                ...prevState
            }
        case actionTypes.GET_VEHICLELIST_DOC_SUCCESS:
            return {
                ...prevState,
                vehicleList: action.vehicleList
            }
        case actionTypes.GET_VEHICLELIST_DOC_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case actionTypes.GET_DRIVERLIST_DOC:
            return {
                ...prevState
            }
        case actionTypes.GET_DRIVERLIST_DOC_SUCCESS:
            return {
                ...prevState,
                driversList: action.driversList
            }
        case actionTypes.GET_DRIVERLIST_DOC_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case actionTypes.CREATE_DOC:
            return {
                ...prevState,
            }
        case actionTypes.CREATE_DOC_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.CREATE_DOC_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.VIEW_DOC:
            return {
                ...prevState,
            }
        case actionTypes.VIEW_DOC_SUCCESS:
            return {
                ...prevState,
                viewDocumentList: action.viewDocumentList.dataList,
            }
        case actionTypes.VIEW_DOC_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.UPDATE_DOC:
            return {
                ...prevState,
            }
        case actionTypes.UPDATE_DOC_SUCCESS:
            return {
                ...prevState,
                response: action.response,
            }
        case actionTypes.UPDATE_DOC_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.DELETE_DOC:
            return {
                ...prevState,
            }
        case actionTypes.DELETE_DOC_SUCCESS:
            return {
                ...prevState,
                delResponse: action.delResponse
            }
        case actionTypes.DELETE_DOC_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.EMPTY_RESPONSE_REMINDER:
            return {
                ...prevState,
                response: null,
                delResponse: null
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
