import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as actionTypes from "./constant";
import axios from '../../../utils/axios';

let successMsg = {
    "message": "SUCCESS",
    "data": "Message initiated to driver number",
    "errorDesc": null,
    "errorCode": 100
}

function* getDriverDetailsService(actionData) {
    try {
        let response = yield axios.get(`getFleetDrivers?fleetOwner=${actionData.orgOwnerId}`).then(res => res);
        let result = response.data;
        if (result.message === "SUCCESS") {
            let { unfavourite, favourite } = result.data;
            let unfavouriteList = unfavourite.map((driver) => {
                if (driver.simConsentProcess === 2) {
                    driver = { ...driver, simConsentProcess: 'Enabled' }
                } else if (driver.simConsentProcess === 1) {
                    driver = { ...driver, simConsentProcess: 'Verify' }
                } else {
                    driver = { ...driver, simConsentProcess: 'Initiate' }
                }
                return driver;
            });
            let favouriteList = favourite.map((driver) => {
                if (driver.simConsentProcess === 2) {
                    driver = { ...driver, simConsentProcess: 'Enabled' }
                } else if (driver.simConsentProcess === 1) {
                    driver = { ...driver, simConsentProcess: 'Verify' }
                } else {
                    driver = { ...driver, simConsentProcess: 'Initiate' }
                }
                return driver;
            });
            const modifiedDriverList = { 'unfavourite': unfavouriteList, 'favourite': favouriteList }
            yield put(actions.setDriverDetails(modifiedDriverList))
        }
    } catch (error) {
        console.log(error);
    }
}
function* addDriverService(actionData) {
    try {
        let response = yield axios.post('addFleetDriver', actionData.driverDetails).then(res => res);
        let responseData = response.data;
        yield put(actions.setResponseData(responseData))
    } catch (error) {
        console.log(error);
    }
}
function* editDriverService(actionData) {
    try {
        let response = yield axios.post('editFleetDriver', actionData.driverDetails).then(res => res);
        let responseData = response.data;
        yield put(actions.setResponseData(responseData))
    } catch (error) {
        console.log(error);
    }
}
function* removeDriverService(actionData) {
    try {
        let response = yield axios.post(`removeFleetDriver?id=${actionData.id}&orgId=${actionData.orgId}`).then(res => res);
        let responseData = response.data;
        yield put(actions.setResponseData(responseData))
    } catch (error) {
        console.log(error);
    }
}
function* inviteDriverService(actionData) {
    try {
        let response = yield axios.post(`inviteDriver?id=${actionData.id}`).then(res => res);
        let responseData = response.data;
        yield put(actions.setResponseData(responseData))
    } catch (error) {
        console.log(error);
    }
}
function* updateFavouriteStatusService(actionData) {
    try {
        let response = yield axios.post('addFavDrivers', actionData.data).then(res => res);
        let responseData = response.data;
        if (actionData.data[0].favStatus) {
            responseData = { ...responseData, data: 'Favorite driver added successfully' }
        } else {
            responseData = { ...responseData, data: 'Favorite driver removed successfully' }
        }
        yield put(actions.setResponseData(responseData))
    } catch (error) {
        console.log(error);
    }
}
function* sendConsentMsgService(actionData) {
    const { driverId, userId } = actionData.data;
    try {
        yield axios.post(`sendConsentMsg?driverId=${driverId}&userId=${userId}`).then(res => res);
        yield put(actions.setResponseData(successMsg))
    } catch (error) {
        console.log(error);
    }
}

function* getExcelDownload(id) {
    try {
        const data = yield axios({
            url: `getDriverListExcel?accountUserId=${id.accountUserId}`,
            method: 'GET',
        })
            .then(response => {
                return response.data;
            });
        yield put(actions.getExcelDownloadSuccess(data));
    } catch (error) {
        yield put(actions.getExcelDownloadError(error));
    }
}

function* getDriversWithSearchText(actionData) {
    try {
        let response = yield axios.get(`getFleetDriversV4?fleetOwner=${actionData.orgOwnerId}&searchText=${actionData.searchText}`).then(res => res);
        let result = response.data;
        if (result.message === "SUCCESS") {
            yield put(actions.setDriverList(result.data))
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* driverSaga() {
    yield all([
        takeLatest(actionTypes.GET_DRIVERS, getDriverDetailsService),
        takeLatest(actionTypes.REFRESH_DRIVER, getDriverDetailsService),
        takeLatest(actionTypes.ADD_DRIVER, addDriverService),
        takeLatest(actionTypes.EDIT_DRIVER, editDriverService),
        takeLatest(actionTypes.REMOVE_DRIVER, removeDriverService),
        takeLatest(actionTypes.INVITE_DRIVER, inviteDriverService),
        takeLatest(actionTypes.UPDATE_FAVOURITE_STATUS, updateFavouriteStatusService),
        takeLatest(actionTypes.SEND_CONSENT_MSG, sendConsentMsgService),
        takeLatest(actionTypes.EXCEL_DOWNLOAD, getExcelDownload),
        takeLatest(actionTypes.GET_DRIVERS_WITH_SEARCH_TEXT, getDriversWithSearchText)

    ]);

}