import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
// import FreshChat from 'react-freshchat';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;


// Pages
const Register = React.lazy(() => import('./containers/Register'));
const ForgetPassword = React.lazy(() => import('./containers/ForgetPassword'));
const AppLayout = React.lazy(() => import('./components/Layouts/appLayout/layout'));
//const GuestLayout = React.lazy(() => import('./components/Layouts/guestLayout/layout'));
const EmailComponent = React.lazy(() => import('./containers/Register/EmailComponent'));
const PrivateRoute =  React.lazy(() => import('./privateRoute'));
const SignIn = React.lazy(() => import('./containers/signIn'));
const tripDetail = React.lazy(() => import('./containers/fleetOwners/dashboard/tripDetail/tripData'));
class App extends Component {

  render() {
    return (
      <>
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <SignIn {...props}/>} />
            <Route exact path="/signIn" name="Login Page" render={props => <SignIn {...props}/>} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
            <Route exact path="/forgotPassword" name="Forgot Password" component={ForgetPassword} />
            <Route exact path="/usermang" name="Login Page" component={EmailComponent}/>
            <Route exact path="/trip" name="Login Page" component={tripDetail}/>
            <PrivateRoute path="/" name="Home" component={AppLayout} />
          </Switch>
        </React.Suspense>
      </Router>
      {/* <FreshChat
        token={process.env.REACT_APP_FRESHCHAT_KEY}
        email = ""
          first_name="..."
          onInit={widget => {
				widget.user.setProperties({
					email: '',
					first_name: '',
					last_name:'',
					phone: '',
				}) 
          }}
        /> */}
    </>
    );
  }
}

export default App;
