import * as actionTypes from "./constant";

export default function addExpense(prevState = {
    addSuccessData: null,
    editSuccessData: null,
    error: null
}, action) {
    switch (action.type) {
        case actionTypes.ADD_EXPENSE:
            return {
                ...prevState,
                data: action.payLoad
            }
        case actionTypes.ADD_EXPENSE_SUCCESS:
            return {
                ...prevState,
                addSuccessData: action.addSuccessData
            }
        case actionTypes.ADD_EXPENSE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.EDIT_EXPENSE:
            return {
                ...prevState,
                data: action.payLoad
            }
        case actionTypes.EDIT_EXPENSE_SUCCESS:
            return {
                ...prevState,
                editSuccessData: action.editSuccessData
            }
        case actionTypes.EDIT_EXPENSE_FAILURE:
            return {
                ...prevState,
                error: action.error,
            }
        case actionTypes.EMPTY_EXPENSE_RESPONSE:
            return {
                ...prevState,
                addSuccessData: "",
                editSuccessData: '',
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
