import { takeLatest, put } from "redux-saga/effects";
import * as actions from './action'
import axios from '../../../utils/axios';
import * as actionTypes from "./constant";

function* getPaymentHistoryService(action) {
    let fromdate = action.fromTimeUtc;
    let toDate = action.toTimeUtc;
    if (!fromdate) {
        fromdate = ''
    }
    if (!toDate) {
        toDate = ''
    }
    try {
        var url = `viewPaymentHistory?fleetownerId=${action.uid}&fromTimeUtc=${fromdate}&toTimeUtc=${toDate}&paymentType=${action.val}`;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            let data = response.data;
            let { message, dataList } = data;
            if (message === "SUCCESS") {
                if (dataList) {
                    yield put(actions.setPaymentHistory(data))
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
}

function* getChartService(action) {
    try {
        var url = `getPingHistoryChart?fromDate=${action.chartDetail.startDate}&fleetownerId=${action.chartDetail.uid}&toDate=${action.chartDetail.endDate}`;
        var response = yield axios.post(url).then(res => res);
        if (response) {
            var data = response.data;
        }
        yield put(actions.setChart(data))
    } catch (error) {
        yield put(actions.setChartError(error))
    }
}

function* getSimPriceList() {
    try {
        var url = `getSimPriceList`;
        var response = yield axios.get(url).then(res => res);
        if (response) {
            var data = response.data;
        }
        yield put(actions.getSimPriceSuccess(data))
    } catch (error) {
        yield put(actions.getSimPriceError(error))
    }
}

export default function* simUsageSaga() {
    yield takeLatest(actionTypes.GET_PAYMENT_HISTORY, getPaymentHistoryService)
    yield takeLatest(actionTypes.GET_CHART, getChartService)
    yield takeLatest(actionTypes.GET_SIM_PRICE, getSimPriceList)

}