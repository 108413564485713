export const GET_TYRE_INVENTORY = "GET_TYRE_INVENTORY";
export const GET_TYRE_INVENTORY_SUCCESS = "GET_TYRE_INVENTORY_SUCCESS";
export const GET_TYRE_INVENTORY_FAILURE = "GET_TYRE_INVENTORY_FAILURE";

export const GET_VEHICLELIST_TYRE = "GET_VEHICLELIST_TYRE";
export const GET_VEHICLELIST_TYRE_SUCCESS = "GET_VEHICLELIST_TYRE_SUCCESS";
export const GET_VEHICLELIST_TYRE_FAILURE = "GET_VEHICLELIST_TYRE_FAILURE";

export const GET_TYRE_BRAND = "GET_TYRE_BRAND";
export const GET_TYRE_BRAND_SUCCESS = "GET_TYRE_BRAND_SUCCESS";
export const GET_TYRE_BRAND_FAILURE = "GET_TYRE_BRAND_FAILURE";

export const ADD_TYRE = "ADD_TYRE";
export const ADD_TYRE_SUCCESS = "ADD_TYRE_SUCCESS";
export const ADD_TYRE_FAILURE = "ADD_TYRE_FAILURE";

export const UPDATE_TYRE = "UPDATE_TYRE";
export const UPDATE_TYRE_SUCCESS = "UPDATE_TYRE_SUCCESS";
export const UPDATE_TYRE_FAILURE = "UPDATE_TYRE_FAILURE";

export const MIGRATE_TYRE = "MIGRATE_TYRE";
export const MIGRATE_TYRE_SUCCESS = "MIGRATE_TYRE_SUCCESS";
export const MIGRATE_TYRE_FAILURE = "MIGRATE_TYRE_FAILURE";

export const GET_TYRE_AUDITS = "GET_TYRE_AUDITS";
export const GET_TYRE_AUDITS_SUCCESS = "GET_TYRE_AUDITS_SUCCESS";
export const GET_TYRE_AUDITS_FAILURE = "GET_TYRE_AUDITS_FAILURE";

export const TYRE_HISTORY_SERVICE = "TYRE_HISTORY_SERVICE";
export const TYRE_HISTORY_SUCCESS = "TYRE_HISTORY_SUCCESS";
export const TYRE_HISTORY_FAILURE = "TYRE_HISTORY_FAILURE";

export const EMPTY_RESPONSE_TYRE = "EMPTY_RESPONSE_TYRE";


export const GET_TYRE_POSITIONS = "GET_TYRE_POSITIONS";
export const GET_TYRE_POSITIONS_SUCCESS = "GET_TYRE_POSITIONS_SUCCESS";