import * as actions from './constant';

export const notifyLiveVehiclesSubscribersSuccess = (dataStatus,vehCountDetails, availablePings,simDurationList,responseType) => ({
    type: actions.NOTIFY_LIVE_VEHICLES_SUBSCRIBERS_SUCCESS,
    dataStatus,
    vehCountDetails,
    availablePings,
    simDurationList,
    responseType
});

export const subscribeLiveVehiclesStatus = subscriptionKey => ({
    type: actions.SUBSCRIBE_LIVE_VEHICLES_STATUS,
    callback: subscriptionKey
});

export const loadVehiclesInitiate = data => ({
    type: actions.LOAD_VEHICLES_INITIATE,
    data
});
export const vehicleStatusCount = data => ({
    type: actions.VEHICLE_STATUS_COUNT,
    data
});
export const vehicleStatusCountSuccess = data => ({
    type: actions.VEHICLE_STATUS_COUNT_SUCCESS,
    data
});
export const subscribeLiveVehiclesStatusInitiate = data => ({
    type: actions.SUBSCRIBE_LIVE_VEHICLES_STATUS_INITIATE,
    data
});

export const unsubscribeLiveVehicleStatus = index => ({
    type: actions.UNSUBSCRIBE_LIVE_VEHICLES_STATUS,
    index
});

export const additionalVehiclesStatusInitiate = data => ({
    type: actions.ADDITIONAL_LIVE_VEHICLES_STATUS,
    data
});
