import * as actionTypes from "./constant";

// create reminder
export function createSCRService(payLoad) {
    return {
        type: actionTypes.CREATE_SCR,
        payLoad
    }
}
export function createSCRSuccess(response) {
    return {
        type: actionTypes.CREATE_SCR_SUCCESS,
        response
    }
}

export function createSCRError(error) {
    return {
        type: actionTypes.CREATE_SCR_FAILURE,
        error
    }
}

// update reminder
export function updateSCRService(payLoad) {
    return {
        type: actionTypes.UPDATE_SCR,
        payLoad
    }
}
export function updateSCRSuccess(response) {
    return {
        type: actionTypes.UPDATE_SCR_SUCCESS,
        response
    }
}

export function updateSCRError(error) {
    return {
        type: actionTypes.UPDATE_SCR_FAILURE,
        error
    }
}

// view reminders
export function getViewSCRService(userId) {
    return {
        type: actionTypes.VIEW_SCR,
        userId
    }
}
export function getViewSCRSuccess(scrList) {
    return {
        type: actionTypes.VIEW_SCR_SUCCESS,
        scrList
    }
}
export function getViewSCRError(error) {
    return {
        type: actionTypes.VIEW_SCR_FAILURE,
        error
    }
}

// delete reminder

export function deleteSCRService(id) {
    return {
        type: actionTypes.DELETE_SCR,
        id
    }
}
export function deleteSCRSuccess(response) {
    return {
        type: actionTypes.DELETE_SCR_SUCCESS,
        response
    }
}

export function deleteSCRError(error) {
    return {
        type: actionTypes.DELETE_SCR_FAILURE,
        error
    }
}
// empty response

export function emptyResponse() {
    return {
        type: actionTypes.EMPTY_RESPONSE_REMINDER        
    }
}

