import { put,takeLatest } from 'redux-saga/effects'
import * as actions from './action'
import * as constant from './constant'
import axios from '../../../utils/axios';

/* Get Shared Trips api call */
function* getSharedTrips(data){
    try{
    const invites = yield axios({
            url: `getPlanDetails?planNumber=${data.planNumber}`,
            method: 'POST',
        })
        .then( response=>{ 
            return response.data
        });
         yield put(actions.sharedTripSuccess(invites.dataList));

    } catch(error){
        console.log("location created failed")
    }
}
/* remove email from sharetrip */
export function* removeEmailFromShareTrip(data){
    try{
        let response = yield axios.post(`removePlanEntity?planEntityId=${data.id}`).then(res => res);
        yield put(actions.removeEmailfromShareTripSuccess(response.data)) 
        
    } catch(error){
        yield put(actions.removeEmailfromShareTripError(error.data)) 
    }

}
/* Share Trips */
function* shareTrips(data){
    let info = data.data
    try{
        
        const invite = yield axios({
            url: `invitePlan`, 
            method: 'POST',
            data : info
        })
        .then( response=>{ 
            return response.data
        });
       yield put(actions.sendInviteSuccess(invite));

    } catch(error){
        console.log("location edit or view failed")
    }
}

function* loadVehicles(actionData) {
    let response,
    filter = actionData.data.filter,
    responseType = actionData.data.responseType;
    try {
        if (!filter) {
            response = yield axios({
                url: `getVehicleLiveStatusBasedAccountV2?userId=${actionData.data.fleetOwnerId}&offset=${actionData.data.offset}&recordCount=${actionData.data.recordCount}&planType=${actionData.data.type}`,
                method: 'POST',
            });
        }else{
            response = yield axios({
                url: `getVehicleLiveStatusBasedAccountV2?userId=${actionData.data.fleetOwnerId}&offset=${actionData.data.offset}&recordCount=${actionData.data.recordCount}&planType=${actionData.data.type}&vehicle=${actionData.data.vehicle}&driver=${actionData.data.driverId}&fromTimeUtc=${actionData.data.fromTimeUtc}&toTimeUtc=${actionData.data.toTimeUtc}`,
                method: 'POST',
            })
        }
        yield put(actions.loadVehicleSuccess(response.data,responseType));
    } catch (error) {
        yield put(actions.loadVehiclesFail(error));
    }
}


export default function* sharedTripsSaga(){
    yield takeLatest(constant.GET_SHARED_TRIPS, getSharedTrips)
    yield takeLatest(constant.SHARE_TRIPS, shareTrips)
    yield takeLatest(constant.LOAD_VEHICLES, loadVehicles)
    yield takeLatest(constant.ADDITIONAL_LOAD_VEHICLES, loadVehicles)
    yield takeLatest(constant.REMOVE_EMAIL_FROM_SHARE_TRIP,removeEmailFromShareTrip)
}