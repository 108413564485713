import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';

// create
function* createSCRService(action) {
    try {
        let res = yield axios.post(`createSimCreditRemainder`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.createSCRSuccess(res));
    } catch (error) {
        yield put(actions.createSCRError(error));
    }
}
// update
function* updateSCRService(action) {
    try {
        let res = yield axios.post(`updateSimCreditRemainder`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.updateSCRSuccess(res));
    } catch (error) {
        yield put(actions.updateSCRError(error));
    }
}
// view
function* getViewSCRService(action) {
    try {
        let res = yield axios.get(`getSimCreditRemainder?userId=${action.userId}`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getViewSCRSuccess(res));
    } catch (error) {
        yield put(actions.getViewSCRError(error));
    }
}
// delete
function* deleteSCRService(action) {
    try {
        let res = yield axios.post(`deleteSimCreditRemainder`, action.id)
            .then(response => {
                return (response.data)
            });
        yield put(actions.deleteSCRSuccess(res));
    } catch (error) {
        yield put(actions.deleteSCRError(error));
    }
}


export default function* simCreditReminderSaga() {
    yield all([
         takeLatest(actionTypes.CREATE_SCR, createSCRService),
        takeLatest(actionTypes.UPDATE_SCR, updateSCRService),
        takeLatest(actionTypes.VIEW_SCR, getViewSCRService),
        takeLatest(actionTypes.DELETE_SCR, deleteSCRService)
    ]);

}


