
import * as actionTypes from "./constant";

// get list
export function getAllVehiclesTyreService(organizationId, uid) {
    return {
        type: actionTypes.GET_VEHICLE_TYRE_LIST,
        organizationId
    }
}
export function getAllVehiclesTyreSuccess(vehicleTyreList) {
    return {
        type: actionTypes.GET_VEHICLE_TYRE_LIST_SUCCESS,
        vehicleTyreList        
    }
}

export function getAllVehiclesTyreError(error) {
    return {
        type: actionTypes.GET_VEHICLE_TYRE_LIST_FAILURE,
        error
    }
}