export const CREATE_HANDOVER         = 'CREATE_HANDOVER'
export const CREATE_HANDOVER_SUCCESS = 'CREATE_HANDOVER_SUCCESS'
export const UPDATE_HANDOVER         = 'UPDATE_HANDOVER'
export const UPDATE_HANDOVER_SUCCESS = 'UPDATE_HANDOVER_SUCCESS'
export const FORCE_HANDOVER          = 'FORCE_HANDOVER'
export const FORCE_HANDOVER_SUCCESS  = 'FORCE_HANDOVER_SUCCESS'
export const DELETE_HANDOVER         = 'DELETE_HANDOVER'
export const DELETE_HANDOVER_SUCCESS = 'DELETE_HANDOVER_SUCCESS'
export const HANDOVER_FAILURE        = 'HANDOVER_FAILURE'
export const CLEAR_STORE             = 'CLEAR_STORE'





