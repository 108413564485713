import { all } from 'redux-saga/effects';
import loginSaga from '../containers/signIn/saga';
import registerSaga from '../containers/Register/saga';
import vehicleSagas from '../containers/fleetOwners/dashboard/saga';
import instructionSaga from '../containers/fleetOwners/dashboard/attentionHistory/Instruction/saga';
import allTruckSaga from '../containers/fleetOwners/allTrucks/saga';
import manageVehicleSaga from '../containers/fleetOwners/manageVehicle/saga';
import addTruckSaga from '../containers/fleetOwners/AddTruck/saga';
import attention from '../containers/fleetOwners/dashboard/attentionHistory/attention/saga';
import truckHistory from '../containers/fleetOwners/dashboard/history/saga';
import SimUsageSaga from '../containers/fleetOwners/SIMConsent/saga';
import SimConsentCredits from '../containers/fleetOwners/Profile/saga';
import profileSaga from '../components/Layouts/appLayout/saga';
import turckStatusV2Saga from '../containers/fleetOwners/dashboard/truckStatus/saga';
import driverSaga from '../containers/fleetOwners/drivers/saga';
import myOrgSaga from '../containers/fleetOwners/organization/MyOrganization/saga';
import MyLocationSaga from '../containers/fleetOwners/Location/saga';
import createOrganizationSaga from '../containers/fleetOwners/organization/AssignedOrganization/saga';
import planSaga from '../containers/fleetOwners/dashboard/plan/saga';
import documentSaga from '../containers/fleetOwners/documents/saga';
import TripDetailSaga from '../containers/fleetOwners/dashboard/tripDetail/saga';
import sharedTripsSaga from '../containers/fleetOwners/sharedTrips/saga';
import sharedTripsDetailsSaga from '../containers/fleetOwners/sharedTrips/details/saga';
import addExpenseSaga from '../containers/fleetOwners/dashboard/attentionHistory/Expenses/AddExpenses/saga';
import expenseSaga from '../containers/fleetOwners/dashboard/attentionHistory/Expenses/saga';
import delExpenseSaga from '../containers/fleetOwners/dashboard/attentionHistory/Expenses/ExpensesHistory/saga';
import handoverSaga from '../containers/fleetOwners/dashboard/handover/saga'
import podSaga from '../containers/fleetOwners/dashboard/pod/saga'
import organizationIncentiveSaga from '../containers/fleetOwners/Incentive/organization/saga'
import driverIncentiveSaga from '../containers/fleetOwners/Incentive/driver/saga'
import organizationReportsSaga from '../containers/fleetOwners/reports/organization/saga'
import maintenanceSaga from '../containers/fleetOwners/FMS/maintenance/saga'
import docSaga from '../containers/fleetOwners/FMS/docRenewal/saga'
import serviceSaga from '../containers/fleetOwners/FMS/service/saga'
import simCreditReminderSaga from '../containers/fleetOwners/FMS/simCredits/saga'
import FuelPrice from '../containers/FuelPrice/saga'
import expenseReportsSaga from '../containers/fleetOwners/reports/expenseReports/saga'
import driverVerifySaga from '../containers/fleetOwners/superAdmin/driverVerify/saga'
import auditSaga from '../containers/fleetOwners/superAdmin/audits/saga'
import claimApprovalSaga from '../containers/fleetOwners/superAdmin/claimApprovals/saga'
import migratedVehicleSaga from '../containers/fleetOwners/superAdmin/migratedVehicles/saga'
import reportChartSaga from '../containers/fleetOwners/reports/chart/saga'
import rechargeSaga from '../containers/fleetOwners/mobileRecharge/saga';
import driverPerformanceSaga from '../containers/fleetOwners/Incentive/driverPerformance/saga';
import tyreManagementSaga from '../containers/fleetOwners/tyreManagement/Tyres/saga';
import allVehicleTyreSaga from '../containers/fleetOwners/tyreManagement/ListOfAllVehiclesTyres/saga';
import tyreReportSaga from '../containers/fleetOwners/reports/tyreReports/saga';
import tyreServiceSaga from '../containers/fleetOwners/tyreManagement/ServiceLogs/saga';
import tyreGraphReportSaga from '../containers/fleetOwners/tyreManagement/tyreGraphReport/saga';

export default function* rootWatcher() {
    yield all([
        loginSaga(),
        registerSaga(),
        profileSaga(),
        allTruckSaga(),
        vehicleSagas(),
        manageVehicleSaga(),
        instructionSaga(),
        addTruckSaga(),
        attention(),
        truckHistory(),
        SimUsageSaga(),
        turckStatusV2Saga(),
        SimConsentCredits(),
        driverSaga(),
        myOrgSaga(),
        createOrganizationSaga(),
        MyLocationSaga(),
        planSaga(),
        documentSaga(),
        TripDetailSaga(),
        sharedTripsSaga(),
        sharedTripsDetailsSaga(),
        addExpenseSaga(),
        expenseSaga(),
        delExpenseSaga(),
        handoverSaga(),
        podSaga(),
        organizationIncentiveSaga(),
        driverIncentiveSaga(),
        organizationReportsSaga(),
        maintenanceSaga(),
        docSaga(),
        serviceSaga(),
        simCreditReminderSaga(),
        FuelPrice(),
        expenseReportsSaga(),
        driverVerifySaga(),
        auditSaga(),
        claimApprovalSaga(),
        migratedVehicleSaga(),
        reportChartSaga(),
        rechargeSaga(),
        driverPerformanceSaga(),
        allVehicleTyreSaga(),
        tyreReportSaga(),
        tyreManagementSaga(),
        tyreServiceSaga(),
        tyreGraphReportSaga()
    ]);
}
