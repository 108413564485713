import { put, takeLatest, all } from 'redux-saga/effects'
import * as actions from './action'
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';


function* claimHistoryService() {
    try {
        let response = yield axios.get(`getFleetOwnerList`).then(res => res);
        let result = response.data;
        if (result.message === "SUCCESS") {
            yield put(actions.claimHistorySuccess(response.data.dataList))
        }
    } catch (error) {
        console.log(error);
    }
}
function* claimApproveService(actionData) {
    try {
        let response = yield axios.post(`fleetOwnerKycProcess`, actionData.data).then(res => res);
        yield put(actions.claimApproveSuccess(response.data))
    } catch (error) {
        yield put(actions.claimApproveError(error))
    }
}

export default function* claimApprovalSaga() {
    yield all([
        takeLatest(actionTypes.CLAIM_HISTORY_SERVICE, claimHistoryService),
        takeLatest(actionTypes.CLAIM_APPROVE_SERVICE, claimApproveService)
    ]);
}
