import * as actionTypes from "./constant";

export default function driverVerify(prevState = {
    driverList: [],
    loading: false,
    driverVerifyRes: '',
    savedLoading: false.driverVerify,
    setDriver: ''
}, action) {
    switch (action.type) {
        case actionTypes.GET_DRIVER_VERIFY:
            return {
                ...prevState,
                loading: true
            }
        case actionTypes.SET_DRIVER_VERIFY:
            return {
                ...prevState,
                driverList: action.driverList,
                loading: false,
                setDriver: 'SET_DRIVER'
            }
        case actionTypes.RESET_DRIVER:
            return {
                ...prevState,               
                loading: false,
                setDriver: ''
            }
        case actionTypes.DRIVER_VERIFY_SERVICE:
            return {
                ...prevState,
                savedLoading: true
            }
        case actionTypes.DRIVER_VERIFY_SUCCESS:
            return {
                ...prevState,
                savedLoading: false,
                driverVerifyRes: action.res
            }
        case actionTypes.DRIVER_VERIFY_FAILURE:
            return {
                ...prevState,
                error: action.error
            }
        case actionTypes.DRIVER_VERIFY_EMPTY_RES:
            return {
                ...prevState,
                driverVerifyRes: ''
            }
        default: {
            return {
                ...prevState
            }
        }
    }
}
