export const LOAD_ALLTRUCK_LODING= 'LOAD_ALLTRUCK_LODING'
export const LOAD_ALLTRUCK_SUCCESS = 'LOAD_ALLTRUCK_SUCCESS'
export const LOAD_ALLTRUCK_FAILURE = 'LOAD_ALLTRUCK_FAILURE'
export const HAND_OVER = 'HAND_OVER'
export const HAND_OVER_SUCCESS = 'HAND_OVER_SUCCESS'
export const HAND_OVER_FAILURE = 'HAND_OVER_FAILURE'
export const SHOW_ADD_TRUCK = 'SHOW_ADD_TRUCK'
export const HIDE_ADD_TRUCK = 'HIDE_ADD_TRUCK'
export const PLAN_SHOW_ADD_TRUCK = 'PLAN_SHOW_ADD_TRUCK'

