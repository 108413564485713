import { LOAD_ALLTRUCK_SUCCESS,
LOAD_ALLTRUCK_LODING, 
LOAD_ALLTRUCK_FAILURE,
SHOW_ADD_TRUCK,
HIDE_ADD_TRUCK,
PLAN_SHOW_ADD_TRUCK
} from './constant'

export default function allTruck(prevState = {
    data : [],
    loading: false,
    error:'',
    modal : false,
    addVehicleByPlan : false
}, action){
    switch(action.type) {
        case LOAD_ALLTRUCK_LODING:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case LOAD_ALLTRUCK_SUCCESS:
            return {
                ...prevState,
                data : action.truckData,
                loading: false
            }
        case LOAD_ALLTRUCK_FAILURE:
            return {
                ...prevState,
                error : action.error,
                loading : true
            }
        case SHOW_ADD_TRUCK:
            return {
                ...prevState,
                modal : true,
            }
        case PLAN_SHOW_ADD_TRUCK:
            return {
                ...prevState,
                modal : true,
                addVehicleByPlan : true
            }
        case HIDE_ADD_TRUCK:
            return {
                ...prevState,
                modal : false,
                addVehicleByPlan : false
            }
        default : {
            return {
                ...prevState
            }
        }
    }
}

// export  function handOver(state = {
//     error: '',
//     modal: false
// }, action){
//     switch(action.type) {
//         case HAND_OVER:
//             return {
//                 ...state,
//                 error : action.error,
//                 modal : true
//             }
//         case HAND_OVER_SUCCESS:
//             return {
//                 ...state,
//                 modal: false
//             }
//         case HAND_OVER_FAILURE:
//             return {
//                 ...state,
//                 error : action.error,
//                 modal : true
//             }
//         default : {
//             return {
//                 ...state
//             }
//         }
//     }
// }
