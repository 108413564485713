import { put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './action';
import * as actionTypes from "./constant";
import axios from '../../../../utils/axios';
// get types
function* getServiceTypeService() {
    try {
        let res = yield axios.get(`getServiceTypes`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getServiceTypeSuccess(res));
    } catch (error) {
        yield put(actions.getServiceTypeError(error));
    }
}
// get vehicles
function* getVehiclesService(action) {
    try {
        const data = yield axios({
            url: `getVehicles?orgId=${action.organizationId}`,
            method: 'POST',
        })
            .then(response => {
                return response.data.dataList
            });
        yield put(actions.getVehiclesSuccess(data));
    } catch (error) {
        yield put(actions.getVehiclesError(error));
    }
}
// create
function* createServiceService(action) {
    try {
        let res = yield axios.post(`createServiceReport`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.createServiceSuccess(res));
    } catch (error) {
        yield put(actions.createServiceError(error));
    }
}
// update
function* updateServiceService(action) {
    try {
        let res = yield axios.post(`updateServiceReport`, action.payLoad)
            .then(response => {
                return (response.data)
            });
        yield put(actions.updateServiceSuccess(res));
    } catch (error) {
        yield put(actions.updateServiceError(error));
    }
}
// view
function* getViewServiceService(action) {
    try {
        let res = yield axios.get(`getAllServiceReports?orgId=${action.organizationId}`)
            .then(response => {
                return (response.data.dataList)
            });
        yield put(actions.getViewServiceSuccess(res));
    } catch (error) {
        yield put(actions.getViewServiceError(error));
    }
}
// delete
function* deleteServiceService(action) {
    try {
        let res = yield axios.post(`deleteServiceReport`, action.id)
            .then(response => {
                return (response.data)
            });
        yield put(actions.deleteServiceSuccess(res));
    } catch (error) {
        yield put(actions.deleteServiceError(error));
    }
}


export default function* serviceSaga() {
    yield all([
        takeLatest(actionTypes.GET_SERVICE_TYPE, getServiceTypeService),
        takeLatest(actionTypes.GET_VEHICLELIST_SERVICE, getVehiclesService),
        takeLatest(actionTypes.CREATE_SERVICE, createServiceService),
        takeLatest(actionTypes.UPDATE_SERVICE, updateServiceService),
        takeLatest(actionTypes.VIEW_SERVICE, getViewServiceService),
        takeLatest(actionTypes.DELETE_SERVICE, deleteServiceService)
    ]);
}


